<template>
  <a-list item-layout="horizontal" :data-source="collaborators" class="users-list">
    <a-list-item slot="renderItem" slot-scope="collaborator">
      <a-list-item-meta>
        <div v-if="!collaborator.isPending" slot="title" class="text-base break-all">
          {{ collaborator.name }}
          <span v-if="collaborator.isCurrentUser">(you)</span>
        </div>
        <div slot="description" class="text-base text-gray-500 break-all">
          {{ collaborator.email }}
        </div>
        <VUserAvatar
            v-if="!collaborator.isPending"
            slot="avatar"
            :email="collaborator.email"
            :name="collaborator.name"/>
        <a-tooltip v-else slot="avatar" title="Invite sent">
          <a-avatar icon="clock-circle" size="large" class="text-neutral_gray-500 bg-neutral_gray-100"/>
        </a-tooltip>
      </a-list-item-meta>

      <PermissionSelect
          v-if="!collaborator.isOwner && !collaborator.isCurrentUser"
          slot="actions"
          :default-value="collaborator.permission"
          :loading="loadingSelect[collaborator._id]"
          :permissions="Object.values(permissions)"
          @select="permission => onUpdatePermission(permission, collaborator._id)">
        <template #actions>
          <div
              v-if="collaborator.isPending"
              class="cursor-pointer hover:opacity-70"
              @click="onResend(collaborator._id)">
            <a-icon type="redo"/>
            Resend invite
          </div>
          <div
              class="cursor-pointer text-negative hover:opacity-70"
              @click="onRemoveAccess(collaborator._id)">
            <a-icon type="delete"/>
            Delete
          </div>
        </template>
      </PermissionSelect>

      <PermissionSelect
          v-else-if="!collaborator.isOwner && collaborator.isCurrentUser"
          slot="actions"
          :default-value="collaborator.permission"
          :loading="loadingSelect[collaborator._id]"
          :permissions="currentUserPermissions">
        <template #actions>
          <div
              class="cursor-pointer"
              @click="onLeave(collaborator._id, modal.surveyId, modal.folderId)">
            <a-icon type="logout"/>
            Leave
          </div>
        </template>
      </PermissionSelect>

      <div v-else class="text-base text-character_gray-500 pr-2">Owner</div>
    </a-list-item>
  </a-list>
</template>

<script>
import VUserAvatar from "../VUserAvatar";
import {mapGetters} from "vuex";
import PermissionSelect from "./PermissionSelect";
import {CollaboratorsMixin} from "../../mixins/collaborators.mixin";

export default {
  name: "CollaboratorList",
  components: {
    VUserAvatar,
    PermissionSelect,
  },
  mixins: [CollaboratorsMixin],
  data() {
    return {
      loadingSelect: {},
    };
  },
  computed: {
    ...mapGetters("collaborators", ["permissions", "modal", "listCollaborators"]),
    collaborators() {
      const collaborators = [...this.listCollaborators({surveyId: this.modal.surveyId, folderId: this.modal.folderId})]

      return collaborators?.map(collaborator => ({
        ...collaborator,
        isCurrentUser: this.$store.getters["auth/user"].email === collaborator.email,
        isPending: collaborator.status === "pending",
      })) || [];
    },
    currentUserPermissions() {
      const currentCollaborator = this.collaborators.find(collaborator => collaborator.isCurrentUser);
      return Object.values(this.permissions).filter(permission => permission.value === currentCollaborator?.permission);
    },
  },
  methods: {
    onUpdatePermission(permission, collaboratorId) {
      this.$store.dispatch("collaborators/updatePermission", {
        permission,
        collaboratorId,
        surveyId: this.modal.surveyId,
        folderId: this.modal.folderId,
      });
    },
    onRemoveAccess(collaboratorId) {
      this.$set(this.loadingSelect, collaboratorId, true);

      this.$store.dispatch("collaborators/removeAccess", {
        collaboratorId,
        surveyId: this.modal.surveyId,
        folderId: this.modal.folderId,
      })
          .then(() => {
            this.$message.success('Access removed');
          })
          .finally(() => {
            this.$set(this.loadingSelect, collaboratorId, false);
          });
    },
    onResend(collaboratorId) {
      this.$set(this.loadingSelect, collaboratorId, true);

      this.$store.dispatch("collaborators/resendInvitation", {
        collaboratorId,
        surveyId: this.modal.surveyId,
        folderId: this.modal.folderId,
      })
          .then(() => {
            this.$message.success('Invitation resent');
          })
          .finally(() => {
            this.$set(this.loadingSelect, collaboratorId, false);
          });
    },
  }
}
</script>

<style lang="less">
.users-list {
  & .ant-list-item-meta {
    align-items: center;
  }
}
</style>
