<template>
  <div class="bg-white border border-solid border-neutral_gray-300 p-6 rounded">

    <div class="flex items-center justify-between mb-4">
      <div class="flex items-center">
        <a-switch
            class="switch_positive-color"
            checked-children="On"
            un-checked-children="Off"
            v-model="item.active"
        />
        <h3
            class="inline ml-6 mb-0 text-2xl font-light leading-none"
            :class="{'text-gray-300': !item.active}">
          <slot name="title"></slot>
        </h3>
      </div>
      <a-popconfirm
          title="Are you sure delete this logic?"
          ok-text="Delete"
          cancel-text="No, cancel"
          @confirm="onDelete"
          overlayClassName="danger-confirm"
      >
        <a-icon slot="icon" type="question-circle-o" style="color: var(--color-negative)"/>
        <a-button
            type="link"
            size="large"
            icon="delete"
            class="btn-action_question btn-negative -mr-3"
            style="height: auto;"
        >
          Delete
        </a-button>
      </a-popconfirm>
    </div>

    <div class="space-y-6">
      <div class="space-y-3">
        <div v-for="(condition, index) in conditions" :key="index">
          <div class="flex items-center space-x-3">
            <div class="flex-none" style="width: 80px;">
              <div v-if="index === 0" class="text-xl font-light text-center">IF</div>
              <a-select v-else v-model="condition.operator" size="large" class="w-full">
                <a-select-option
                    v-for="operator in operators"
                    :key="operator"
                >
                  {{ operator.toUpperCase() }}
                </a-select-option>
              </a-select>
            </div>

            <a-select
                v-model="condition.type"
                size="large"
                class="flex-none"
                style="width: 110px;"
                :dropdownMatchSelectWidth="false"
                @change="onChangeConditionType(condition)">
              <a-select-option
                  v-for="conditionType in conditionTypes"
                  :key="conditionType.value"
                  :value="conditionType.value">
                <a-icon v-if="isConditionTypeCalculated(conditionType)" type="calculator" class="text-base"/>
                {{ conditionType.title }}
              </a-select-option>
            </a-select>

            <a-select
                v-model="condition.trigger"
                :style="condition.trigger !== 'is empty' && condition.trigger !== 'is not empty' ? 'width: 156px; flex: none;' : 'width: 100%'"
                size="large">
              <a-select-option
                  v-for="trigger in getConditionTriggers(condition)"
                  :key="trigger">
                {{ trigger }}
              </a-select-option>
            </a-select>

            <div v-show="condition.trigger !== 'is empty' && condition.trigger !== 'is not empty'" class="w-full">
              <a-select
                  v-if="getConditionTriggerObj(condition).fieldType === 'select'"
                  v-model="condition.value"
                  size="large"
                  style="width: 100%;">
                <a-select-option
                    v-for="option in getConditionTriggerObj(condition).selectOptions"
                    :key="option._id || option">
                  {{ option.value || option }}
                </a-select-option>
              </a-select>
              <a-input v-else
                       size="large"
                       placeholder="Value"
                       v-model="condition.value"
              />
            </div>

            <VButton v-show="conditions.length > 1"
                     type="default-negative-hover"
                     size="large"
                     style="height: 40px; width: 40px; font-size: 20px;"
                     class="flex-none ml-3"
                     icon="delete"
                     @click="deleteCondition(index)"
            />
          </div>
        </div>
        <a-button
            size="large"
            icon="plus"
            block
            class="btn-add"
            style="text-transform: none;"
            @click="addCondition">
          Add condition
        </a-button>
      </div>

      <div class="space-y-3">
        <div v-for="(action, index) in actions" :key="index">
          <div class="flex items-center space-x-3">
            <div class="text-xl font-light flex-none text-center" style="width: 80px;">
              {{ index === 0 ? "THEN" : "AND" }}
            </div>

            <a-select
                v-model="action.type"
                size="large"
                class="flex-none"
                style="width: 110px"
                @change="onChangeActionType(action)">
              <a-select-option
                  v-for="actionType in actionTypes"
                  :key="actionType.value">
                {{ actionType.title }}
              </a-select-option>
            </a-select>

            <a-select
                v-if="action.type === 'goto'"
                v-model="action.value"
                size="large"
                class="w-full">
              <a-select-option
                  v-for="question in nextQuestions"
                  :key="question._id">
                <span v-if="question.index">{{ question.index }}.</span>
                <span
                    v-if="question.type !== undefined"
                    :style="`color: ${getQuestionTypeColor(question.type)}`">
                  [{{ question.type }}]
                </span>
                {{ question.text | truncateQuestionText }}
              </a-select-option>
            </a-select>

            <div v-else class="flex items-center space-x-3 w-full">
              <a-select
                  v-model="action.calculatedField"
                  size="large"
                  class="flex-none"
                  style="width: 110px;"
                  :dropdownMatchSelectWidth="false"
                  @change="onChangeActionField(action)">
                <a-select-option
                    v-for="(calculatedField, index) in survey.calculatedFields"
                    :key="index"
                    :value="calculatedField.id">
                  <a-icon type="calculator" class="text-base"/>
                  {{ calculatedField.name }}
                </a-select-option>
              </a-select>
              <a-select v-model="action.operation" size="large" class="flex-none" style="width: 110px">
                <a-select-option
                    v-for="operation in getActionOperations(action)"
                    :key="operation">
                  {{ operation }}
                </a-select-option>
              </a-select>
              <a-input
                  size="large"
                  placeholder="Value"
                  v-model="action.value"/>
            </div>

            <VButton v-show="actions.length > 1"
                     type="default-negative-hover"
                     size="large"
                     style="height: 40px; width: 40px; font-size: 20px;"
                     class="flex-none ml-3"
                     icon="delete"
                     @click="deleteAction(index)"
            />
          </div>
        </div>
        <a-button
            size="large"
            icon="plus"
            block
            class="btn-add"
            style="text-transform: none;"
            @click="addAction">
          Add action
        </a-button>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "../VButton";
import _ from "lodash";

const LAST_STEP_COMMENT = "lastStepComment";

export default {
  name: "VConditionalLogicItem",
  components: {VButton},
  props: {
    item: Object,
    question: Object,
  },
  filters: {
    truncateQuestionText(value) {
      if (value.trim() === "") return "Untitled"
      return _.truncate(value, {length: 50})
    }
  },
  created() {
    // set default conditions
    if (this.item.conditions.length) {
      this.conditions = this.item.conditions;
    } else {
      this.conditions.push({...this.getDefaultConditionItem()});
    }

    // set default actions
    if (this.item.actions.length) {
      this.actions = this.item.actions;
    } else {
      this.actions.push({...this.getDefaultActionItem()});
    }
  },
  data() {
    return {
      conditions: [],
      actions: [],
      operators: ["and", "or"],
      operationTypesObj: {
        number: ["+", "-", "*", "/", "="],
        string: ["+", "="],
      },
      actionTypes: [
        {value: "goto", title: "Go To"},
        {value: "calculate", title: "Calculate"}
      ],
    }
  },
  watch: {
    conditions: {
      handler: function (newValue) {
        this.$emit("update", {conditions: newValue})
      },
      deep: true
    },
    actions: {
      handler: function (newValue) {
        this.$emit("update", {actions: newValue})
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters('survey', ['survey', 'questions', 'thankyouScreens']),
    nextQuestions() {
      const questions = this.questions
          .map((q, index) => ({...q, index: index + 1}))
          .filter((q) => q._id !== this.question._id);

      if (this.survey.comment.active) {
        questions.push({text: "Last step comment", _id: LAST_STEP_COMMENT});
      }

      const thankyouScreenItems = this.thankyouScreens.map((t) => {
        return {text: `${t.title} ${t.description}`, _id: `thankyou-${t._id}`, type: "thank you"}
      });

      return questions.concat(thankyouScreenItems);
    },
    triggerTypes() {
      return {
        swipe: {
          triggers: ["is", "is not"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("swipe"),
        },
        nps: {
          triggers: ["=", "≠", ">", "<", "≥", "≤"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("nps"),
        },
        multiple: {
          triggers: ["contains", "does not contain"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("multiple"),
          subtypes: {
            single: {
              triggers: ["is", "is not"],
            }
          },
        },
        rating: {
          triggers: ["=", "≠", ">", "<", "≥", "≤"],
          fieldType: "select",
          selectOptions: this.getSelectOptions("rating"),
        },
        open: {
          subtypes: {
            singleLine: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input",
            },
            multiLine: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input",
            },
            number: {
              triggers: ["=", "≠", ">", "<", "≥", "≤", "is empty", "is not empty"],
              fieldType: "input",
            },
            email: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input",
            },
            phone: {
              triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
              fieldType: "input"
            },
          }
        },
        calculatedNumber: {
          triggers: ["=", "≠", ">", "<", "≥", "≤"],
          fieldType: "input",
        },
        calculatedString: {
          triggers: ["is", "is not", "contains", "does not contain", "begins with", "ends with", "is empty", "is not empty"],
          fieldType: "input",
        },
      }
    },
    conditionTypes() {
      return [
        {value: "answer", title: "Answer"},
        ...this.survey.calculatedFields.map(field => ({
          value: field.id,
          title: field.name,
          triggerType: field.type.toLowerCase() === "number" ? "calculatedNumber" : "calculatedString",
          isCalculated: true,
        }))
      ];
    },
  },
  methods: {
    onDelete() {
      this.$emit("delete")
    },
    addCondition() {
      this.conditions.push({
        ...this.getDefaultConditionItem(),
        operator: "and"
      });
    },
    deleteCondition(index) {
      this.conditions.splice(index, 1);
    },
    addAction() {
      this.actions.push({...this.getDefaultActionItem()});
    },
    deleteAction(index) {
      this.actions.splice(index, 1);
    },
    getConditionTriggers(condition) {
      const conditionTypeObj = this.getConditionTypeObj(condition);
      if (condition && this.isConditionTypeCalculated(conditionTypeObj)) return _.get(this.triggerTypes, conditionTypeObj.triggerType, null)?.triggers;
      if (this.question.type === "multiple" && this.question.options.isSingleSelection) return this.triggerTypes.multiple.subtypes.single.triggers;
      if (this.question.type === "open") return _.get(this.triggerTypes.open.subtypes, this.question.subtype, null)?.triggers;
      return _.get(this.triggerTypes, this.question.type, null)?.triggers;
    },
    getConditionValue(condition) {
      const conditionTypeObj = this.getConditionTypeObj(condition);
      if (condition && this.isConditionTypeCalculated(conditionTypeObj)) return null;
      const selectOptions = this.getSelectOptions(this.question.type);
      return selectOptions ? selectOptions[0]?._id || selectOptions[0] : null;
    },
    getConditionTriggerObj(condition) {
      const conditionTypeObj = this.getConditionTypeObj(condition);
      return this.isConditionTypeCalculated(conditionTypeObj) ? this.triggerTypes[conditionTypeObj.triggerType] : this.triggerTypes[this.question.type];
    },
    getConditionTypeObj(condition) {
      return this.conditionTypes.find(type => type.value === condition?.type);
    },
    isConditionTypeCalculated(conditionType) {
      return conditionType?.isCalculated;
    },
    getCalculatedField(id) {
      return this.survey.calculatedFields.find(field => field.id === id);
    },
    getActionValue(action) {
      if (action?.type === "calculate") return null;
      const lastQuestionId = this.survey.comment.active ? LAST_STEP_COMMENT : this.thankyouScreens?.[0]?._id ? `thankyou-${this.thankyouScreens[0]._id}` : null;
      const index = this.questions.findIndex((item) => item._id === this.question._id);
      return index !== -1 && index < this.questions.length - 1 ? this.questions[index + 1]._id : lastQuestionId;
    },
    getActionOperations(action) {
      return this.operationTypesObj[this.getCalculatedField(action.calculatedField)?.type || 'number']
    },
    getDefaultConditionItem() {
      return {
        type: this.conditionTypes[0].value,
        trigger: this.getConditionTriggers({type: this.conditionTypes[0].value})?.[0],
        value: this.getConditionValue(null),
      }
    },
    getDefaultActionItem() {
      return {
        type: this.actionTypes[0].value,
        calculatedField: this.survey.calculatedFields[0]?.id,
        operation: this.operationTypesObj.number[0],
        value: this.getActionValue(null),
      }
    },
    getQuestionTypeColor(type) {
      switch (type) {
        case "swipe":
          return "var(--color-blue-500)"
        case "nps":
          return "var(--color-violet-500)"
        case "rating":
          return "var(--color-orange-500)"
        case "multiple":
          return "var(--color-blue-700)"
        case "open":
          return "var(--color-gray-500)"
        case "thank you":
          return "var(--color-positive)"
      }
    },
    getSelectOptions(type) {
      let selectOptions = null;
      if (type === "swipe") {
        selectOptions = [
          {
            _id: "yes",
            value: this.question.translations.rightButton.value || this.survey.translations.questions.swipe.rightButton.value || this.survey.translations.questions.swipe.rightButton.defaultValue
          },
          {
            _id: "no",
            value: this.question.translations.leftButton.value || this.survey.translations.questions.swipe.leftButton.value || this.survey.translations.questions.swipe.leftButton.defaultValue
          },
        ]
      } else if (type === "multiple") {
        selectOptions = this.question.choices.map((choice, index) => {
          return {
            ...choice,
            value: `[${index + 1}] ${choice.text || "Untitled choice"}`
          };
        });
      } else if (type === "nps") {
        selectOptions = _.range(1, this.question.options.scoreScale + 1);
      } else if (type === "rating") {
        switch (this.question.options.ratingScale) {
          case(2):
            selectOptions = [1, 5]
            break;
          case(3):
            selectOptions = [1, 3, 5]
            break;
          default:
            selectOptions = [1, 2, 3, 4, 5]
        }
      }
      return selectOptions;
    },
    onChangeConditionType(condition) {
      condition.trigger = this.getConditionTriggers(condition)[0];
      condition.value = this.getConditionValue(condition);
    },
    onChangeActionType(action) {
      action.operation = this.operationTypesObj.number[0];
      action.value = this.getActionValue(action);
    },
    onChangeActionField(action) {
      const calculatedField = this.getCalculatedField(action.calculatedField);
      action.operation = this.operationTypesObj[calculatedField.type][0];
      action.value = this.getActionValue(action);
    }
  }
}
</script>

<style scoped>

</style>
