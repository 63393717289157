<template>
  <VSubscriptionLayout :data="currentPlan">


    <template #plan-extra>
      <span style="color: rgba(127, 140, 167, 1)">
        Paused for {{ user.subscription.pausedFrom | moment('from', true) }}
      </span>
    </template>


    <template #plan-buttons>
      <div class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default-success" style="height: 36px;" @click="onRenew(promocodeForm.value)">
          <a-icon type="reload" style="line-height: 0;"/>
        </VButton>
        <div class="text-base text-positive leading-none">Resume</div>
      </div>

      <div class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default-negative" style="height: 36px;" @click="onCancel">
          <a-icon type="stop" style="line-height: 0;"/>
        </VButton>
        <div class="text-base leading-none">Cancel</div>
      </div>
    </template>


    <template #plan-billing-link>
      <div class="flex items-center space-x-1.5">
        <a-icon type="credit-card" class="text-gray-300 text-base"></a-icon>
        <a :href="user.subscription.updateUrl"
           target="_blank"
           class="text-base leading-tight underline hover:text-blue-700 hover:no-underline focus:text-blue-700 focus:no-underline"
           style="color: rgba(0, 149, 233, 1);">Update payment method</a>
      </div>
    </template>


    <template #plans>
      <a-skeleton :loading="isLoading" active>
        <div class="flex items-start space-x-3 pl-20">
          <span class="text-xl leading-tight">Resume your subscription:</span>
          <div class="flex justify-end space-x-3">
            <VPlanRadio v-for="plan in plansComputed"
                        :key="plan.product_id"
                        :title="plan.title"
                        :price="+(plan.subscription.price.gross).toFixed(0)"
                        :currency-symbol="plan.currencySymbol"
                        :value="plan.product_id"
                        :checked-default="selectedPlanDefault"
                        v-model="selectedPlan"
                        :ribbon="getRibbon(plan)"
                        :disabled="selectedPlan !== plan.product_id"
            >
              <template #extra>
                <div>
                  <div v-if="plan.subscription.interval === 'year'"
                       class="text-sm leading-4"
                       style="color: #7F8CA7;">
                    {{ plan.currencySymbol }}{{ (plan.subscription.price.gross / 12).toFixed(0) }} per month
                  </div>
                  <div class="text-sm leading-4 text-blue-500">
                    <span v-if="plan.responses.toLowerCase() === 'unlimited'">
                      <VIcon name="infinite" class="text-blue-500"/>
                    </span>
                    <span v-else>
                      {{ plan.responses }}
                    </span>
                    responses
                  </div>
                </div>
              </template>
            </VPlanRadio>
          </div>
        </div>
      </a-skeleton>
    </template>


    <template #promocode>
      <div v-if="!isLoading" class="flex-none">
        <div class="text-xl mb-4 leading-none">Coupon</div>
        <a-form-model :model="promocodeForm" layout="inline" class="promocode-form">
          <a-form-model-item prop="value"
                             label="Code"
                             :rules="{validator: onValidateCoupon, trigger: 'blur'}"
                             has-feedback
                             style="margin: 0;">
            <a-input size="large"
                     v-model="promocodeForm.value"
                     style="width: 184px; border-radius: 10px;"/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </template>


    <template #plan-update-button>
      <VButton v-if="!isLoading"
               type="success"
               size="large"
               style="width: 184px;"
               @click="onRenew(promocodeForm.value)">
        <a-icon type="reload"></a-icon>
        <span class="uppercase font-bold">Resume</span>
      </VButton>
    </template>


  </VSubscriptionLayout>
</template>

<script>
import {subscriptionProMixin} from "../../mixins/subscriptionPro";
import {VALIDATE_COUPON} from "../../store/actions.type";

export default {
  name: "SubscriptionProPaused",

  mixins: [subscriptionProMixin],

  data() {
    return {
      promocodeForm: {
        value: ''
      }
    }
  },

  methods: {

    onValidateCoupon(rule, value, callback) {
      if (value === '') {
        return callback(new Error('Not valid code'));
      }

      this.$store.dispatch(`billing/${VALIDATE_COUPON}`, {
        coupon: value
      })
          .then(() => {
            callback()
          })
          .catch(e => {
            callback(e)
          })
    },

  }
}
</script>
