import {EXPORT_RESULTS, SURVEY_DELETE, SURVEY_EDIT} from "@/store/actions.type";
import _ from "lodash";
import {Parser} from "json2csv";
import DownloadCsvFile from "../helpers/downloadCsvFile";

export const surveyMixin = {
  methods: {
    deleteSurvey(id) {
      this.$confirm({
        title: 'Are you sure?',
        content: `Deleting this survey will also delete any collected responses and comments.`,
        okText: 'Delete',
        cancelText: 'No, cancel',
        okButtonProps: {props: {type: 'danger', size: 'default'}},
        centered: true,
        maskClosable: true,
        class: 'modal-delete-survey',
        icon: () => (<a-icon type="warning" style="color: var(--color-negative)"/>),
        onOk: () => this.$store.dispatch(`survey/${SURVEY_DELETE}`, {survey_id: id})
          .then(() => {
            if (this.$route.name === "Survey") {
              this.$router.push('/');
            }
            this.$posthog.capture('Survey Deleted', {surveyId: id});
          }),
      })
    },
    exportResults(id) {
      this.$store.dispatch(`survey/${EXPORT_RESULTS}`, {survey_id: id})
        .then(response => {
          const {survey_id, data} = response;
          const parser = new Parser();
          const csv = parser.parse(data);
          DownloadCsvFile(survey_id, csv);

          this.$posthog.capture('Exported Results to CSV', {surveyId: id});
        });
    },
    previewSurvey(survey_id = undefined, isTemplate = false) {
      const sharingLink = this.getSharableLink(survey_id);
      window.open(`${sharingLink}/?preview_mode=true`, '_blank');

      if (isTemplate) {
        this.$posthog.capture('Previewed Template', {templateId: survey_id});
      } else {
        this.$posthog.capture('Previewed Survey', {surveyId: survey_id});
      }
    },
    doCopy() {
      const sharingLink = this.getSharableLink()
      const el = this.$refs.btnCopy.$el
      const btnName = el.innerHTML

      this.$copyText(sharingLink).then(() => {
        el.disabled = true
        el.innerHTML = '<span>Copied!</span>'
        setTimeout(() => {
          el.disabled = false
          el.innerHTML = btnName
        }, 5000)

        this.$posthog.capture('Copied Survey link');
      })
    },
    getSharableLink(survey_id = undefined) {
      survey_id = survey_id || this.$route.params.id;

      let link = `${process.env.VUE_APP_PUBLIC_URL}${survey_id}`;

      let surveyObj = this.survey;
      if (!surveyObj) {
        surveyObj = _.find(this.surveys, ["_id", survey_id]);
      }

      if (surveyObj?.custom_domain.active && surveyObj?.custom_domain.verifiedDomain) {
        link = `https://${surveyObj.custom_domain.verifiedDomain}/survey/${survey_id}`
      }

      return link;
    },
    editSurvey: _.debounce(function (options) {
      this.$store.dispatch(`survey/${SURVEY_EDIT}`, {survey_id: options._id, options})
    }, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS),
  }
}

export default surveyMixin;
