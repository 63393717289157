<template>
  <VSubscriptionLayout :data="currentPlan">


    <template #plan-extra>
      <span style="color: rgba(210, 140, 0, 1)">
        Payment failed! Update your payment method.
      </span>
    </template>


    <template #plan-buttons>
      <div class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default" style="height: 36px;" @click="onPause">
          <a-icon type="pause" style="line-height: 0;"/>
        </VButton>
        <div class="text-base leading-none">Pause</div>
      </div>

      <div class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default-negative" style="height: 36px;" @click="onCancel">
          <a-icon type="stop" style="line-height: 0;"/>
        </VButton>
        <div class="text-base leading-none">Cancel</div>
      </div>

    </template>


    <template #plan-billing-link>
      <div class="flex items-center space-x-1.5">
        <a-icon type="credit-card" class="text-gray-300 text-base"></a-icon>
        <a :href="user.subscription.updateUrl"
           target="_blank"
           class="text-base leading-tight underline hover:text-blue-700 hover:no-underline focus:text-blue-700 focus:no-underline"
           style="color: rgba(0, 149, 233, 1);">Update payment method</a>
      </div>
    </template>


    <template #plans>
      <a-skeleton :loading="isLoading" active>
        <div class="flex items-start space-x-3 pl-20">
          <span class="text-xl leading-tight">Switch your plan:</span>
          <div class="flex justify-end space-x-3">
            <VPlanRadio v-for="plan in plansComputed"
                        :key="plan.product_id"
                        :title="plan.title"
                        :price="+(plan.subscription.price.gross).toFixed(0)"
                        :currency-symbol="plan.currencySymbol"
                        :value="plan.product_id"
                        :checked-default="selectedPlanDefault"
                        v-model="selectedPlan"
                        :ribbon="getRibbon(plan)"
                        :disabled="selectedPlan !== plan.product_id"
            >
              <template #extra>
                <div>
                  <div v-if="plan.subscription.interval === 'year'"
                       class="text-sm leading-4"
                       style="color: #7F8CA7;">
                    {{ plan.currencySymbol }}{{ (plan.subscription.price.gross / 12).toFixed(0) }} per month
                  </div>
                  <div class="text-sm leading-4 text-blue-500">
                    <span v-if="plan.responses.toLowerCase() === 'unlimited'">
                      <VIcon name="infinite" class="text-blue-500"/>
                    </span>
                    <span v-else>
                      {{ plan.responses }}
                    </span>
                    responses
                  </div>
                </div>
              </template>
            </VPlanRadio>
          </div>
        </div>
      </a-skeleton>
    </template>


    <template #plan-update-button>
      <a :href="user.subscription.updateUrl"
         target="_blank">
        <VButton v-if="!isLoading"
                 type="success"
                 size="large"
                 style="width: 184px;">
          <a-icon type="poweroff"></a-icon>
          <span class="uppercase font-bold">Update</span>
        </VButton>
      </a>
    </template>


  </VSubscriptionLayout>
</template>

<script>
import {subscriptionProMixin} from "../../mixins/subscriptionPro";

export default {
  name: "SubscriptionActive",

  mixins: [subscriptionProMixin]
}
</script>
