<template>
  <div class="space-y-4">
    <div v-for="(item, index) in question.logics" :key="uuidKey[index]">
      <VConditionalLogicItem :item="item"
                             :question="question"
                             @update="onUpdate(index, $event)"
                             @delete="onDelete(index)">
        <template slot="title">Logic {{ index + 1 }}</template>
      </VConditionalLogicItem>
    </div>

    <a-button
        size="large"
        icon="plus"
        block
        class="btn-add"
        style="text-transform: uppercase;"
        :loading="isLoading"
        @click="onCreate"
    >
      New logic
    </a-button>
  </div>
</template>

<script>
import VConditionalLogicItem from "./VConditionalLogicItem";
import questionMixin from "../../mixins/question";
import _ from "lodash";
import {v4 as uuidv4} from 'uuid';

export default {
  name: "VConditionalLogic",

  props: {
    question: Object,
  },

  components: {VConditionalLogicItem},

  mixins: [questionMixin],

  data() {
    return {
      isLoading: false,
    }
  },

  watch: {
    'question.type': {
      handler: function () {
        // remove logics when question type changed
        this.question.logics = []
      }
    },
  },

  computed: {
    uuidKey() {
      return this.question.logics.map(() => uuidv4());
    },
  },

  methods: {
    onDelete(index) {
      this.question.logics.splice(index, 1);
    },
    onCreate() {
      this.question.logics.push({active: true, conditions: [], actions: []});
    },
    onUpdate(index, data) {
      if (_.has(data, "conditions")) {
        this.question.logics[index].conditions = data.conditions;
      } else if (_.has(data, "actions")) {
        this.question.logics[index].actions = data.actions;
      }
    }
  }
}
</script>

<style scoped>

</style>
