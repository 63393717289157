<template>
  <div>
    <div class="flex items-center mb-3">
      <span class="text-lg font-light leading-none">{{ inputName }}</span>
      <a-tooltip v-if="inputInfoHtml"
                 :destroyTooltipOnHide="true"
                 placement="right"
                 overlayClassName="tooltip-image"
                 class="ml-1">
        <template slot="title">
          <div class="h-full flex flex-col justify-between">
            <div class="pt-4 pb-0">
              <VIcon v-if="inputInfoIcon" :name="inputInfoIcon"></VIcon>
            </div>
            <div class="bg-white text-left pt-3 pl-4 pr-5 pb-5 text-base leading-tight text-character_gray-400"
                 v-html="inputInfoHtml"></div>
          </div>
        </template>
        <a-icon type="info-circle" class="icon-info"/>
      </a-tooltip>
    </div>
    <div class="flex space-x-3">
      <a-switch v-if="typeof switchValue === 'boolean'"
                class="switch_positive-color mt-2"
                checked-children="On"
                un-checked-children="Off"
                :checked="switchValue"
                @change="onSwitch"
      />
      <VTextArea :max-length="inputLength" :count-typed-symbols="inputValue.length">
        <a-input :placeholder="inputPlaceholder"
                 size="large"
                 :maxLength="inputLength"
                 :style="`width: ${inputWidth || 'auto'};`"
                 :value="inputValue"
                 :disabled="switchValue === false"
                 @change="onInput"
        />
      </VTextArea>
    </div>
  </div>
</template>

<script>
import VTextArea from "./VTextArea";
import VIcon from "./VIcon";

export default {
  name: "VSwitchInput",

  components: {VTextArea, VIcon},

  props: {
    inputName: {
      type: String,
      required: true,
    },
    inputInfoHtml: {
      type: String,
    },
    inputInfoIcon: {
      type: String,
    },
    inputLength: {
      type: Number,
      required: true,
    },
    inputPlaceholder: {
      type: String,
      default: "",
    },
    inputWidth: {
      type: String,
      default: "auto",
    },
    inputValue: {
      type: String,
      required: true,
    },
    switchValue: {
      type: Boolean,
      default: undefined,
    }
  },

  methods: {
    onSwitch(value) {
      this.$emit('update:switch-value', value)
    },

    onInput(e) {
      const {value} = e.target;
      this.$emit('update:input-value', value)
    }
  }
}
</script>

<style scoped>

</style>
