import ApiService from "@/common/api.service";

import {DELETE_FILE} from "./actions.type";

const state = {}

const getters = {}

const actions = {
  [DELETE_FILE](context, {fileName}) {
    return new Promise((resolve, reject) => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/upload/${fileName}/delete`)
        .then(result => {
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
}

const mutations = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
