<template>
  <div>
    <h3 class="flex items-center mb-8 text-character_gray-400">
      <span class="font-light text-2xl">Embed Code</span>
    </h3>

    <div class="flex mb-4">
      <div class="w-7/12 m-0 text-base">
        <slot name="description"></slot>
      </div>

      <div class="flex-1 flex justify-end items-end">
        <VButton size="large"
                 type="primary"
                 ref="btnCopyCode"
                 icon="code"
                 style="width: 140px;"
                 class="btn-copy"
                 @click="onCopyCode"
        >
          Copy code
        </VButton>
      </div>

    </div>

    <div class="code-block p-6 text-sm font-normal leading-tight">
      <div style="width: 400px" ref="code">
        <slot name="code"></slot>
      </div>
    </div>

  </div>
</template>

<script>
import VButton from "../components/VButton";

export default {
  name: "VEmbedCode",
  props: {type: String},
  components: {VButton},
  methods: {
    onCopyCode() {
      const code = this.$refs.code.getElementsByTagName('code')[0].innerText;
      const el = this.$refs.btnCopyCode.$el
      const btnName = el.innerHTML

      this.$copyText(code).then(() => {
        el.disabled = true
        el.innerHTML = '<span>Copied!</span>'
        setTimeout(() => {
          el.disabled = false
          el.innerHTML = btnName
        }, 5000)
      })

      this.$posthog.capture('Copied Embed Code', {surveyId: this.$route.params.id, embedType: this.type});
    }
  }
}
</script>

<style lang="less" scoped>
.code-block {
  background-color: fade(@v-color-blue-500, 5%);

  & code {
    overflow-wrap: break-word;
    font-family: Consolas, Menlo, Courier, monospace;
    font-weight: 400;
    font-size: 14px;
  }
}

.btn-copy {
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    background: @v-color-button-blue;
    color: #fff;
    border-color: @v-color-blue-500;
  }
}
</style>
