import ApiService from "@/common/api.service";
import {
  FETCH_SURVEYS,
  FETCH_TEMPLATES,
  FETCH_LAST_UPDATES,
  CREATE_FOLDER,
  UPDATE_FOLDER,
  DELETE_FOLDER
} from "./actions.type";
import {
  SET_SURVEYS,
  DELETE_SURVEY_MUTATION,
  SET_TEMPLATES,
  SET_LAST_UPDATES,
  SET_LAST_UPDATES_SEEN,
  CREATE_FOLDER_MUTATION,
  DELETE_FOLDER_MUTATION,
  UPDATE_FOLDER_MUTATION,
} from "./mutations.type";

const state = {
  surveys: [],
  folders: [],
  templates: [],
  templateGroups: [],
  latestUpdates: [],
  hasNewUpdates: false,
}

const getters = {
  surveys(state) {
    return state.surveys;
  },
  getSurveyById: (state) => (surveyId) => {
    return state.surveys.find(survey => survey._id === surveyId);
  },
  folders(state) {
    return state.folders;
  },
  getFolderById: (state) => (folderId) => {
    return state.folders.find(folder => folder._id === folderId);
  },
  templates(state) {
    return state.templates;
  },
  templateGroups(state) {
    return state.templateGroups;
  },
  latestUpdates(state) {
    return state.latestUpdates;
  },
  hasNewUpdates(state) {
    return state.hasNewUpdates;
  },
}

const actions = {
  [FETCH_SURVEYS]({commit}, {folderId}) {
    const url = folderId ? `${process.env.VUE_APP_API_URL}/admin/surveys/${folderId}` : `${process.env.VUE_APP_API_URL}/admin/surveys`;
    return new Promise((resolve, reject) => {
      ApiService.get(url)
        .then(response => {
          commit(SET_SURVEYS, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CREATE_FOLDER]({commit}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/folder`)
        .then(result => {
          commit(CREATE_FOLDER_MUTATION, result);
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [UPDATE_FOLDER]({commit, state}, {folderId, name}) {
    const timestamp = state.folders.find(folder => folder._id === folderId).updatedAt;
    return new Promise((resolve, reject) => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/folder/${folderId}`, {name, timestamp})
        .then(result => {
          commit(UPDATE_FOLDER_MUTATION, {folderId, name: result.name, updatedAt: result.updatedAt});
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [DELETE_FOLDER]({commit}, {folderId}) {
    return new Promise((resolve, reject) => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/folder/${folderId}`)
        .then(() => {
          commit(DELETE_FOLDER_MUTATION, folderId);
          resolve(folderId);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [FETCH_TEMPLATES]({commit}) {
    return new Promise(resolve => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey_templates`)
        .then(response => {
          commit(SET_TEMPLATES, response);
          resolve(response);
        })
        .catch(error => {
          throw new Error(error);
        });
    });
  },
  [FETCH_LAST_UPDATES]({commit}) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/latest_updates`)
        .then(response => {
          commit(SET_LAST_UPDATES, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

const mutations = {
  [SET_SURVEYS](state, data) {
    state.surveys = data.surveys;
    state.folders = data.folders;
  },
  [DELETE_SURVEY_MUTATION](state, surveyId) {
    const objIndex = state.surveys.findIndex(obj => obj._id === surveyId)
    if (objIndex !== -1) {
      state.surveys.splice(objIndex, 1)
    }
  },
  [CREATE_FOLDER_MUTATION](state, payload) {
    state.folders.push(payload);
  },
  [UPDATE_FOLDER_MUTATION](state, {folderId, name, updatedAt}) {
    const objIndex = state.folders.findIndex(obj => obj._id === folderId)
    if (objIndex !== -1) {
      state.folders[objIndex].name = name;
      state.folders[objIndex].updatedAt = updatedAt;
    }
  },
  [DELETE_FOLDER_MUTATION](state, folderId) {
    const objIndex = state.folders.findIndex(obj => obj._id === folderId)
    if (objIndex !== -1) {
      state.folders.splice(objIndex, 1)
    }
  },
  [SET_TEMPLATES](state, data) {
    const {templates, groups} = data;

    state.templates = templates;
    state.templateGroups = groups;
  },
  [SET_LAST_UPDATES](state, payload) {
    state.latestUpdates = payload.latestUpdates;
    state.hasNewUpdates = payload.hasNewUpdates;
  },
  [SET_LAST_UPDATES_SEEN](state) {
    state.hasNewUpdates = false;
  },
  addCollaboratorToFolder(state, {folderId, collaborator}) {
    const folder = state.folders.find(folder => folder._id === folderId);
    if (folder) {
      folder.collaborators.push(collaborator);
    }
  },
  addCollaboratorToSurvey(state, {surveyId, collaborator}) {
    const survey = state.surveys.find(survey => survey._id === surveyId);
    if (survey) {
      survey.collaborators.push(collaborator);
    }
  },
  removeCollaboratorFromFolder(state, {folderId, collaboratorId}) {
    const folder = state.folders.find(folder => folder._id === folderId);
    if (folder) {
      const collaboratorIndex = folder.collaborators.findIndex(collaborator => collaborator._id === collaboratorId);
      folder.collaborators.splice(collaboratorIndex, 1);
    }
  },
  removeCollaboratorFromSurvey(state, {surveyId, collaboratorId}) {
    const survey = state.surveys.find(survey => survey._id === surveyId);
    if (survey) {
      const collaboratorIndex = survey.collaborators.findIndex(collaborator => collaborator._id === collaboratorId);
      survey.collaborators.splice(collaboratorIndex, 1);
    }
  },
  updateCollaboratorInFolder(state, {folderId, collaborator}) {
    const folder = state.folders.find(folder => folder._id === folderId);
    if (folder) {
      const collaboratorIndex = folder.collaborators.findIndex(c => c._id === collaborator._id);
      folder.collaborators.splice(collaboratorIndex, 1, collaborator);
    }
  },
  updateCollaboratorInSurvey(state, {surveyId, collaborator}) {
    const survey = state.surveys.find(survey => survey._id === surveyId);
    if (survey) {
      const collaboratorIndex = survey.collaborators.findIndex(c => c._id === collaborator._id);
      survey.collaborators.splice(collaboratorIndex, 1, collaborator);
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
