<template>
  <div class="analytic-img_swipe flex rounded bg-neutral_gray-50 overflow-hidden">
    <ik-image v-if="subtype === 'textWithImage' && imgSrc"
              :src="imgSrc | parseImgUrl"
              class="object-cover w-full h-full"
              alt="Image"
    />
    <div v-else-if="subtype === 'textWithImage' && !imgSrc" class="m-auto text-center">
      <a-icon type="picture" style="font-size: 26px; color: var(--color-text-secondary)"></a-icon>
      <span class="block text-lg font-light mt-2 leading-none">No image</span>
    </div>
    <div v-else-if="subtype === 'textOnly'" class="m-auto text-center">
      <a-icon type="font-size" style="font-size: 26px; color: var(--color-text-secondary)"></a-icon>
      <span class="block text-lg font-light mt-2 leading-none">Text only</span>
    </div>
  </div>
</template>

<script>
import {parseImgUrl} from "../../../helpers/fns";

export default {
  name: "VQuestionSwipeImg",
  props: {
    imgSrc: {
      type: String,
      default: ''
    },
    subtype: {
      type: String
    }
  },
  filters: {parseImgUrl},
}
</script>

<style lang="less" scoped>
.analytic-img_swipe {
  height: 188px;
  width: 188px;
}
</style>
