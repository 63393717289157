<template>
  <label>
    <input :value="value"
           :checked="isChecked"
           @change="$emit('change', $event.target.value)"
           type="radio"
           name="action-radio"
           class="absolute opacity-0 cursor-pointer select-none">
    <span :style="`border-color: ${ribbon.color}`"
          class="radio-inner flex flex-col justify-between relative cursor-pointer select-none border-2 border-solid p-5">

      <span v-if="Object.keys(ribbon).length > 0" class="absolute w-full flex justify-center"
            style="top: -12px; left: 0;">
        <span class="label text-sm text-white leading-none px-2 py-1.25"
              style="box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); border-radius: 28px;"
              :style="`background: ${ribbon.color}`">
          {{ ribbon.text }}
        </span>
      </span>

      <span class="flex items-center justify-between">
        <span class="title text-xl leading-none">{{ title }}</span>

        <span class="flex-none justify-center">
          <span class="checkmark relative block h-6 w-6 rounded-full border-2 border-solid border-gray-500"></span>
        </span>
      </span>

    </span>
  </label>
</template>

<script>
export default {
  name: "VVerticalRadio",

  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    value: String,
    modelValue: {default: undefined},
    title: String,
    ribbon: Object,
  },

  computed: {
    isChecked() {
      return this.modelValue === this.value
    }
  }
}
</script>

<style lang="less" scoped>
@color-checked: #0095E9;

.radio-inner {
  background: @v-color-layout;
  border-radius: 10px;
  border-color: rgba(154, 165, 187, 0.7);
  transition: ease .3s;
}

.checkmark {
  &:after {
    content: '';
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: @v-color-button-blue;
    transition: ease .3s;
  }
}

.title {
  font-weight: 600;
}

input {
  &:hover {
    & ~ .radio-inner {
      background: #fff;
    }
  }

  &:checked {
    & ~ .radio-inner {
      background: #fff;

      & .checkmark {
        border-color: @color-checked;

        &:after {
          display: block;
        }
      }

      & .title {
        font-weight: 700;
      }

    }
  }
}
</style>
