<template>
  <div class="relative">
    <div v-if="isLimitReached" class="absolute w-full flex items-center justify-center space-x-8 z-20"
         style="top: 50px">
      <VIcon name="dron-flying" class="dron"/>
      <div>
        <div class="text-2xl font-bold mb-2 leading-none">
          <div v-if="hiddenResponsesNum > 0" class="flex items-center">
            <span class="resp-label text-white mr-1">{{ hiddenResponsesNum }}</span>
            extra responses waiting for you!
          </div>
          <div v-else>
            Extra responses waiting for you!
          </div>
        </div>

        <div class="text-base font-semibold leading-tight mb-5 text-character_gray-400"
             style="max-width: 500px;">
          <span v-if="hiddenResponsesNum > 0">
            You've reached the response limit for this period and have collected {{ hiddenResponsesNum }} extra responses
          that are currently
          hidden. Upgrade to our PRO plan to unlock extra responses and access all the responses you've collected so
          far. Your response limit will be reset on {{ responsesLimit.dateEnd | moment('ll') }}.
          </span>
          <span v-else>
            You've reached the response limit for this period. Upgrade to our PRO plan to unlock extra responses. Your
          response limit will be reset on {{ responsesLimit.dateEnd | moment('ll') }}.
          </span>
        </div>

        <div class="flex items-center space-x-3">
          <VButton type="violet" icon="crown" size="large" @click="onUpgrade">Upgrade to unlock</VButton>
          <VButton type="default" icon="import" size="large" @click="onClose">View old responses</VButton>
        </div>
      </div>
    </div>
    <div :class="{'blured z-10': isLimitReached}">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "./VButton";
import VIcon from "./VIcon";
import {SET_LIMIT_REACHED_ALERT} from "../store/mutations.type";

export default {
  name: "VLimitReached",

  components: {VButton, VIcon},

  computed: {
    ...mapGetters('auth', ["responsesLimit"]),
    ...mapGetters('survey', ["isLimitReachedAlert", "responses_summary"]),
    isLimitReached: function () {
      const {passedCount, availableCount} = this.responsesLimit;

      if (this.isLimitReachedAlert) return false;

      return passedCount >= availableCount;
    },
    hiddenResponsesNum: function () {
      return this.responses_summary.hiddenCount;
    }
  },

  methods: {
    onClose() {
      this.$store.commit(`survey/${SET_LIMIT_REACHED_ALERT}`, true);

      this.$posthog.capture('Clicked on View Old Results button on limit popup', {
        surveyId: this.$route.params.id,
        responsesCollected: this.responsesLimit.passedCount,
        responsesHidden: this.responses_summary.hiddenCount,
      });
    },

    onUpgrade() {
      this.$router.push({name: 'Billing'});

      this.$posthog.capture('Clicked on Upgrade button on limit popup', {
        surveyId: this.$route.params.id,
        responsesCollected: this.responsesLimit.passedCount,
        responsesHidden: this.responses_summary.hiddenCount,
      });
    }
  }
}
</script>

<style lang="less" scoped>
.blured {
  background: rgba(244, 246, 250, 0.7);
  filter: blur(8px);
}

.resp-label {
  background: @v-color-button-magenta;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px 8px;
}

@keyframes dron-flying {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.dron {
  animation: dron-flying 5s linear infinite;
}
</style>
