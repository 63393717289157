<template>
  <div>
    <div class="flex-none px-2.5 select-none space-x-1"
         style="border-radius: 4px;"
         :class="classComputed"
    >
      <a-icon :type="icon"></a-icon>
      <span class="text-base font-semibold leading-6">{{ text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VSubscriptionStatus",
  props: {
    status: String,
    icon: String,
    text: String
  },
  computed: {
    classComputed: function () {
      if (this.status === 'success') {
        return 'text-positive bg-light-green'
      }

      if (this.status === 'error') {
        return 'text-negative bg-light-negative'
      }

      if (this.status === 'warning') {
        return 'text-warning bg-light-warning'
      }

      if (this.status === 'info') {
        return 'text-info bg-light-info'
      }

      return 'text-gray-500 bg-light-gray'
    }
  }
}
</script>

<style lang="less" scoped>
.bg-light-green {
  background: rgba(57, 174, 97, 0.1);
}

.bg-light-negative {
  background: rgba(244, 53, 76, 0.1);
}

.bg-light-warning {
  background: rgba(250, 173, 20, 0.1);
}

.bg-light-gray {
  background: rgba(127, 140, 167, 0.1);
}

.text-warning {
  color: rgba(250, 173, 20, 1);
}

.bg-light-info {
  background: rgba(0, 149, 233, 0.1);
}

.text-info {
  color: rgba(0, 149, 233, 1);
}
</style>
