<template>
  <div class="mx-auto w-full pt-16 pb-20" style="max-width: 1134px;">

    <VSurveyBlock class="w-full pb-16">
      <template #title>
        Settings
      </template>
      <template #content>
        <div class="w-7/12">
          <h3 class="flex items-center mt-8 mb-6 text-character_gray-400 font-light text-2xl">
            Email notifications
          </h3>

          <div class="flex items-center">
            <a-switch class="switch_positive-color"
                      checked-children="On"
                      un-checked-children="Off"
                      v-model="user.notifications.weeklyReport"
                      @change="onChange"
            />
            <span class="ml-3 mr-1 font-light text-base">Weekly report</span>
            <a-tooltip>
              <template slot="title">
                Get a summary of your survey results in your inbox once a week.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
        </div>
      </template>
    </VSurveyBlock>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VSurveyBlock from "../components/VSurveyBlock";
import {UPDATE_USER_SETTINGS} from "../store/actions.type";

export default {
  name: "Notifications",

  components: {VSurveyBlock},

  computed: {
    ...mapGetters('auth', ['user']),
  },

  methods: {
    onChange() {
      this.$store.dispatch(`auth/${UPDATE_USER_SETTINGS}`, {notifications: this.user.notifications})
          .then(() => {
            this.$posthog.capture('Toggled Weekly Report', {
              email: this.user.email,
              value: this.user.notifications.weeklyReport,
            });
          })
    }
  }
}
</script>

<style scoped>

</style>
