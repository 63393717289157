<template>
  <div class="space-y-6 mb-16 pt-8">


    <div class="flex justify-between text-xl leading-none">
      <div class="flex">
        <div class="mr-5 font-light">
          Status:
          <span
              class="font-normal"
              :class="survey.visibility === true ? 'text-positive' : 'text-warning-500'"
          >
              {{ survey.visibility === true ? 'active' : 'paused' }}
          </span>
        </div>
        <div class="mr-5 font-light">
          Questions:
          <div
              v-if="!isLoadingChild"
              class="inline font-normal"
          >
            {{ questions_count }}
          </div>
          <a-icon v-else type="loading" style="font-size: 18px; color: var(--color-text-secondary)"></a-icon>
        </div>
      </div>
      <div class="font-light" :class="survey.password.active === true ? 'text-blue-700': 'text-positive'">
        <a-icon :type="survey.password.active === true ? 'lock': 'unlock'" theme="filled"></a-icon>
        {{ survey.password.active === true ? 'Password access' : 'No password' }}
      </div>
    </div>


    <div v-if="questions_count > questions_show_count">
      <a-alert type="error" show-icon>
        <p slot="message" class="font-normal mb-2">You have exceeded the limit of {{ questions_show_count }}
          questions</p>
        <p slot="description" class="font-light"> The answers to your extra questions have stopped collecting. Please
          consider
          <router-link :to="{name: 'Billing'}"> upgrading your plan</router-link>
        </p>
      </a-alert>
    </div>


    <a-collapse
        v-model="collapse.activeKey"
        :accordion="true"
        :bordered="false"
        expandIconPosition="right"
        class="space-y-6"
        style="background-color: transparent;"
        @change="saveCurrentStep"
    >
      <template #expandIcon="props">
        <a-icon type="down" :rotate="props.isActive ? 180 : 0"
                style="color: var(--color-gray-500); font-size: 24px;"/>
      </template>
      <a-collapse-panel
          v-for="panel in collapse.items"
          :key="panel.key"
          :forceRender="true"
          style="background: var(--color-base-theme); border: 1px solid var(--color-gray-300); box-sizing: border-box; box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05); border-radius: 8px;"
      >
        <template slot="header">
          <VBlockHeader :position="panel.key" :is-active="panel.key === collapse.activeKey">
            <template #title>{{ panel.name }}</template>
            <template #extra>
              <div v-if="panel.key === '1'" class="ml-4 flex items-center justify-start cursor-auto"
                   @click.stop
              >
                <a-switch
                    class="switch_violet-color"
                    checked-children="On"
                    un-checked-children="Off"
                    v-model="survey.randomize_questions"
                    @change="onRandomize"
                />
                <span class="ml-1.5 mr-1 font-light text-base select-none">
                    Randomize questions
                </span>
                <a-tooltip>
                  <template slot="title">
                    Shuffle your questions in a random order every time someone opens the survey.
                  </template>
                  <a-icon type="info-circle" class="icon-info"/>
                </a-tooltip>
              </div>
              <div v-if="panel.key === '2'" class="ml-4 flex items-center justify-start cursor-auto"
                   @click.stop
              >
                <a-switch v-model="survey.visibility"
                          checked-children="Active"
                          un-checked-children="Paused"
                          class="switch_status"
                          style="min-width: 76px;"
                          @change="onSurveyStatus"
                />
                <span class="ml-1.5 mr-1 font-light text-base select-none">Survey status</span>
                <a-tooltip>
                  <template slot="title">
                    You can pause your survey so that it stops collecting new responses.
                  </template>
                  <a-icon type="info-circle" class="icon-info"/>
                </a-tooltip>
              </div>
            </template>
          </VBlockHeader>
        </template>

        <div class="w-full"
             style="height: 16px; background: linear-gradient(180deg, rgba(154, 165, 187, 0.2) 0%, rgba(154, 165, 187, 0) 55.21%);">
        </div>

        <component :is="panel.content_component"></component>

        <a-divider :dashed="true" style="margin-bottom: 20px; border-color: var(--color-neutral-gray-300)"/>

        <div class="flex justify-end items-center pb-5 px-10 space-x-3">
          <div class="text-gray-300 text-base mr-3">
            Step {{ panel.key }} of {{ collapse.items.length }}
          </div>
          <VButton v-if="parseInt(panel.key) > 1"
                   size="large"
                   type="default"
                   icon="arrow-left"
                   class="uppercase"
                   style="font-weight: 600;"
                   @click="onStep(parseInt(collapse.activeKey) - 1)"
          >
            Previous step
          </VButton>
          <VButton v-if="panel.key < collapse.items.length"
                   size="large"
                   type="primary"
                   icon="arrow-right"
                   class="uppercase"
                   style="font-weight: 700;"
                   :disabled="!isSurveyReady"
                   @click="onStep(parseInt(collapse.activeKey) + 1)"
          >
            Next step
          </VButton>
        </div>
      </a-collapse-panel>
    </a-collapse>


  </div>
</template>

<script>
import SurveyDesignQuestions from "@/components/SurveyEdit/Design/SurveyDesignQuestions";
import SurveyDesignOptions from "@/components/SurveyEdit/Design/SurveyDesignOptions";
import SurveyDesignShare from "@/components/SurveyEdit/Design/SurveyDesignShare";
import SurveyDesignConnect from "@/components/SurveyEdit/Design/SurveyDesignConnect";
import SurveyDesignNotifications from "@/components/SurveyEdit/Design/SurveyDesignNotifications";
import {mapGetters} from "vuex";
import {surveyMixin} from "@/mixins/survey";
import _ from "lodash";
import VBlockHeader from "@/components/SurveyEdit/Design/VBlockHeader";
import VButton from "@/components/VButton";

export default {
  name: "SurveyEditDesign",
  components: {VBlockHeader, VButton},
  mixins: [surveyMixin],
  mounted() {
    let currentStep = this.getCurrentStep()
    if (currentStep) {
      this.collapse.activeKey = currentStep
    }
  },
  watch: {
    survey: {
      handler(newOptions, oldOptions) {
        if (!_.isEmpty(oldOptions)) {
          this.editSurvey(newOptions);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      collapse: {
        items: [
          // keys must be a string
          {key: '1', name: 'Questions', content_component: SurveyDesignQuestions},
          {key: '2', name: 'Survey options', content_component: SurveyDesignOptions},
          {key: '3', name: 'Connections', content_component: SurveyDesignConnect},
          {key: '4', name: 'Notifications', content_component: SurveyDesignNotifications},
          {key: '5', name: 'Sharing', content_component: SurveyDesignShare},
        ],
        activeKey: '1',
      },
    }
  },
  computed: {
    ...mapGetters('survey', ["survey", "isLoadingChild", "questions_count", "questions_show_count"]),
    isSurveyReady: function () {
      return this.questions_count > 0 || this.survey.comment.active
    },
  },
  methods: {
    onStep(step) {
      step = step.toString()
      this.collapse.activeKey = step
      this.saveCurrentStep(step)
    },
    saveCurrentStep(step) {
      if (step) {
        window.sessionStorage.setItem(`survey-step-${this.survey._id}`, step)
      } else {
        window.sessionStorage.removeItem(`survey-step-${this.survey._id}`)
      }
    },
    getCurrentStep() {
      return window.sessionStorage.getItem(`survey-step-${this.survey._id}`)
    },
    onSurveyStatus() {
      if (this.survey.visibility) {
        this.$posthog.capture('Survey Activated', {surveyId: this.survey._id});
      } else {
        this.$posthog.capture('Survey Paused', {surveyId: this.survey._id});
      }
    },
    onRandomize() {
      this.$posthog.capture('Toggled Randomize Questions', {
        surveyId: this.survey._id,
        value: this.survey.randomize_questions,
      });
    }
  }
}
</script>
