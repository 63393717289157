export const CollaboratorsMixin = {
  methods: {
    onInviteUser(surveyId = null, folderId = null) {
      this.$store.commit("collaborators/setModal", {
        isVisible: true,
        folderId,
        surveyId,
      });
    },
    onLeave(collaboratorId, surveyId, folderId) {
      this.$confirm({
        title: 'Are you sure?',
        content: `You will lose access to this ${surveyId ? 'survey and all its data' : 'folder and all its surveys'}.`,
        okText: 'Leave',
        cancelText: 'No, cancel',
        centered: true,
        maskClosable: true,
        onOk: () => this.$store.dispatch("collaborators/leave", {
          collaboratorId,
          surveyId,
          folderId,
        }).then(() => {
          this.$message.success(`You left the ${surveyId ? 'survey' : 'folder'}`);

          this.$store.commit("collaborators/setModal", {
            isVisible: false,
            surveyId: null,
            folderId: null,
          });

          if (this.$route.name !== "Index") {
            this.$router.replace({name: "Index"});
          }
        }),
      })
    },
  }
}
