<template>
  <div class="pt-3 pb-10 px-10">
    <h3 class="flex items-center mb-8 text-character_gray-400 font-light text-2xl">
      Notify me
    </h3>
    <div class="w-7/12">
      <p class="text-base mb-8">
        Get notified by email with survey results when someone completes this survey.
      </p>

      <div class="space-y-7">
        <a-switch class="switch_positive-color"
                  checked-children="On"
                  un-checked-children="Off"
                  v-model="survey.notifications.newResponseToOwner"
                  @change="onChange"
        />
        <span class="ml-3 mr-1 font-light text-base">Email notification</span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "SurveyDesignNotifications",

  computed: {
    ...mapGetters('survey', ['survey']),
  },

  methods: {
    onChange() {
      this.$posthog.capture('Toggled Email Notification', {
        surveyId: this.survey._id,
        value: this.survey.notifications.newResponseToOwner,
      });
    }
  }
}
</script>
