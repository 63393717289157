<template>
  <div class="survey-footer-block flex items-center justify-center py-3 px-10 fixed bottom-0 space-x-5 w-full">
    <VButton size="large"
             type="default-danger"
             icon="link"
             ref="btnCopy"
             style="width: 160px;"
             @click="doCopy"
    >
      Copy survey link
    </VButton>
    <VButton size="large"
             type="default-primary"
             icon="eye"
             style="width: 160px;"
             @click="previewSurvey"
    >
      Preview survey
    </VButton>
  </div>
</template>

<script>
import VButton from "@/components/VButton";
import surveyMixin from "@/mixins/survey";
import {mapGetters} from "vuex";

export default {
  name: "TheSurveyFooter",
  components: {VButton},
  mixins: [surveyMixin],
  computed: {
    ...mapGetters('survey', ["survey"]),
  }
}
</script>

<style lang="less" scoped>
.survey-footer-block {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.08);
  background-color: fade(@v-color-base-light, 92%);
  z-index: 1060;
}
</style>
