import ApiService from "@/common/api.service";

import {
  CANCEL_SUBSCRIPTION,
  SWITCH_SUBSCRIPTION,
  GET_PAY_LINK,
  PAUSE_SUBSCRIPTION,
  RENEW_SUBSCRIPTION,
  VALIDATE_COUPON,
  FETCH_PLANS,
  ACTIVATE_CODE,
  FETCH_ACTIVATED_CODES,
  CHECK_AUTH,
} from "./actions.type";
import {
  FETCH_START,
  SET_PLANS,
  SUBSCRIPTION_LOADING_START,
  SUBSCRIPTION_LOADING_END,
  SET_ACTIVATED_CODES,
  ACTIVATE_CODE_BY_VALUE,
} from "./mutations.type";

import fetchJsonp from "fetch-jsonp";
import _ from "lodash";


const state = {
  plans: [],
  codes: [],
  country: undefined,
  isLoading: false,
  isSubscriptionLoading: false,
  needUpgradeModal: {},
}

const getters = {
  plans(state) {
    return state.plans;
  },
  codes(state) {
    return state.codes;
  },
  country(state) {
    return state.country;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isSubscriptionLoading(state) {
    return state.isSubscriptionLoading;
  },
  needUpgradeModal(state) {
    return state.needUpgradeModal;
  }
}

const actions = {
  [FETCH_PLANS]({commit}) {
    commit(FETCH_START);
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/billing_plans`)
        .then(plans => {
          return plans;
        })
        .then((plans) => {
          const planIds = _.map(plans, 'planId').join(',');
          const checkoutMode = process.env.NODE_ENV === "dev" ? "sandbox-checkout" : "checkout"

          fetchJsonp(`https://${checkoutMode}.paddle.com/api/2.0/prices?product_ids=${planIds}`, {
            timeout: 30000,
          })
            .then(function (response) {
              return response.json()
            })
            .then(function (json) {
              if (json.response.products.length === 0) throw new Error("Prices doesn't exists");

              commit(SET_PLANS, {
                plans,
                prices: json.response.products,
                country: json.response.customer_country,
              });
              resolve(json)
            }).catch(function (ex) {
            reject(ex)
          })
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [CANCEL_SUBSCRIPTION]({commit}) {
    commit(SUBSCRIPTION_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/cancel_subscription`)
        .then(result => {
          if ((result.code && result.code.toString() === '422') || !result.success) {
            throw result.message;
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SUBSCRIPTION_LOADING_END);
        })
    });
  },

  [SWITCH_SUBSCRIPTION]({commit}, {planId}) {
    commit(SUBSCRIPTION_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/switch_subscription`, {subscriptionPlanId: planId})
        .then(result => {
          if ((result.code && result.code.toString() === '422') || !result.subscription_id) {
            throw result.message;
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SUBSCRIPTION_LOADING_END);
        })
    });
  },

  [PAUSE_SUBSCRIPTION]({commit}, {days}) {
    commit(SUBSCRIPTION_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/pause_subscription`, {days})
        .then(result => {
          if ((result.code && result.code.toString() === '422') || !result.subscription_id) {
            throw result.message;
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SUBSCRIPTION_LOADING_END);
        })
    });
  },

  [RENEW_SUBSCRIPTION]({commit}, {coupon}) {
    commit(SUBSCRIPTION_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/renew_subscription`, {coupon})
        .then(result => {
          if ((result.code && result.code.toString() === '422') || !result.subscription_id) {
            throw result.message;
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SUBSCRIPTION_LOADING_END);
        })
    });
  },

  [VALIDATE_COUPON](context, {coupon}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/validate_coupon`, {coupon})
        .then(result => {
          if ((result.code && result.code.toString() === '422')) {
            throw result.message;
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

  [GET_PAY_LINK](context, params) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/pay_link`, {...params})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },

  [FETCH_ACTIVATED_CODES]({commit}) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/activated_codes`)
        .then(response => {
          commit(SET_ACTIVATED_CODES, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },

  [ACTIVATE_CODE]({commit, dispatch}, {code}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/activate_code`, {code})
        .then(response => {
          commit(ACTIVATE_CODE_BY_VALUE, response);
          dispatch(`auth/${CHECK_AUTH}`, null, {root: true});
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },

}

const mutations = {
  [FETCH_START](state) {
    state.isLoading = true;
  },

  [SET_PLANS](state, {country, plans, prices}) {
    state.plans = _.merge(_.keyBy(plans, 'planId'), _.keyBy(prices, 'product_id'));
    state.country = country;
    state.isLoading = false;
  },

  [SUBSCRIPTION_LOADING_START](state) {
    state.isSubscriptionLoading = true;
  },

  [SUBSCRIPTION_LOADING_END](state) {
    state.isSubscriptionLoading = false;
  },

  [SET_ACTIVATED_CODES](state, codes) {
    state.codes = codes && codes.length ? codes : [{active: false, value: ''}];
  },

  [ACTIVATE_CODE_BY_VALUE](state, codeValue) {
    const code = _.find(state.codes, {value: codeValue});
    if (code) {
      _.set(code, 'active', true);
    }
  },
  setNeedUpgradeModal(state, value) {
    state.needUpgradeModal = value;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
