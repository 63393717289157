<template>
  <div class="shadow w-full h-full rounded-xl border-1.5 border-dashed border-blue-500 light-blue-bg cursor-pointer group"
       role="button"
       @click="$emit('click')">
    <div class="flex flex-col items-center justify-center h-full w-full text-blue-500 text-3xl">
      <a-icon :type="item.icon"/>
      <div class="text-xl font-semibold">{{ item.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewItem",
  props: {
    item: {
      type: Object,
      default: () => ({
        icon: 'plus',
        text: 'New survey',
      })
    }
  }
}
</script>

<style lang="less" scoped>
.light-blue-bg {
  background: linear-gradient(0deg, rgba(0, 204, 255, 0.06), rgba(0, 204, 255, 0.06)), #FFFFFF;
}

.shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
}
</style>
