<template>
  <div class="space-y-8">

    <div class="text-lg leading-tight">
      <p>Your questions will be added as Swipe Reply questions.</p>
      <p class="mb-0">Put your questions in one column without a heading, each on a new row.</p>
      <p>Empty rows are ignored.</p>
      <p>See example <a :href="$constants('LINK_IMPORT_QUESTIONS_EXAMPLE_DOC')" target="_blank">here</a>.</p>
    </div>

    <div :class="{'opacity-60': isUploadDisabled}">
      <a-upload-dragger
          name="file"
          accept="text/csv"
          :before-upload="onBeforeUpload"
          :showUploadList="false"
          :disabled="isUploadDisabled"
      >
        <div>
          <a-icon type="file-text" class="upload-icon"/>
        </div>
        <div class="ant-upload-text select-none">
          Click or drag CSV file here
        </div>
      </a-upload-dragger>

      <div v-if="file" class="flex justify-between items-center mt-2">
        <div class="space-x-1">
          <a-icon type="paper-clip"/>
          <span>{{ file.name }}</span>
        </div>
        <a-icon type="delete"
                class="cursor-pointer hover:text-negative transition duration-100"
                @click.prevent="onRemoveFile"
        />
      </div>
    </div>

    <transition
        enter-active-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="opacity-100"
        leave-to-class="opacity-0"
    >
      <div v-show="file && resultData">
        <a-table :columns="columns"
                 :data-source="resultData"
                 :pagination="false"
                 :bordered="true"
                 :scroll="{x: false, y: 300}"
        >
          <span slot="customQuestionsTitle">Questions <span v-if="resultData">({{ resultData.length }})</span></span>
        </a-table>
      </div>
    </transition>

    <VImportAlert :data="resultData"/>

  </div>
</template>
<script>
import Papa from "papaparse";
import _ from "lodash";
import VImportAlert from "./VImportAlert";
import {mapGetters} from "vuex";

export default {
  name: 'VCsvImport',
  components: {VImportAlert},
  props: {
    mapFields: {
      required: true
    },
  },
  data() {
    return {
      file: null,
      csv: null,
      resultData: null,
      columns: [
        {
          dataIndex: 'question',
          key: 'question',
          slots: { title: 'customQuestionsTitle' },
          sorter: false,
        },
      ],
    };
  },
  watch: {
    csv: {
      handler: function () {
        this.resultData = this.buildMappedCsv()
        this.$emit('input', this.resultData)
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'questions_count']),
    isUploadDisabled: function () {
      const maxQuestions = this.survey.features.questions_count
      return maxQuestions && this.questions_count >= maxQuestions
    }
  },
  methods: {
    onBeforeUpload(file) {
      const _this = this

      this.file = file

      this.readFile((output) => {
        _this.csv = _.get(Papa.parse(output, {skipEmptyLines: true}), "data");
      })

      return false // must return false (https://1x.antdv.com/components/upload#API)
    },
    readFile(callback) {
      let file = this.file

      if (file) {
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        reader.onload = function (evt) {
          callback(evt.target.result);
        };
        reader.onerror = function () {
        };
      }
    },
    buildMappedCsv() {
      const _this = this;

      return _.map(this.csv, (row) => {
        let newRow = {};

        _.forEach(_this.mapFields, (field, column) => {
          _.set(newRow, field, row[column]);
        });

        return newRow;
      });
    },
    onRemoveFile() {
      this.file = null
      this.csv = null
    }
  },
};
</script>
