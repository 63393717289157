import {startsWith} from "lodash/string";

export function parseImgUrl(url) {

  if(!startsWith(url, process.env.VUE_APP_API_URL) || process.env.NODE_ENV === "dev") return url

  let arSplittedUrl = url.split("/")
  return process.env.VUE_APP_IMAGEKIT_URL_ENDPOINT + arSplittedUrl[arSplittedUrl.length - 1]

}
