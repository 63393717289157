<template>
  <div @click.capture="needUpgradeMessage">
    <slot></slot>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import store from "../store";

export default {
  name: "VProElement",
  props: ["needUpgrade", "name", "isPlusAddon"],
  computed: {
    ...mapGetters('auth', ["user", "isUserLifetime"])
  },
  methods: {
    needUpgradeMessage() {
      if (!this.needUpgrade) return;

      store.commit("billing/setNeedUpgradeModal", {
        show: true,
        actionName: this.name.toLowerCase(),
        isPlusAddon: this.isPlusAddon
      });
    },
  }
}
</script>

<style scoped>

</style>
