<template>
  <div class="flex flex-col justify-between flex-none py-3">
    <VButton class="btn-sort"
             :class="{'hover:transform hover:-translate-y-1': questionIndex > 0}"
             style="background: transparent"
             :disabled="questionIndex === 0"
             @click="onSort(questionIndex - 1)"
    >
      <a-icon type="arrow-up" style="font-size: 32px;"/>
    </VButton>

    <VButton class="btn-sort cursor-move drag-handle"
             style="background: transparent; display:block;"
    >
      <VIcon name="drag-points"></VIcon>
    </VButton>

    <VButton class="btn-sort"
             :class="{'hover:transform hover:translate-y-1': questionIndex !== questionsLength-1}"
             style="background: transparent"
             :disabled="questionIndex === questionsLength-1"
             @click="onSort(questionIndex + 1)"
    >
      <a-icon type="arrow-down" style="font-size: 32px;"/>
    </VButton>
  </div>
</template>

<script>
import VButton from "../../VButton";
import VIcon from "../../VIcon";

export default {
  name: "VQuestionSort",
  components: {VIcon, VButton},
  props: ["questionIndex", "questionsLength"],
  methods: {
    onSort(relatedIndex) {
      if (relatedIndex >= 0 && relatedIndex <= this.questionsLength - 1) {
        this.$emit('makeSort', {relatedIndex})
      }
    }
  }
}
</script>

<style lang="less" scoped>
.btn-sort {
  color: var(--color-gray-500);
  padding: 0;
  border: 0;
  box-shadow: none;

  &:hover,
  &:focus {
    color: var(--color-gray-500);
  }

  &:after {
    display: none;
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus {
    color: var(--color-gray-500);
    cursor: default;
    opacity: .25;
  }
}
</style>
