<template>
  <div class="space-y-8">

    <div class="text-lg leading-tight">
      <p>Select up to {{ maxFilesToUpload }} images and it will turn into Swipe Reply questions.</p>
      <p class="mb-0">Image size should be less than 2MB.</p>
      <p>Recommended size is 400x300px.</p>
    </div>

    <div class="import-image-uploader clearfix space-y-2"
         :class="{'opacity-60': isUploadDisabled}"
    >
      <a-upload-dragger name="image"
                        accept="image/*"
                        list-type="picture-card"
                        :file-list="fileList"
                        :beforeUpload="onBeforeUpload"
                        :multiple="true"
                        :showUploadList="{ showPreviewIcon: false }"
                        :disabled="isUploadDisabled"
                        @change="handleChange"
      >
        <div>
          <a-icon type="picture" class="upload-icon"/>
        </div>
        <div class="ant-upload-text select-none">
          Click or drag images here
        </div>
      </a-upload-dragger>
    </div>

    <VImportAlert :data="fileList"/>
  </div>
</template>

<script>
import VImportAlert from "./VImportAlert";
import {mapGetters} from "vuex";
import _ from "lodash";

export default {
  name: "VImagesImport",
  components: {VImportAlert},
  data() {
    return {
      fileList: [],
      maxFilesToUpload: 30, // If modified change it on backend too
    }
  },
  watch: {
    fileList: {
      handler: function () {
        let files = _.map(this.fileList, (file) => file.originFileObj)
        this.$emit('input', files)
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'questions_count']),
    isUploadDisabled: function () {
      const maxQuestions = this.survey.features.questions_count
      return maxQuestions && this.questions_count >= maxQuestions
    }
  },
  methods: {
    onBeforeUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/apng' || file.type === 'image/avif' || file.type === 'image/gif' || file.type === 'image/svg+xml' || file.type === 'image/webp';
      if (!isImage) {
        file.status = 'error'
        file.response = 'You can only upload images'
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        file.status = 'error'
        file.response = 'The image size should be smaller than 2MB'
      }

      return false;
    },
    handleChange({ fileList }) {
      if (fileList.length <= this.maxFilesToUpload) {
        this.fileList = fileList
      } else {
        this.$message.error(`You can import up to ${this.maxFilesToUpload} images at a time!`);
        this.fileList = fileList.slice(0, this.maxFilesToUpload);
      }
    }
  }
}
</script>

<style scoped>

</style>
