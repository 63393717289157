<template>
  <div class="space-y-4">
    <div
        v-for="(item, index) in survey.calculatedFields"
        :key="index">
      <div class="flex items-center space-x-3">
        <a-input
            size="large"
            placeholder="Field name"
            v-model="item.name"/>
        <a-select
            v-model="item.type"
            size="large"
            class="flex-none"
            style="width: 110px">
          <a-select-option
              v-for="type in types"
              :key="type.value"
              :value="type.value">
            {{ type.title }}
          </a-select-option>
        </a-select>
        <a-input
            size="large"
            placeholder="Initial value"
            v-model="item.value"/>
        <VButton
            v-show="survey.calculatedFields.length > 1"
            type="default-negative-hover"
            size="large"
            style="height: 40px; width: 40px; font-size: 20px;"
            class="flex-none ml-3"
            icon="delete"
            @click="onDelete(index)"/>
      </div>
    </div>

    <a-button
        size="large"
        icon="plus"
        block
        class="btn-add"
        style="text-transform: uppercase;"
        :loading="isAdding"
        @click="onAdd">
      New calculated field
    </a-button>
  </div>
</template>

<script>
import VButton from "../VButton";
import {mapGetters} from "vuex";
import store from "../../store";
import {CALCULATED_FIELD_CREATE} from "../../store/actions.type";

export default {
  name: "VConditionalLogicItem",
  components: {VButton},
  data() {
    return {
      types: [
        {value: "number", title: "Number"},
        {value: "string", title: "String"}
      ],
      isAdding: false,
    }
  },
  computed: {
    ...mapGetters('survey', ["survey"]),
  },
  methods: {
    onDelete(index) {
      this.survey.calculatedFields.splice(index, 1);
    },
    async onAdd() {
      this.isAdding = true;
      try {
        await store.dispatch(`survey/${CALCULATED_FIELD_CREATE}`, {survey_id: this.$route.params.id});
      } finally {
        this.isAdding = false;
      }
    },
  }
}
</script>

<style scoped>

</style>
