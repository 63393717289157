<template>
  <div>
    <VIcon :name="icon" />
  </div>
</template>

<script>
import VIcon from "./VIcon";

export default {
  name: "VQuestionIcon",
  components: {VIcon},

  props: ['type', 'subtype'],
  computed: {
    icon: function () {
      const questionTypeIcons = {
        swipe: {
          icon: null,
          subtypes: {
            textWithImage: {
              icon: 'question-swipe-image'
            },
            textOnly: {
              icon: 'question-swipe-text'
            },
          }
        },
        nps: {
          icon: null,
          subtypes: {
            horizontal: {
              icon: 'question-nps-horizontal'
            },
            vertical: {
              icon: 'question-nps-vertical'
            }
          }
        },
        multiple: {
          icon: 'question-multiple',
          subtypes: {}
        },
        rating: {
          icon: null,
          subtypes: {
            smilesColorful: {
              icon: 'question-smiles-color'
            },
            smilesGray: {
              icon: 'question-smiles-gray'
            },
            stars: {
              icon: 'question-stars'
            }
          }
        },
        open: {
          icon: null,
          subtypes: {
            singleLine: {
              icon: 'question-open-single-line'
            },
            multiLine: {
              icon: 'question-open-multi-line'
            },
            number: {
              icon: 'question-open-single-line'
            },
            email: {
              icon: 'question-open-email'
            },
            phone: {
              icon: 'question-open-phone'
            },
          }
        },
        comment: {
          icon: 'question-last-comment',
          subtypes: {}
        },
        welcome: {
          icon: 'welcome-screen',
          subtypes: {}
        }
      };
      const type = this.type;
      const subtype = this.subtype;

      let icon = questionTypeIcons[type].icon;
      if (icon === null) {
        icon = questionTypeIcons[type].subtypes[subtype].icon;
      }

      return icon;
    }
  }
}
</script>
