<template>
  <VSubscriptionLayout :data="currentPlan">


    <template #plan-extra>
      <span class="text-blue-500">
        Subscription is up and running
      </span>
    </template>


    <template #plan-buttons>

      <div class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default" style="height: 36px;" @click="onPause">
          <a-icon type="pause" style="line-height: 0;"/>
        </VButton>
        <div class="text-base leading-none">Pause</div>
      </div>

      <div class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default-negative" style="height: 36px;" @click="onCancel">
          <a-icon type="stop" style="line-height: 0;"/>
        </VButton>
        <div class="text-base leading-none">Cancel</div>
      </div>

      <a-modal :visible="modal.pause.visible"
               :closable="true"
               :width="698"
               :footer="null"
               :destroyOnClose="true"
               @cancel="modal.pause.visible = false"
      >
        <template #title>
          <div class="text-3xl font-light leading-tight" style="color: #262626;">{{ modal.pause.title }}</div>
        </template>

        <template #closeIcon>
          <div class="flex items-center justify-center text-gray-300 hover:text-gray-400 transition duration-100"
               style="height: 70px; font-size: 26px;">
            <VIcon name="close"/>
          </div>
        </template>

        <div class="space-y-4">
          <div v-for="item in modal.pause.items" :key="item.value">
            <VVerticalRadio :title="item.title"
                                      :value="item.value"
                                      v-model="selectedPauseAction"
                                      :ribbon="item.ribbon"/>
          </div>
        </div>

        <a-divider style="margin: 24px 0 12px;"/>

        <div class="text-base font-light leading-tight mb-7">
          <p>
            <b>Pausing</b> your subscription will <b class="text-orange-500">block</b> access to <b>PRO features</b> and collecting <b>extra responses</b>, but keep it without
            being billed.
          </p>
          <p class="mb-0">
            Your subscription will be paused at the end of your current billing cycle on <b>{{ user.subscription.nextBillDate | moment('ll') }}</b>.
          </p>
        </div>

        <div class="flex justify-center items-center space-x-3">
          <VButton type="default" size="large" @click="modal.pause.visible = false" style="width: 184px;">
            <a-icon type="close"/>
            <span class="uppercase">{{ modal.pause.cancelText }}</span>
          </VButton>
          <VButton type="warning" size="large" @click="doPause" style="width: 184px;">
            <a-icon type="pause"/>
            <span class="uppercase">{{ modal.pause.okText }}</span>
          </VButton>
        </div>

      </a-modal>

      <a-modal :visible="modal.cancel.visible"
               :closable="true"
               :width="698"
               :footer="null"
               :destroyOnClose="true"
               @cancel="modal.cancel.visible = false"
      >

        <template #title>
          <div class="text-3xl font-light leading-tight" style="color: #262626;">{{ modal.cancel.title }}</div>
        </template>

        <template #closeIcon>
          <div class="flex items-center justify-center text-gray-300 hover:text-gray-400 transition duration-100"
               style="height: 70px; font-size: 26px;">
            <VIcon name="close"/>
          </div>
        </template>

        <div class="space-y-4">
          <div v-for="item in modal.cancel.items" :key="item.value">
            <VVerticalRadio :title="item.title"
                                      :value="item.value"
                                      v-model="selectedCancelAction"
                                      :ribbon="item.ribbon"/>
          </div>
        </div>

        <a-divider style="margin: 24px 0 12px;"/>

        <div class="text-base font-light leading-tight mb-7">
          <p>
            <a-icon type="pause" class="text-orange-500 mr-2"/>
            <b>Pausing</b> your subscription will <b class="text-orange-500">block</b> access to <b>PRO features</b> and collecting <b>extra responses</b>, but keep it without
            being billed.
          </p>
          <p>
            <a-icon type="stop" class="text-negative mr-2"/>
            <b>Cancelling</b> your subscription will result in <b class="text-negative">loss</b> access to PRO features and <b class="text-negative">delete</b> extra responses.
          </p>
          <p class="mb-0">
            Your subscription will be active until the end of your current billing cycle <b>{{ user.subscription.nextBillDate | moment('ll') }}</b>.
          </p>
        </div>

        <div class="flex justify-center items-center space-x-3">
          <VButton type="default" size="large" @click="modal.cancel.visible = false" style="width: 184px;">
            <a-icon type="close"/>
            <span class="uppercase">{{ modal.cancel.cancelText }}</span>
          </VButton>
          <VButton v-if="selectedCancelAction !== 'cancel'"
                   @click="doPauseCancel"
                   type="warning"
                   size="large"
                   style="width: 184px;">
            <a-icon type="pause"/>
            <span class="uppercase">Pause</span>
          </VButton>
          <VButton v-else
                   @click="doCancel"
                   type="negative"
                   size="large"
                   style="width: 184px;">
            <a-icon type="stop"/>
            <span class="uppercase">Cancel</span>
          </VButton>
        </div>

      </a-modal>
    </template>


    <template #plan-billing-link>
      <div class="flex items-center space-x-1.5">
        <a-icon type="credit-card" class="text-gray-300 text-base"></a-icon>
        <a :href="user.subscription.updateUrl"
           target="_blank"
           class="text-base leading-tight underline hover:text-blue-700 hover:no-underline focus:text-blue-700 focus:no-underline"
           style="color: rgba(0, 149, 233, 1);">Update payment method</a>
      </div>
    </template>


    <template #plans>
      <a-skeleton :loading="isLoading" active>
        <div class="flex items-start space-x-3 pl-20">
          <span class="text-xl leading-tight">Switch your plan:</span>
          <div class="flex justify-end space-x-3">
            <VPlanRadio v-for="plan in plansComputed"
                        :key="plan.product_id"
                        :title="plan.title"
                        :price="+(plan.subscription.price.gross).toFixed(0)"
                        :currency-symbol="plan.currencySymbol"
                        :value="plan.product_id"
                        :checked-default="selectedPlanDefault"
                        v-model="selectedPlan"
                        :ribbon="getRibbon(plan)"
            >
              <template #extra>
                <div>
                  <div v-if="plan.subscription.interval === 'year'"
                       class="text-sm leading-4"
                       style="color: #7F8CA7;">
                    {{ plan.currencySymbol }}{{ (plan.subscription.price.gross / 12).toFixed(0) }} per month
                  </div>
                  <div class="text-sm leading-4 text-blue-500">
                    <span v-if="plan.responses.toLowerCase() === 'unlimited'">
                      <VIcon name="infinite" class="text-blue-500"/>
                    </span>
                    <span v-else>
                      {{ plan.responses }}
                    </span>
                    responses
                  </div>
                </div>
              </template>
            </VPlanRadio>
          </div>
        </div>
      </a-skeleton>
    </template>


    <template #plan-update-button>
      <VButton v-if="!isLoading"
               :disabled="selectedPlan === currentPlan.planId"
               @click="onSwitch(selectedPlan)"
               type="primary"
               size="large"
               style="width: 184px;">
        <a-icon type="poweroff"></a-icon>
        <span class="uppercase font-bold">Update</span>
      </VButton>
    </template>


  </VSubscriptionLayout>
</template>

<script>
import {subscriptionProMixin} from "../../mixins/subscriptionPro";
import _ from "lodash";

export default {
  name: "SubscriptionActive",

  mixins: [subscriptionProMixin],

  data() {
    return {
      modal: {
        pause: {
          title: 'Pause subscription',
          visible: false,
          okText: 'Pause',
          cancelText: 'No, stay',
          items: [
            {
              value: 'pause1',
              days: null,
              title: 'Pause for 1 month',
              ribbon: {}
            },
            {
              value: 'pause2',
              days: null,
              title: 'Pause for 2 months',
              ribbon: {}
            },
          ]
        },
        cancel: {
          title: 'How about pausing instead?',
          visible: false,
          okText: 'Confirm cancellation',
          cancelText: 'No, stay',
          items: [
            {
              value: 'pause1',
              days: 30,
              title: 'Pause for 1 month and get 10% off coupon',
              ribbon: {color: '#FF8743', text: '10% discount'},
            },
            {
              value: 'pause2',
              days: null,
              title: 'Pause for 2 months',
              ribbon: {}
            },
            {value: 'cancel', title: 'Cancel subscription', ribbon: {}},
          ]
        }
      },
      selectedPauseAction: 'pause1',
      selectedCancelAction: 'pause1',
    }
  },

  methods: {
    onPause() {
      this.modal.pause.visible = true;

      this.$posthog.capture('Clicked on Pause Sub button');
    },

    onCancel() {
      this.modal.cancel.visible = true;

      this.$posthog.capture('Clicked on Cancel Sub button');
    },

    doPause() {
      const { days } = _.find(this.modal.pause.items, (item) => item.value === this.selectedPauseAction);
      this.modal.pause.visible = false
      return this.makePause(days)
    },

    doPauseCancel() {
      const { days } = _.find(this.modal.cancel.items, (item) => item.value === this.selectedCancelAction);
      this.modal.cancel.visible = false
      return this.makePause(days)
    },

    doCancel() {
      this.modal.cancel.visible = false
      return this.makeCancel()
    },
  }
}
</script>
