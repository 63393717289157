<template>
  <a :href="link" target="_blank" class="text-base">
    <a-icon type="question-circle"/>
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "VLearnMore",
  props: {
    link: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: "Learn more",
    },
  },
}
</script>

<style scoped>

</style>
