<template>
  <div class="extra-collapse mt-5">
    <a-collapse :bordered="false" expandIconPosition="right">

      <template #expandIcon="props">
        <div class="relative">
          <a-icon type="down"
                  :rotate="props.isActive ? 180 : 0"
                  style="color: var(--color-text-description); font-size: 16px;"/>
        </div>
      </template>
      <a-collapse-panel key="logic">

        <template slot="header">
          <div class="flex items-center space-x-1.5 text-xl leading-none">
            <a-icon type="calculator" style="color: var(--color-text-secondary); font-size: 24px;"/>
            <span class="text-character_gray-400 border-0 border-b border-dashed border-character_gray-400">
              Calculated fields
            </span>
          </div>
        </template>

        <div style="min-width: 650px">
          <p class="mt-2 mb-4">
            <VLearnMore :link="$constants('LINK_HELP_CALCULATED_FIELDS')"/>
          </p>
          <VCalculatedFields/>
        </div>

      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import VCalculatedFields from "./VCalculatedFields";
import VLearnMore from "../VLearnMore";

export default {
  name: "VCalculatedFieldsCollapse",
  components: {VCalculatedFields, VLearnMore},
}
</script>

<style scoped>

</style>
