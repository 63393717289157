<template>
  <div class="w-full mt-5">

    <h2 v-if="$route.name !== 'Upgrade'" class="text-3xl text-dark_gray-500 mb-5">
      Plan <span class="font-bold">upgrade</span>
    </h2>
    <div v-else class="mb-4">
      <h2 class="text-3xl text-dark_gray-500 mb-0">
        Activate license codes
      </h2>
      <p class="text-base text-character_gray-300 mb-0">If you have LTD codes, activate them here. Features and responses vary based on your license tier.</p>
    </div>

    <div class="bg-white rounded-xl px-6 pt-7 pb-6 shadow-custom">

      <div v-if="$route.name !== 'Upgrade'" class="flex items-start justify-between">
        <div>
          <div class="leading-tight text-xl">
            Activate license codes
          </div>
        </div>

        <div class="flex justify-center items-start space-x-4">
          <div class="flex items-center space-x-1 text-base">
            <a-icon :type="features.remove_branding ? 'check' : 'close'"
                    :class="features.remove_branding ? 'text-positive' : 'text-negative'"
            />
            <span>Remove branding</span>
          </div>
          <div class="flex items-center space-x-1 text-base">
            <a-icon :type="features.custom_domain ? 'check' : 'close'"
                    :class="features.custom_domain ? 'text-positive' : 'text-negative'"
            />
            <span>Custom domain</span>
          </div>
          <div class="flex items-center space-x-1 text-base">
            <span class="text-blue-500">{{ features.responses_count }}</span>
            <span>responses</span>
          </div>
        </div>
      </div>

      <a-divider v-if="$route.name !== 'Upgrade'" style="margin: 24px 0;"/>

      <div class="flex space-x-4">
        <div class="flex-grow" style="max-width: 708px;">
          <a-form-model ref="codesForm"
                        :model="codesForm"
                        class="codes-form"
          >
            <a-form-model-item
                v-for="(code, index) in codes"
                :key="index"
                :prop="'codes.' + index + '.value'"
                :rules="{validator: validateField, trigger: [null]}"
                :validate-status="code.active ? 'success' : ''"
                has-feedback
            >
              <div class="flex items-center">
                <div class="flex flex-none items-center justify-center w-8 h-8 mr-2 bg-gray-300 rounded-full">
                  <span class="text-white font-light text-xl font-bold" v-text="index + 1"></span>
                </div>
                <a-input placeholder="Enter your code here"
                         size="large"
                         v-model="code.value"
                         :disabled="code.active || isLoading"
                         class="mr-3"
                />
                <VButton type="default"
                         size="large"
                         style="height: 40px; min-width: 96px;"
                         @click="onActivate(index)"
                         :disabled="code.active || isLoading">
                  {{ code.active ? "Activated!" : "Activate" }}
                </VButton>
                <VButton type="default-negative-hover"
                         size="large"
                         style="height: 40px; width: 40px; font-size: 20px;"
                         class="ml-4"
                         :disabled="code.active || codes.length === 1 || isLoading"
                         @click="deleteCode(code)"
                >
                  <div class="flex">
                    <a-icon type="delete"/>
                  </div>
                </VButton>
              </div>
            </a-form-model-item>
          </a-form-model>

          <a-button v-show="codes.length < maxCodesCount"
                    type="default"
                    block
                    size="large"
                    icon="plus"
                    class="btn-add mt-6"
                    @click="addCode"
          >
            Add code
          </a-button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import VButton from "../VButton";
import {ACTIVATE_CODE, FETCH_ACTIVATED_CODES} from "../../store/actions.type";
import {mapGetters} from "vuex";

export default {
  name: "ActivateCodes",

  components: {VButton},

  created() {
    this.$store.dispatch(`billing/${FETCH_ACTIVATED_CODES}`);
  },

  computed: {
    ...mapGetters('auth', ["user", "features"]),
    ...mapGetters('billing', ["codes"]),

    codesForm() {
      return {codes: this.codes}
    },
  },

  data() {
    return {
      maxCodesCount: 4,
      isLoading: false,
    }
  },

  methods: {
    addCode() {
      if (this.codes.length >= this.maxCodesCount) return;

      this.codes.push({active: false, value: ''});
    },

    deleteCode(item) {
      let index = this.codes.indexOf(item);
      if (index !== -1) {
        this.codes.splice(index, 1);
      }
    },

    onActivate(index) {
      this.$refs.codesForm.validateField(`codes.${index}.value`);
    },

    validateField(rule, value, callback) {
      const isNotActivated = this.codes.find(field => {
        return !field.active && field.value.trim() === value.trim();
      });

      if (value.trim() === '') {
        callback('This field can\'t be empty')
      } else if (isNotActivated) {
        this.isLoading = true;

        this.$store.dispatch(`billing/${ACTIVATE_CODE}`, {code: value})
            .then(() => {
              callback()

              const key = 'code-activated';
              this.$notification.open({
                key,
                duration: 8,
                message: <div class="text-positive text-base mb-2">Code Activated - Plan Upgraded!</div>,
                description: 'Congratulations! Your code has been activated, and your plan has been upgraded to the next level!',
                icon: <a-icon type="check" class="text-positive"/>,
                style: {width: '467px', marginLeft: `${384 - 467}px`},
                btn: h => {
                  return h(
                      'a-button',
                      {
                        props: {
                          type: 'primary',
                          size: 'small',
                        },
                        on: {
                          click: () => {
                            this.$notification.close(key);
                          }
                        },
                      },
                      'Got it!',
                  );
                }
              });

              if (this.$route.name === 'Upgrade') {
                this.$router.push({name: 'Billing'});
              }

              this.$posthog.capture('Lifetime Code Activated', {
                email: this.user.email,
                oldPlan: this.user.current_plan,
              });
            })
            .catch(() => {
              callback("Invalid code")
            })
            .finally(() => {
              this.isLoading = false;
            })
      } else {
        callback()
      }
    },
  }
}
</script>

<style scoped>

</style>
