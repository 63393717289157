<template>
  <div></div>
</template>

<script>
import {mapGetters} from "vuex";
import {Modal} from "ant-design-vue";
import VPlusAddonFeatures from "../VPlusAddonFeatures";
import {UpgradeMixin} from "../../mixins/upgrade.mixin";

export default {
  name: "UpgradeNeededModal",
  mixins: [UpgradeMixin],
  watch: {
    "needUpgradeModal.show": {
      handler(newValue) {
        if (newValue) this.needUpgradeMessage();
      },
    }
  },
  computed: {
    ...mapGetters('auth', ["user", "isUserLifetime"]),
    ...mapGetters('billing', ["needUpgradeModal"]),
  },
  methods: {
    needUpgradeMessage() {
      let modalContent = this.getModalContent();

      this.closeOtherModals();
      Modal.confirm(modalContent);

      this.$posthog.capture('Tried to Activate the Blocked Feature', {featureName: this.needUpgradeModal?.actionName?.toLowerCase()});
    },
    getModalContent() {
      if (this.needUpgradeModal?.isPlusAddon && this.isUserLifetime) {
        return this.getPlusAddonModalContent();
      } else if (this.isUserLifetime) {
        return this.getLifetimeUserModalContent();
      } else {
        return this.getDefaultModalContent();
      }
    },
    getDefaultModalContent() {
      return {
        title: (
            <div class="text-violet-500 mb-2">Upgrade needed</div>
        ),
        content: (
            <div>If you want to <span class="text-violet-500">{this.needUpgradeModal?.actionName}</span>, upgrade your
              plan.</div>
        ),
        icon: () => (
            <a-icon type="star" style="color: var(--color-violet-500)"/>
        ),
        okText: 'Upgrade plan',
        onOk: () => {
          this.navigateToBilling();
        },
        onCancel: () => {
          this.onCloseModal();
        }
      };
    },
    getPlusAddonModalContent() {
      return {
        title: (
            <div class="text-violet-500 mb-2">Upgrade needed</div>
        ),
        content: (
            <div class="space-y-4">
              <div>
                If you want to <span class="text-violet-500">{this.needUpgradeModal?.actionName}</span>, buy
                the Plus add-on.
              </div>

              <a-alert type="info" class="text-sm" show-icon>
                <div slot="message" class="space-y-1">
                  <div>Plus add-on includes:</div>
                  <VPlusAddonFeatures class="space-y-1"/>
                </div>
              </a-alert>
            </div>
        ),
        icon: () => (
            <a-icon type="star" style="color: var(--color-violet-500)"/>
        ),
        okText: (
            <span>Buy Plus add-on</span>
        ),
        onOk: () => this.setupPlusAddonCheckout(),
        onCancel: () => {
          this.onCloseModal();
        }
      };
    },
    getLifetimeUserModalContent() {
      return {
        title: (
            <div class="text-violet-500 mb-2">Upgrade needed</div>
        ),
        content: (
            <div>If you want to <span class="text-violet-500">{this.needUpgradeModal?.actionName}</span>, add additional
              LTD codes.</div>
        ),
        icon: () => (
            <a-icon type="star" style="color: var(--color-violet-500)"/>
        ),
        okText: 'Add additional codes',
        onOk: () => {
          this.navigateToBilling();
        },
        onCancel: () => {
          this.onCloseModal();
        }
      };
    },
    navigateToBilling() {
      this.$router.push({name: "Billing"});
      this.$posthog.capture('Clicked on Upgrade button in Upgrade Modal', {featureName: this.needUpgradeModal?.actionName?.toLowerCase()});
      this.onCloseModal();
    },
    onCloseModal() {
      this.$store.commit('billing/setNeedUpgradeModal', {show: false, actionName: null, isPlusAddon: false});
    },
    closeOtherModals() {
      Modal.destroyAll();
      this.$store.commit("collaborators/setModal", {isVisible: false, surveyId: null, folderId: null});
    }
  }
}
</script>
