<template>
  <div>
    <div class="flex items-start pl-12 mb-4">
      <div class="flex-grow">
        <div class="flex">
          <span class="text-xl font-light">Question:</span>
          <VTextArea :max-length="questionMaxLength"
                     :count-typed-symbols="question.text.length"
                     :has-upgrade="true"
                     class="flex-grow ml-3">
            <a-textarea placeholder="Your question here"
                        :max-length="questionMaxLength"
                        :auto-size="{ minRows: 3 }"
                        v-model="question.text"
            />
          </VTextArea>
        </div>
        <div class="pl-5 mt-2.5">
          <div class="mb-1.5">
            <Draggable v-model="question.choices" @end="onDragSort" handle=".drag-multiple-answer-handle">
              <div
                  v-for="(choice, index) in question.choices"
                  :key="choice._id"
                  class="flex items-start mb-1.5"
              >
                <div class="font-light text-xl pt-2 mr-3 text-right" style="width: 85px;">
                  Choice {{ index + 1 | numToLetter }}:
                </div>
                <VTextArea
                    :max-length="questionMaxLength"
                    :count-typed-symbols="choice.text.length"
                    :has-upgrade="true"
                    class="flex-grow">
                  <a-input
                      size="large"
                      class="input-draggable"
                      :maxLength="questionMaxLength"
                      v-model="choice.text"
                      @change="updateChoice(index)"
                  >
                    <DragIcon
                        slot="suffix"
                        class="cursor-move drag-multiple-answer-handle"
                        style="font-size: 28px"
                    />
                  </a-input>
                </VTextArea>
                <VButton type="default-negative-hover"
                         size="large"
                         icon="delete"
                         style="height: 40px; width: 40px; font-size: 20px; margin-top: 2px;"
                         class="ml-3"
                         :disabled="question.choices.length < 3"
                         @click="deleteChoice(index)"
                />
              </div>
            </Draggable>
          </div>
          <div style="margin-left: 96px; margin-right: 52px;">
            <a-button
                type="default"
                block
                size="large"
                icon="plus"
                class="btn-add"
                :loading="isCreatingChoice"
                @click="createChoice"
            >
              Add choice
            </a-button>
          </div>
        </div>
      </div>
      <div class="pl-5 flex-none">
        <div class="flex justify-end">
          <div class="flex items-center">
            <span class="font-light text-xl">Min required</span>
            <a-tooltip class="ml-1 mr-2">
              <template slot="title">
                Minimum selected options required to pass this step.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
          <a-select
              v-model="question.options.requiredAnswers"
              @change="onChangeRequired()"
              style="width: 80px"
              size="large"
          >
            <a-select-option
                v-for="choiceNum in question.choices.length"
                :key="choiceNum"
            >
              {{ choiceNum }}
            </a-select-option>
          </a-select>
        </div>
        <div v-show="question.options.requiredAnswers <= 1" class="flex items-center justify-end mt-5">
          <span class="font-light text-xl">Single selection</span>
          <a-tooltip>
            <template slot="title">
              Get users to select only one option.
            </template>
            <a-icon type="info-circle" class="icon-info mr-3 ml-1"/>
          </a-tooltip>
          <a-switch
              class="switch_positive-color"
              checked-children="On"
              un-checked-children="Off"
              v-model="question.options.isSingleSelection"
          />
        </div>
      </div>
    </div>

    <a-divider style="margin-top: 32px; margin-bottom: 16px; border-color: var(--color-gray-300)"/>

  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import questionMixin from "@/mixins/question";
import {DragIcon} from "@/helpers/customIcons";
import Draggable from 'vuedraggable';
import {CHOICE_CREATE, CHOICE_DELETE, CHOICE_UPDATE, CHOICES_UPDATE_POSITION} from "@/store/actions.type";
import _ from "lodash";
import VButton from "../../../VButton";
import VTextArea from "../../../VTextArea";

export default {
  name: "QuestionMultiple",
  props: ["question"],
  components: {
    VTextArea,
    DragIcon,
    Draggable,
    VButton
  },
  mixins: [questionMixin],
  filters: {
    numToLetter: function (value) {
      let s = '', t;
      while (value > 0) {
        t = (value - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        value = (value - t) / 26 | 0;
      }
      return s || value;
    }
  },
  created() {
    this.updateChoice = _.debounce(this.updateChoice, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS);
  },
  mounted() {
    //reset required answers value
    if (this.question.options.requiredAnswers > this.question.choices.length) {
      this.question.options.requiredAnswers = this.question.choices.length;
    }
  },
  data() {
    return {
      isCreatingChoice: false,
    }
  },
  computed: {
    ...mapGetters('survey', ["survey"]),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 100;
    }
  },
  methods: {
    async createChoice() {
      const maxPosition = Math.max(...this.question.choices.map(c => c.position), -1);

      this.isCreatingChoice = true;

      try {
        await this.$store.dispatch(`survey/${CHOICE_CREATE}`, {
          survey_id: this.$route.params.id,
          question_id: this.question._id,
          position: maxPosition + 1,
        });
      } finally {
        this.isCreatingChoice = false;
      }
    },

    updateChoice(choiceIndex) {
      let {_id, text} = this.question.choices[choiceIndex];

      this.$store.dispatch(`survey/${CHOICE_UPDATE}`, {
        survey_id: this.$route.params.id,
        question_id: this.question._id,
        choice_id: _id,
        text
      });
    },

    deleteChoice(choiceIndex) {
      this.$store.dispatch(`survey/${CHOICE_DELETE}`, {
        survey_id: this.$route.params.id,
        question_id: this.question._id,
        choice_id: this.question.choices[choiceIndex]._id,
      }).then(() => {
        if (this.question.options.requiredAnswers > this.question.choices.length) {
          this.question.options.requiredAnswers = this.question.choices.length;
        }
      });
    },

    onDragSort() {
      let choices = this.question.choices.map(function (choice, index) {
        return {...choice, position: index}
      });
      this.$store.dispatch(`survey/${CHOICES_UPDATE_POSITION}`, {
        survey_id: this.$route.params.id,
        choices,
      });
    },

    onChangeRequired() {
      if (this.question.options.requiredAnswers > 1) {
        this.question.options.isSingleSelection = false;
      }
    }
  }
}
</script>
