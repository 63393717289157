import posthog from "posthog-js";

export default {
  install(Vue) {
    Vue.prototype.$posthog = posthog;

    if (process.env.NODE_ENV === "dev") return;

    posthog.init(process.env.VUE_APP_POSTHOG_API_KEY);
    // posthog.debug(true);
  }
};

