<template>
  <router-link :to="{name: 'Folder', params: {folderId: folder._id}}">
    <div
        :class="['folder', {'is-dragging-over': isDraggingOver}]"
        @dragover.prevent
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
        @drop="onDrop">
      <div class="folder-tab"></div>
      <div class="folder-body flex flex-col pt-0 pb-4 px-4">
        <div class="flex justify-end">
          <a-dropdown :trigger="['click']">
            <a-menu slot="overlay">
              <a-menu-item v-if="canEditFolder" key="edit">
                <router-link :to="{name: 'Folder', params: {folderId: folder._id}}">
                  <div class="text-base space-x-2">
                    <a-icon type="edit"/>
                    <span>Edit</span>
                  </div>
                </router-link>
              </a-menu-item>
              <a-menu-item v-if="canInviteToFolder" key="invite">
                <div
                    class="text-base space-x-2"
                    @click="onInviteUser(null, folder._id)">
                  <a-icon type="team"/>
                  <span>Collaborators</span>
                  <a-tag color="rgba(57, 174, 97, 0.15)"
                         class="text-xs text-positive">
                    NEW
                  </a-tag>
                </div>
              </a-menu-item>
              <a-menu-divider v-if="canEditFolder || canInviteToFolder"/>
              <a-menu-item v-if="canLeaveFolder" key="leave">
                <div
                    class="text-base space-x-2"
                    @click.prevent="onLeave(getCollaborator({folderId: folder._id})._id, null, folder._id)">
                  <a-icon type="logout"/>
                  <span>Leave</span>
                </div>
              </a-menu-item>
              <a-menu-item v-if="canEditFolder" key="delete">
                <div class="text-base text-negative space-x-2"
                     @click="onDeleteFolder"
                >
                  <a-icon type="delete"/>
                  <span>Delete</span>
                </div>
              </a-menu-item>
            </a-menu>
            <div
                class="cursor-pointer text-black leading-none text-opacity-40 hover:text-blue-500 transition duration-100">
              <a-icon type="ellipsis" style="font-size: 22px;"/>
            </div>
          </a-dropdown>
        </div>

        <div class="flex-grow flex flex-col justify-between">
          <div class="space-x-1.5 mb-1">
            <div class="text-character_gray-400 font-semibold text-lg leading-tight no-underline text-left break-words">
              {{ folder.name | folderName }}
            </div>
          </div>

          <div class="flex justify-between items-center">
            <div class="text-gray-500 text-base space-x-1">
              <a-icon type="folder-open"></a-icon>
              <span>{{ folder.surveysCount }}</span>
            </div>
            <div v-if="folder.collaborators && folder.collaborators.length > 1 && canInviteToFolder"
                 class="text-gray-500 text-base space-x-1 hover:text-blue-500 transition duration-100"
                 @click.prevent="onInviteUser(null, folder._id)">
              <a-icon type="team"></a-icon>
              <span>{{ folder.collaborators.length }}</span>
            </div>
            <a-tag v-else-if="!canInviteToFolder" class="text-sm m-0">
              {{ currentPermission }}
            </a-tag>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import {DELETE_FOLDER} from "../../store/actions.type";
import _ from "lodash";
import {mapGetters} from "vuex";
import {CollaboratorsMixin} from "../../mixins/collaborators.mixin";

export default {
  name: "FolderItem",
  props: {
    folder: {
      type: Object,
      required: true
    }
  },
  mixins: [CollaboratorsMixin],
  filters: {
    folderName(value) {
      if (!value) return 'Untitled folder'
      return _.truncate(value, {length: 60})
    }
  },
  data() {
    return {
      isDraggingOver: false
    }
  },
  computed: {
    ...mapGetters('auth', ["user"]),
    ...mapGetters('collaborators', ["getCollaborator", "canInvite", "canEdit", "getPermissionByValue"]),
    canEditFolder() {
      return this.canEdit({folderId: this.folder._id});
    },
    canInviteToFolder() {
      return this.canInvite({folderId: this.folder._id});
    },
    canLeaveFolder() {
      return !this.getCollaborator({folderId: this.folder._id}).isOwner;
    },
    currentPermission() {
      return this.getPermissionByValue(this.getCollaborator({folderId: this.folder._id})?.permission)?.label;
    }
  },
  methods: {
    onDeleteFolder() {
      this.$confirm({
        title: 'Are you sure?',
        content: `Deleting this folder will also remove surveys and any collected results.`,
        okText: 'Delete',
        cancelText: 'No, cancel',
        okButtonProps: {props: {type: 'danger', size: 'default'}},
        centered: true,
        maskClosable: true,
        class: 'modal-delete-survey',
        icon: () => (<a-icon type="warning" style="color: var(--color-negative)"/>),
        onOk: () => this.$store.dispatch(`home/${DELETE_FOLDER}`, {folderId: this.folder._id})
            .then(() => {
              if (this.$router.currentRoute.path !== '/') {
                this.$router.push('/');
              }
            }),
      });
    },
    onDragEnter() {
      this.isDraggingOver = true;
    },
    onDragLeave(event) {
      if (!this.$el.contains(event.relatedTarget)) {
        this.isDraggingOver = false;
      }
    },
    onDrop(event) {
      this.isDraggingOver = false;
      const surveyId = event.dataTransfer.getData('surveyId');
      this.$emit('drop-survey', {surveyId, folderId: this.folder._id});
    }
  }
}
</script>

<style lang="less" scoped>
@tab-height: 16px;

.folder {
  height: 100%;
  background: #FFF;
  position: relative;
  border-radius: 12px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  border: 1.5px solid #FFF8E9;
  overflow: hidden;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    border: 1.5px solid var(--color-blue-500);
  }

  &.is-dragging-over {
    border: 1.5px solid var(--color-blue-500);
  }

  &-tab {
    width: 60px;
    height: @tab-height;
    background: #FFF8E9;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 12px 0 0 0;
  }

  &-body {
    width: 100%;
    height: calc(100% - @tab-height);
    background: #FFF8E9;
    position: absolute;
    top: @tab-height;
    left: 0;
  }
}
</style>
