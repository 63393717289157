<template>
  <div class="inline" style="font-size: 22px;">
    <a-avatar v-if="avatarUrl" :src="avatarUrl" size="large"/>
    <a-avatar v-else class="avatar" size="large">{{ name | getFirstLetter }}</a-avatar>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  name: "VUserAvatar",
  props: {
    email: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  filters: {
    getFirstLetter(name) {
      return name.charAt(0).toUpperCase();
    },
  },
  created() {
    this.loadAvatar();
  },
  data() {
    return {
      avatarUrl: null,
    };
  },
  methods: {
    getEmailHash(email) {
      return md5(email.trim().toLowerCase());
    },
    loadAvatar() {
      const gravatarUrl = `https://www.gravatar.com/avatar/${this.getEmailHash(
          this.email
      )}?s=80&d=404`;

      // Check if the avatar exists
      fetch(gravatarUrl, {method: 'HEAD'})
          .then((response) => {
            if (response.ok) {
              this.avatarUrl = gravatarUrl;
            }
          })
          .catch(() => {
            this.avatarUrl = null;
          });
    },
  },
}
</script>

<style lang="less" scoped>
.avatar {
  color: @v-color-blue-500;
  background-color: @v-color-blue-50;
}
</style>
