<template>
  <div class="group" style="width: 180px;">
    <div class="flex justify-center border-1.5 overflow-hidden group-hover:border-solid group-hover:border-blue-500"
         :class="isActive ? 'border-solid border-blue-500' : 'border-dashed border-gray-400 survey-type-item--shadow'"
         style="height: 132px; border-radius: 12px;"
    >
      <VIcon :name="iconName"></VIcon>
    </div>
    <div class="text-lg font-bold leading-tight mb-2 mt-4"
         :class="isActive ? 'text-blue-500' : 'text-character_gray-500'"
    >
      {{ title }}
    </div>
    <div class="text-sm leading-tight text-character_gray-400">
      {{ description }}
    </div>
  </div>
</template>

<script>
import VIcon from "../components/VIcon";

export default {
  name: "VSurveyTypeLayout",
  props: {
    isActive: {type: Boolean, required: true},
    iconName: {type: String},
    title: {type: String},
    description: {type: String},
  },
  components: {VIcon},
}
</script>

<style lang="less" scoped>
.survey-type-item--shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}
</style>
