<template>
	<div class="flex justify-end items-center text-gray-500 leading-none">
		<a-icon :type="indicator.icon" style="font-size: 20px"></a-icon>
		<span class="text-2xl font-light ml-1">{{ indicator.value }}</span>
	</div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const {mapGetters} = createNamespacedHelpers('survey');

export default {
	name: "VSaveIndicator",
	computed: {
		...mapGetters(['isSaving']),
		indicator: function () {
			let value = 'Saving...',
				icon = 'loading';

			if (!this.isSaving) {
				value = 'Saved!';
				icon = 'check';
			}

			return {
				value,
				icon
			}
		}
	}
}
</script>

<style scoped>

</style>