import Vue from 'vue'
import App from './App.vue'
import router from "@/router"
import store from "@/store"
import VueClipboard from 'vue-clipboard2'
import Ant from 'ant-design-vue'
import {message} from "ant-design-vue"
import './assets/less/index.less'
import VueSocialSharing from 'vue-social-sharing'
import InfiniteLoading from 'vue-infinite-loading'
const VueMoment = require('vue-moment')
import C from "./plugins/constants"
import ImageKit from "imagekitio-vue"
import posthogPlugin from "./plugins/posthog";

message.config({
	maxCount: 1,
	duration: 5,
});

Vue.use(Ant);
Vue.use(VueClipboard);
Vue.use(VueMoment);
Vue.use(VueSocialSharing);
Vue.use(C);
Vue.use(ImageKit, {urlEndpoint: process.env.VUE_APP_IMAGEKIT_URL_ENDPOINT});
Vue.use(posthogPlugin);

Vue.use(InfiniteLoading, {
	props: {
		spinner: 'spiral',
	},
	slots: {
		noMore: '',
		noResults: '',
	},
});

Vue.config.productionTip = false;


new Vue({
	render: h => h(App),
	router,
	store
}).$mount('#app');
