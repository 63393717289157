<template>
  <div>
    <div class="w-full"
         style="height: 16px; background: linear-gradient(0deg, rgba(154, 165, 187, 0.2) 0%, rgba(154, 165, 187, 0) 55.21%);">
    </div>

    <a-collapse :bordered="false"
                expandIconPosition="right"
                class="space-y-6"
                style="background-color: transparent; margin-bottom: -12px;"
                @change="onCollapse"
    >
      <template #expandIcon="props">
        <a-icon type="down" :rotate="props.isActive ? 180 : 0"
                style="color: var(--color-gray-500); font-size: 24px;"/>
      </template>
      <a-collapse-panel key="1"
                        style="background: var(--color-base-theme); border: 0; box-sizing: border-box;">
        <template slot="header">
          <VBlockHeader :style="headerShadow">
            <template #icon>
              <a-icon type="zhihu"/>
            </template>
            <template #title>Translations</template>
          </VBlockHeader>
        </template>

        <a-divider :dashed="true"
                   style="margin-top: 1px; margin-bottom: 40px; border-color: var(--color-neutral-gray-300)"/>

        <div class="text-character_gray-400">
          <h4 class="font-light text-2xl leading-tight px-10 mb-10">Interface / Main</h4>

          <div class="divide-x-0 divide-y divide-dashed divide-neutral_gray-300">
            <VTranslationsItem icon="welcome-screen" name="Welcome screen">
              <VSwitchInput input-name="Swipe description"
                            input-info-html="This hint message will help your respondents <b>learn how to start</b> by swiping."
                            input-info-icon="tooltip-welcome-swipe-desc"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.welcomeScreen.swipeDesc.defaultValue"
                            :input-value.sync="survey.translations.welcomeScreen.swipeDesc.value"
                            :switch-value.sync="survey.translations.welcomeScreen.swipeDesc.active"
              />
              <VSwitchInput input-name="Button next"
                            input-info-html="You can change the <b>start button</b> label."
                            input-info-icon="tooltip-welcome-button"
                            :input-length="16"
                            input-width="168px"
                            :input-placeholder="survey.translations.welcomeScreen.buttonStart.defaultValue"
                            :input-value.sync="survey.translations.welcomeScreen.buttonStart.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-last-comment" name="Last step comment">
              <VSwitchInput input-name="Button submit"
                            input-info-html="You can change the <b>submit button</b> label."
                            input-info-icon="tooltip-comment-button-submit"
                            :input-length="16"
                            input-width="168px"
                            :input-placeholder="survey.translations.comment.buttonSubmit.defaultValue"
                            :input-value.sync="survey.translations.comment.buttonSubmit.value"
              />
              <VSwitchInput input-name="Button skip"
                            input-info-html="You can change the <b>skip button</b> label."
                            input-info-icon="tooltip-comment-button-skip"
                            :input-length="16"
                            input-width="168px"
                            :input-placeholder="survey.translations.comment.buttonSkip.defaultValue"
                            :input-value.sync="survey.translations.comment.buttonSkip.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="survey-hint" name="Survey hint">
              <VSwitchInput input-name="Hint button label"
                            input-info-html="<b>Hint button label</b>. You can switch it off or change the message."
                            input-info-icon="tooltip-survey-hint-tooltip"
                            :input-length="16"
                            input-width="168px"
                            :input-placeholder="survey.translations.surveyHint.tooltip.defaultValue"
                            :input-value.sync="survey.translations.surveyHint.tooltip.value"
                            :switch-value.sync="survey.translations.surveyHint.tooltip.active"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="survey-nav" name="Navigation buttons">
              <VSwitchInput input-name="Back button tooltip"
                            input-info-html="This tooltip appears when someone hovers over the <b>back button</b>."
                            input-info-icon="tooltip-survey-nav-back"
                            :input-length="64"
                            input-width="400px"
                            :input-placeholder="survey.translations.surveyNav.tooltipBack.defaultValue"
                            :input-value.sync="survey.translations.surveyNav.tooltipBack.value"
                            :switch-value.sync="survey.translations.surveyNav.tooltipBack.active"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="survey-password" name="Password page">
              <VSwitchInput input-name="Page title"
                            input-info-html="<b>Password page title</b>. You can switch it off or change the message."
                            input-info-icon="tooltip-survey-password-title"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.surveyPassword.title.defaultValue"
                            :input-value.sync="survey.translations.surveyPassword.title.value"
                            :switch-value.sync="survey.translations.surveyPassword.title.active"
              />
              <VSwitchInput input-name="Placeholder"
                            input-info-html="You can change the password <b>input placeholder</b> text."
                            input-info-icon="tooltip-survey-password-placeholder"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.surveyPassword.placeholder.defaultValue"
                            :input-value.sync="survey.translations.surveyPassword.placeholder.value"
                            :switch-value.sync="survey.translations.surveyPassword.placeholder.active"
              />
              <VSwitchInput input-name="Error message"
                            input-info-html="This <b>error message</b> appears when someone tries to enter the wrong password."
                            input-info-icon="tooltip-survey-password-error"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.surveyPassword.error.defaultValue"
                            :input-value.sync="survey.translations.surveyPassword.error.value"
              />
              <VSwitchInput input-name="Button submit"
                            input-info-html="You can change the <b>submit button</b> label."
                            input-info-icon="tooltip-survey-password-button"
                            :input-length="16"
                            input-width="168px"
                            :input-placeholder="survey.translations.surveyPassword.buttonSubmit.defaultValue"
                            :input-value.sync="survey.translations.surveyPassword.buttonSubmit.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="survey-messages" name="Messages">
              <VSwitchInput input-name="Survey taken - title"
                            input-info-html="This is the <b>error title</b> the participant will see when attempting to <b>retake</b> the completed survey."
                            input-info-icon="tooltip-survey-messages-taken-title"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.surveyMessages.titleSurveyTaken.defaultValue"
                            :input-value.sync="survey.translations.surveyMessages.titleSurveyTaken.value"
              />
              <VSwitchInput input-name="Survey taken - description"
                            input-info-html="This is the <b>error description</b> the participant will see when attempting to <b>retake</b> the completed survey."
                            input-info-icon="tooltip-survey-messages-taken-desc"
                            :input-length="64"
                            input-width="400px"
                            :input-placeholder="survey.translations.surveyMessages.descSurveyTaken.defaultValue"
                            :input-value.sync="survey.translations.surveyMessages.descSurveyTaken.value"
                            :switch-value.sync="survey.translations.surveyMessages.descSurveyTaken.active"
              />
              <VSwitchInput input-name="Survey paused - title"
                            input-info-html="This is the <b>error title</b> the participant will see when opening the <b>paused survey</b>."
                            input-info-icon="tooltip-survey-messages-paused-title"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.surveyMessages.titleSurveyPaused.defaultValue"
                            :input-value.sync="survey.translations.surveyMessages.titleSurveyPaused.value"
              />
              <VSwitchInput input-name="Survey paused - description"
                            input-info-html="This is the <b>error description</b> the participant will see when opening the <b>paused survey</b>."
                            input-info-icon="tooltip-survey-messages-paused-desc"
                            :input-length="64"
                            input-width="400px"
                            :input-placeholder="survey.translations.surveyMessages.descSurveyPaused.defaultValue"
                            :input-value.sync="survey.translations.surveyMessages.descSurveyPaused.value"
                            :switch-value.sync="survey.translations.surveyMessages.descSurveyPaused.active"
              />
            </VTranslationsItem>
          </div>
        </div>

        <a-divider :dashed="true"
                   style="margin-top: 2px; margin-bottom: 40px; border-color: var(--color-neutral-gray-300)"/>

        <div class="text-character_gray-400" style="padding-bottom: 2px;">
          <h4 class="font-light text-2xl leading-tight px-10 mb-10">Interface / Questions</h4>

          <div class="divide-x-0 divide-y divide-dashed divide-neutral_gray-300">
            <VTranslationsItem icon="question-swipe-image" name="Swipe reply">
              <VSwitchInput input-name="Swipe description"
                            input-info-html="This hint message will help your respondents <b>learn how to answer</b> by swiping."
                            input-info-icon="tooltip-question-swipe-desc"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.swipe.swipeDesc.defaultValue"
                            :input-value.sync="survey.translations.questions.swipe.swipeDesc.value"
                            :switch-value.sync="survey.translations.questions.swipe.swipeDesc.active"
              />

              <VSwitchInput input-name="Left button"
                            input-info-html="You can change the <b>left button</b> label."
                            input-info-icon="tooltip-question-swipe-left-button"
                            :input-length="10"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.swipe.leftButton.defaultValue"
                            :input-value.sync="survey.translations.questions.swipe.leftButton.value"
              />

              <VSwitchInput input-name="Right button"
                            input-info-html="You can change the <b>right button</b> label."
                            input-info-icon="tooltip-question-swipe-right-button"
                            :input-length="10"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.swipe.rightButton.defaultValue"
                            :input-value.sync="survey.translations.questions.swipe.rightButton.value"
              />

              <VSwitchInput input-name="Selected description"
                            input-info-html="The <b>selected prefix</b>. Shows how the question was answered (Example: Selected: Yes)."
                            input-info-icon="tooltip-question-swipe-selected"
                            :input-length="32"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.swipe.selectedDesc.defaultValue"
                            :input-value.sync="survey.translations.questions.swipe.selectedDesc.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-nps-horizontal" name="NPS">
              <VSwitchInput input-name="Press key description"
                            input-info-html="This hint message will help your respondents <b>learn how to answer</b> by pressing “X” keyboard key."
                            input-info-icon="tooltip-question-nps-desc"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.nps.keyDesc.defaultValue"
                            :input-value.sync="survey.translations.questions.nps.keyDesc.value"
                            :switch-value.sync="survey.translations.questions.nps.keyDesc.active"
              />

              <VSwitchInput input-name="Min label"
                            input-info-html="<b>Min value label</b>. Show the min level of recommendation."
                            input-info-icon="tooltip-question-nps-min"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.nps.minLabel.defaultValue"
                            :input-value.sync="survey.translations.questions.nps.minLabel.value"
              />

              <VSwitchInput input-name="Max label"
                            input-info-html="<b>Max value label</b>. Show the max level of recommendation."
                            input-info-icon="tooltip-question-nps-max"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.nps.maxLabel.defaultValue"
                            :input-value.sync="survey.translations.questions.nps.maxLabel.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-multiple" name="Multiple answer">
              <VSwitchInput input-name="Min required description"
                            input-info-html="<b>Min required</b> description. Displays the minimum selected choices required to answer the question."
                            input-info-icon="tooltip-question-multiple-required"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.multiple.requiredDesc.defaultValue"
                            :input-value.sync="survey.translations.questions.multiple.requiredDesc.value"
              />

              <VSwitchInput input-name="Button submit"
                            input-info-html="You can change the <b>submit button</b> label."
                            input-info-icon="tooltip-question-multiple-button"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.multiple.buttonSubmit.defaultValue"
                            :input-value.sync="survey.translations.questions.multiple.buttonSubmit.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-smiles-color" name="Likert, smiles">
              <VSwitchInput input-name="Min label"
                            input-info-html="<b>Min value label</b>. Show the min level of happiness."
                            input-info-icon="tooltip-question-smiles-min"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.likertSmiles.minLabel.defaultValue"
                            :input-value.sync="survey.translations.questions.likertSmiles.minLabel.value"
              />

              <VSwitchInput input-name="Max label"
                            input-info-html="<b>Max value label</b>. Show the max level of happiness."
                            input-info-icon="tooltip-question-smiles-max"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.likertSmiles.maxLabel.defaultValue"
                            :input-value.sync="survey.translations.questions.likertSmiles.maxLabel.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-stars" name="Likert, stars">
              <VSwitchInput input-name="Min label"
                            input-info-html="<b>Min value label</b>. Show the min level of satisfaction."
                            input-info-icon="tooltip-question-stars-min"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.likertStars.minLabel.defaultValue"
                            :input-value.sync="survey.translations.questions.likertStars.minLabel.value"
              />

              <VSwitchInput input-name="Max label"
                            input-info-html="<b>Max value label</b>. Show the max level of satisfaction."
                            input-info-icon="tooltip-question-stars-max"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.likertStars.maxLabel.defaultValue"
                            :input-value.sync="survey.translations.questions.likertStars.maxLabel.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-open-single-line" name="Open answer">
              <VSwitchInput input-name="Swipe description"
                            input-info-html="This hint message will help your respondents <b>learn how to answer</b> by swiping."
                            input-info-icon="tooltip-question-open-desc"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.open.swipeDesc.defaultValue"
                            :input-value.sync="survey.translations.questions.open.swipeDesc.value"
                            :switch-value.sync="survey.translations.questions.open.swipeDesc.active"
              />

              <VSwitchInput input-name="Error message"
                            input-info-html="<b>Single and Multi line validation error</b>. You can change the error message."
                            input-info-icon="tooltip-question-open-error"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.open.error.defaultValue"
                            :input-value.sync="survey.translations.questions.open.error.value"
              />

              <VSwitchInput input-name="Button submit"
                            input-info-html="You can change the <b>submit button</b> label."
                            input-info-icon="tooltip-question-open-button"
                            :input-length="20"
                            input-width="168px"
                            :input-placeholder="survey.translations.questions.open.buttonSubmit.defaultValue"
                            :input-value.sync="survey.translations.questions.open.buttonSubmit.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-open-number" name="Open answer, number">
              <VSwitchInput input-name="Error message"
                            input-info-html="<b>Number validation error</b>. You can change the error message."
                            input-info-icon="tooltip-question-open-number-error"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.openNumber.error.defaultValue"
                            :input-value.sync="survey.translations.questions.openNumber.error.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-open-phone" name="Open answer, phone">
              <VSwitchInput input-name="Error message"
                            input-info-html="<b>Phone validation error</b>. You can change the error message."
                            input-info-icon="tooltip-question-open-phone-error"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.openPhone.error.defaultValue"
                            :input-value.sync="survey.translations.questions.openPhone.error.value"
              />
            </VTranslationsItem>

            <VTranslationsItem icon="question-open-email" name="Open answer, email">
              <VSwitchInput input-name="Error message"
                            input-info-html="<b>Email validation error</b>. You can change the error message."
                            input-info-icon="tooltip-question-open-email-error"
                            :input-length="40"
                            input-width="288px"
                            :input-placeholder="survey.translations.questions.openEmail.error.defaultValue"
                            :input-value.sync="survey.translations.questions.openEmail.error.value"
              />
            </VTranslationsItem>
          </div>
        </div>

      </a-collapse-panel>
    </a-collapse>

  </div>
</template>

<script>
import VBlockHeader from "./VBlockHeader";
import VTranslationsItem from "../../../layouts/VTranslationsItem";
import VSwitchInput from "../../VSwitchInput";
import {mapGetters} from "vuex";

export default {
  name: "SurveyDesignTranslations",

  components: {VBlockHeader, VTranslationsItem, VSwitchInput},

  data() {
    return {
      collapsed: false,
    }
  },

  computed: {
    ...mapGetters('survey', ["survey"]),

    headerShadow() {
      return !this.collapsed ? 'background: linear-gradient(0deg, rgba(154, 165, 187, 0.16) 0%, rgba(154, 165, 187, 0) 47.4%);' : '';
    }
  },

  methods: {
    onCollapse(key) {
      this.collapsed = key.length > 0;
    }
  }
}
</script>

<style scoped>

</style>
