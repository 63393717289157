<template>
  <div class="px-10">
    <VEmbedCode type="fullpage">
      <template #description>
        <p>
          This is your full-page embed code. <b>Copy-paste</b> it in your site's HTML on the page you want your fullscreen
          survey to appear.
        </p>
      </template>
      <template #code>
        <code>
          &lt;!DOCTYPE html&gt;
          &lt;html lang="en"&gt;
          &lt;head&gt;
          &lt;meta charset="utf-8" /&gt;
          &lt;meta name="viewport" content="width=device-width, initial-scale=1" /&gt;
          &lt;title&gt;{{ survey.title || 'Untitled Survey – MetaSurvey' }}&lt;/title&gt;
          &lt;style&gt;*{margin:0;padding:0;} html,body,#metasurvey-widget{width:100%;height:100%;}&lt;/style&gt;
          &lt;/head&gt;
          &lt;body&gt;
          {{ embedDiv }}
          {{ embedScript }}
          &lt;/body&gt;
          &lt;/html&gt;
        </code>
      </template>
    </VEmbedCode>
  </div>
</template>

<script>
import VEmbedCode from "../../../../layouts/VEmbedCode";
import {mapGetters} from "vuex";
import {embedMixin} from "../../../../mixins/embed";

export default {
  name: "VSharingFullpage",
  components: {VEmbedCode},
  mixins: [embedMixin],
  computed: {
    ...mapGetters('survey', ["survey"]),
    embedDiv: function () {
      let embedDiv = document.createElement('div')
      embedDiv.id = "metasurvey-widget"
      embedDiv.setAttribute('data-ms-widget', this.survey._id)

      return embedDiv.outerHTML
    },
  },
}
</script>

<style scoped>

</style>
