<template>
  <div class="py-3">

    <div class="px-10">
      <h3 class="flex items-center mb-4 text-character_gray-400">
        <span class="font-light text-2xl">Survey type</span>
      </h3>

      <div class="pb-8">
        <a-radio-group v-model="currentType" size="large" class="clear-select flex items-start space-x-6">
          <a-radio-button v-for="surveyType in surveyTypes"
                          :key="surveyType.value"
                          :value="surveyType.value"
          >
            <VSurveyTypeLayout :is-active="surveyType.value === currentType"
                               :icon-name="surveyType.iconName"
                               :title="surveyType.title"
                               :description="surveyType.description"
            />
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>


    <div v-if="subtypes" class="survey-subtype-items pt-10 pb-8 px-10">
      <a-radio-group v-model="currentSubtype" size="large" class="clear-select flex items-start space-x-6">
        <a-radio-button v-for="subtype in subtypes"
                        :key="subtype.value"
                        :value="subtype.value"
                        class="relative"
        >
          <a-icon v-if="subtype.value === currentSubtype"
                  type="caret-down"
                  class="text-blue-500 absolute"
                  style="font-size: 28px; top: -30px; left: 50%; transform: translateX(-50%);"
          ></a-icon>
          <VSurveyTypeLayout :is-active="subtype.value === currentSubtype"
                             :icon-name="subtype.iconName"
                             :title="subtype.title"
                             :description="subtype.description"
          />
        </a-radio-button>
      </a-radio-group>
    </div>

    <a-divider v-if="!subtypes" :dashed="true" style="margin: 0 0 28px; border-color: var(--color-neutral-gray-300)"/>

    <component :is="activeView" :button-type="currentSubtype"></component>
  </div>
</template>

<script>
import VSurveyTypeLayout from "../../../layouts/VSurveyTypeLayout";
import _ from "lodash";
import VSharingLink from "./Sharings/VSharingLink";
import VSharingWidget from "./Sharings/VSharingWidget";
import VSharingFullpage from "./Sharings/VSharingFullpage";
import VSharingPopup from "./Sharings/VSharingPopup";

export default {
  name: "SurveyDesignShare",
  components: {VSurveyTypeLayout},
  data() {
    return {
      surveyTypes: [
        {
          value: "link",
          iconName: "survey-type-link",
          title: "Sharable link",
          description: "Send the survey link to users via email, social media, or share it with a QR code",
          view: VSharingLink,
        },
        {
          value: "widget",
          iconName: "survey-type-widget",
          title: "Frame embed",
          description: "Present your survey as a part of your website",
          view: VSharingWidget,
        },
        {
          value: "fullpage",
          iconName: "survey-type-fullpage",
          title: "Full-page embed",
          description: "Show users a full-page survey when your page loads",
          view: VSharingFullpage,
        },
        {
          value: "popup",
          iconName: "survey-type-popup",
          title: "Popup embed",
          description: "Your survey pops up in the center of the screen when users hit the button",
          view: VSharingPopup,
          subTypes: [
            {
              value: "side-button",
              iconName: "survey-subtype-side-button",
              title: "Side button",
              description: "Pin the button to one of seven different positions on your site (we recommend this)",
            },
            {
              value: "cta",
              iconName: "survey-subtype-cta",
              title: "CTA button",
              description: "Display a Call-To-Action button between your site blocks",
            },
            {
              value: "inline",
              iconName: "survey-subtype-inline",
              title: "Inline button",
              description: "Place the button link directly in the text",
            },
          ]
        },
      ],
      currentType: "link",
      currentSubtype: "side-button",
    }
  },
  computed: {
    subtypes: function () {
      return _.find(this.surveyTypes, ["value", this.currentType])?.subTypes
    },
    activeView: function () {
      return _.find(this.surveyTypes, ["value", this.currentType])?.view
    }
  },
}
</script>

<style lang="less" scoped>
.survey-subtype-items {
  background-color: fade(@v-color-blue-500, 6%);
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 16px;
  }

  &:before {
    top: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(154, 165, 187, 0.3) 0%, rgba(154, 165, 187, 0) 55.21%);;
  }

  &:after {
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(154, 165, 187, 0.3) 0%, rgba(154, 165, 187, 0) 55.21%);
    transform: rotate(-180deg);
  }
}
</style>
