<template>
  <div>
    <div class="px-10">
      <h3 class="flex items-center mb-3 text-character_gray-400">
        <span class="font-light text-2xl">Sharing options</span>
      </h3>
    </div>

    <div class="flex px-10">
      <div class="flex-none border-0 border-r border-solid border-gray-200 pr-4 xl:pr-6 pt-3 pb-4">
        <div class="mb-3 text-character_gray-400 font-light text-lg">Send this link to users</div>
        <div class="flex space-x-3">
          <a-tooltip :visible="showCopiedTooltip">
            <template slot="title">
              Copied!
            </template>
            <div class="flex bg-blue-100 text-blue-500 py-2 px-4 rounded-lg cursor-pointer">
            <span class="m-auto border-0 border-b border-dashed border-blue-500 leading-none truncate"
                  @click="doCopyLink"
            >
              {{ sharingLink }}
            </span>
            </div>
          </a-tooltip>
          <VButton size="large"
                   type="danger"
                   icon="link"
                   ref="btnCopy"
                   class="v_btn_copy"
                   style="width: 140px;"
                   @click="doCopy"
          >
            Copy link
          </VButton>
        </div>
        <div v-if="defaultSharingLink !== sharingLink"
             class="text-sm mt-3 space-x-1 text-neutral_gray-500 leading-none truncate">
          <span class="inline-flex">Alternative link:</span>
          <div class="inline-flex">
            <span class="border-0 border-b border-dashed border-gray-500">
              {{ defaultSharingLink }}
            </span>
          </div>
        </div>
      </div>


      <div class="border-0 border-r border-solid border-gray-200 px-4 xl:px-6 py-4">
        <div class="mb-3 text-character_gray-400 font-light text-lg">Share in social</div>
        <div class="flex space-x-3">
          <ShareNetwork href="javascript:void(0)"
                        network="facebook"
                        :url="sharingLink"
                        :title="sharingTitle"
                        :quote="sharingTitle"
          >
            <a-button size="large" class="social-button facebook"
                      @click="$posthog.capture('Sharing Survey in Social', {resource: 'Facebook'})">
              <a-icon type="facebook" theme="filled" style="font-size: 28px; line-height: 0;"></a-icon>
            </a-button>
          </ShareNetwork>
          <ShareNetwork href="javascript:void(0)"
                        network="twitter"
                        :url="sharingLink"
                        :title="sharingTitle"
          >
            <a-button size="large" class="social-button twitter"
                      @click="$posthog.capture('Sharing Survey in Social', {resource: 'Twitter'})">
              <a-icon type="twitter" style="font-size: 28px; line-height: 0;"></a-icon>
            </a-button>
          </ShareNetwork>
          <ShareNetwork href="javascript:void(0)"
                        network="linkedin"
                        :url="sharingLink"
                        :title="sharingTitle"
          >
            <a-button size="large" class="social-button linkedin"
                      @click="$posthog.capture('Sharing Survey in Social', {resource: 'LinkedIn'})">
              <a-icon type="linkedin" theme="filled" style="font-size: 28px; line-height: 0;"></a-icon>
            </a-button>
          </ShareNetwork>
          <ShareNetwork href="javascript:void(0)"
                        network="reddit"
                        :url="sharingLink"
                        :title="sharingTitle"
          >
            <a-button size="large" class="social-button reddit"
                      @click="$posthog.capture('Sharing Survey in Social', {resource: 'Reddit'})">
              <a-icon type="reddit-circle" theme="filled" style="font-size: 28px; line-height: 0;"/>
            </a-button>
          </ShareNetwork>
        </div>
      </div>
    </div>

    <a-divider :dashed="true" style="margin: 20px 0 32px; border-color: var(--color-neutral-gray-300)"/>

    <div class="flex px-10">

      <div class="flex-grow border-0 border-r border-solid border-gray-200 pr-4 xl:pr-6">
        <h3 class="font-light text-2xl text-character_gray-400 mb-7">Customize link</h3>
        <div>

          <div class="space-y-3">
            <VProElement
                :needUpgrade="!survey.features.custom_domain"
                name="use Custom Domain"
                class="flex items-center">
              <a-switch
                  class="switch_positive-color mr-3"
                  checked-children="On"
                  un-checked-children="Off"
                  v-model="survey.custom_domain.active"
                  :disabled="!survey.features.custom_domain"
              />
              <span class="font-light text-lg">Custom domain</span>

              <a-tooltip>
                <template slot="title">
                  Host this survey on your subdomain to make it white labeled.
                </template>
                <a-icon type="info-circle" class="icon-info ml-1"/>
              </a-tooltip>
              <VLabel v-if="!survey.features.custom_domain" type="feature"></VLabel>
            </VProElement>

            <p>
              <VLearnMore :link="$constants('LINK_HELP_CUSTOM_DOMAIN')" text="How to setup a custom domain"/>
            </p>

            <div v-if="survey.custom_domain.active && survey.features.custom_domain">
              <div class="flex space-x-3">
                <a-input placeholder="survey.mycompany.com"
                         size="large"
                         v-model="customDomain"
                         style="width: 480px"/>
                <VButton
                    slot="actions"
                    size="large"
                    icon="plus"
                    type="primary"
                    :loading="isDomainVerifying"
                    @click="addCustomDomain">
                  Add
                </VButton>
              </div>

              <div v-if="survey.addedCustomDomains && survey.addedCustomDomains.length">
                <div class="font-light text-base mb-2 mt-4">Select existing custom domain:</div>
                <a-radio-group
                    v-model="survey.custom_domain.verifiedDomain"
                    class="space-y-2">
                  <a-radio
                      v-for="customDomain in survey.addedCustomDomains"
                      :key="customDomain"
                      :value="customDomain"
                      style="display: block;">
                    <a-space :size="12">
                      <div class="text-base border border-solid border-neutral_gray-200 rounded"
                           style="width: 456px; padding: 6px 11px; line-height: 30px;">
                        {{ customDomain }}
                      </div>
                      <a-popconfirm
                          slot="actions"
                          title="Are you sure delete this custom domain? This action may affect other surveys."
                          ok-text="Delete"
                          cancel-text="No, cancel"
                          overlayClassName="danger-confirm"
                          @confirm="deleteCustomDomain(customDomain)"
                      >
                        <VButton
                            type="default-negative-hover"
                            icon="delete"
                            :loading="isDomainDeleting[customDomain]"
                            style="height: 40px; width: 40px;"/>
                      </a-popconfirm>
                    </a-space>
                  </a-radio>
                </a-radio-group>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="pl-4 xl:pl-6">
        <div class="mb-3 text-character_gray-400 font-light text-lg">Share with QR</div>
        <div class="flex space-x-8">
          <div style="min-width: 120px">
            <a-switch
                class="switch_positive-color"
                checked-children="Yes"
                un-checked-children="No"
                v-model="isActiveQr"
            />
            <div v-show="isActiveQr" class="mt-6">
              <div class="mb-3 text-character_gray-400 font-light text-lg">Options</div>
              <div class="flex items-center mb-4">
                <a-switch
                    class="switch_positive-color"
                    checked-children="On"
                    un-checked-children="Off"
                    v-model="isActiveLogo"
                />
                <span class="ml-3 mr-1 font-light text-base">Add logo</span>
                <a-tooltip>
                  <template slot="title">
                    Show the company logo in the center of the QR.
                  </template>
                  <a-icon type="info-circle" class="icon-info"/>
                </a-tooltip>
              </div>
              <VProElement
                  v-if="isActiveLogo"
                  :needUpgrade="!survey.features.qr_logo"
                  name="upload Your Logo"
                  class="flex items-center space-x-2"
              >
                <a-upload
                    name="qr-logo"
                    accept="image/*"
                    :show-upload-list="false"
                    :action="uploadEndPoint"
                    :before-upload="beforeUpload"
                    :disabled="!survey.features.qr_logo"
                    @change="onUpload"
                >
                  <VButton type="link"
                           size="large"
                           class="text-lg gray"
                           :class="{'opacity-40 cursor-not-allowed': !survey.features.qr_logo}"
                           :loading="isLogoLoading"
                  >
                    Upload my logo
                  </VButton>
                </a-upload>
                <VLabel v-if="!survey.features.qr_logo" type="feature"></VLabel>
              </VProElement>
            </div>
          </div>
          <div v-if="isActiveQr" class="flex flex-col justify-center">
            <div class="flex">
              <a-spin :spinning="isDisableDownloadQr">
                <div class="border-2 border-solid border-blue-500 rounded-lg overflow-hidden"
                     style="width: 168px; height: 168px;"
                >
                  <vue-qr :text="sharingLink"
                          :logoSrc="isActiveLogo ? qrLogoUrl : ''"
                          :logoScale="0.2"
                          :margin="15"
                          :width="168"
                          ref="qr"
                          :callback="onLoadQr"
                  ></vue-qr>
                </div>
              </a-spin>
            </div>
            <div class="flex justify-center mt-4">
              <VButton type="primary"
                       size="large"
                       icon="download"
                       style="width: 138px; height: 40px;"
                       :disabled="isDisableDownloadQr"
                       @click="onDownloadQr"
              >
                Download
              </VButton>
            </div>
          </div>
          <div v-else style="margin-top: -34px">
            <VIcon name="qr-ghost"></VIcon>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import VButton from "../../../VButton";
import surveyMixin from "../../../../mixins/survey";
import VueQr from 'vue-qr'
import VProElement from "../../../VProElement";
import VLabel from "../../../VLabel";
import {mapGetters} from "vuex";
import VIcon from "../../../VIcon";
import {DELETE_DOMAIN, VERIFY_DOMAIN} from "../../../../store/actions.type";
import VLearnMore from "../../../VLearnMore";

export default {
  name: "VSharingLink",
  components: {VButton, VueQr, VProElement, VLabel, VIcon, VLearnMore},
  mixins: [surveyMixin],
  watch: {
    isActiveLogo: function () {
      this.reloadQr()
    },
  },
  data() {
    return {
      sharingTitle: 'Take a quick survey please!',
      defaultSharingLink: `${process.env.VUE_APP_PUBLIC_URL}${this.$route.params.id}`,
      uploadEndPoint: `${process.env.VUE_APP_API_URL}/upload/`,
      showCopiedTooltip: false,
      isActiveQr: false,
      isActiveLogo: false,
      isLogoLoading: false,
      qrImgBase64: "",
      defaultDomain: process.env.VUE_APP_DEFAULT_DOMAIN,
      isDomainVerifying: false,
      isDomainDeleting: {},
      customDomain: "",
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
    qrLogoUrl: function () {
      return this.survey.sharing_options.qr_logo_src || 'https://ik.imagekit.io/metasurvey/MetaSurvey/logo_qr2_mHK3pCEd1.png'
    },
    isDisableDownloadQr: function () {
      return this.qrImgBase64 === ""
    },
    sharingLink: function () {
      return this.getSharableLink()
    }
  },
  methods: {
    doCopyLink() {
      this.$copyText(this.sharingLink).then(() => {
        this.showCopiedTooltip = true
        setTimeout(() => this.showCopiedTooltip = false, 1000)

        this.$posthog.capture('Copied Survey link');
      })
    },
    onDownloadQr() {
      let a = document.createElement('a')
      a.href = this.qrImgBase64
      a.download = "qr.png"
      a.click()

      this.$posthog.capture('Downloaded QR', {surveyId: this.survey._id});
    },
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.$message.error('You can only upload JPG and PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isLt2M && isJpgOrPng;
    },
    onUpload(info) {
      if (info.file.status === 'uploading') {
        this.isLogoLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.reloadQr()
        this.survey.sharing_options.qr_logo_src = `${process.env.VUE_APP_UPLOAD_URL}${info.file.response[0].fileName}`
        this.isLogoLoading = false
        this.$posthog.capture('Uploaded QR Logo', {surveyId: this.survey._id});
      }
      if (info.file.status === 'error') {
        this.isLogoLoading = false
        this.$message.error({content: 'Failed to upload QR logo. Please try again.', duration: 5})
      }
    },
    onLoadQr(uri) {
      this.qrImgBase64 = uri
    },
    reloadQr() {
      this.qrImgBase64 = ""
    },
    addCustomDomain() {
      this.isDomainVerifying = true;

      this.$store.dispatch(
          `survey/${VERIFY_DOMAIN}`,
          {
            survey_id: this.$route.params.id,
            domain: this.customDomain
          }
      )
          .then(() => {
            this.customDomain = "";

            this.$message.success(`Custom domain ${this.survey.custom_domain.verifiedDomain} verified and survey link updated!`, 8);

            this.$posthog.capture('Connected Custom Domain', {
              surveyId: this.survey._id,
              customDomain: this.survey.custom_domain.verifiedDomain
            });
          })
          .finally(() => {
            this.isDomainVerifying = false;
          })
    },
    deleteCustomDomain(domain) {
      this.$set(this.isDomainDeleting, domain, true);

      this.$store.dispatch(
          `survey/${DELETE_DOMAIN}`,
          {
            survey_id: this.$route.params.id,
            domain
          }
      )
          .then(() => {
            this.$message.success(`Your custom domain ${domain} has been removed!`, 8);
          })
          .finally(() => {
            this.$set(this.isDomainDeleting, domain, false);
          })
    }
  }
}
</script>

<style lang="less" scoped>
.color-mixin(@color) {
  color: @color;
  border-color: @color;

  &:hover, &:focus {
    .lighter(color, @color, 2);
    .lighter(border-color, @color, 2);
  }
}

.social-button {
  padding: 0;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: @v-btn-height-lg;
}

.facebook {
  .color-mixin(@v-color-facebook);
}

.twitter {
  .color-mixin(@v-color-twitter);
}

.linkedin {
  .color-mixin(@v-color-linkedin);
}

.reddit {
  .color-mixin(@v-color-reddit);
}

.gray-link span {
  color: 1px dashed var(--color-gray-500) !important;
  border-bottom: 1px dashed var(--color-gray-500) !important;
}
</style>
