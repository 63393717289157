<template>
    <a-icon :component="smile"></a-icon>
</template>

<script>
	import {
		SmileColorfulNeutral,
		SmileColorfulPositive,
		SmileColorfulSad,
		SmileColorfulSuspicious,
		SmileColorfulThinking,
		SmileGrayNeutral,
		SmileGrayPositive,
		SmileGraySad,
		SmileGraySuspicious,
		SmileGrayThinking
	} from "@/helpers/customIcons";

	export default {
		name: "VSmiles",
		props: ["value", "type"],
		data() {
			return {
				smiles: {
					smilesColorful: {
						1: SmileColorfulSad,
						2: SmileColorfulThinking,
						3: SmileColorfulNeutral,
						4: SmileColorfulSuspicious,
						5: SmileColorfulPositive,
					},
					smilesGray: {
						1: SmileGraySad,
						2: SmileGrayThinking,
						3: SmileGrayNeutral,
						4: SmileGraySuspicious,
						5: SmileGrayPositive,
					}
				}
			}
		},
		computed: {
			smile: function () {
				return this.smiles[this.type][this.value];
			}
		}
	}
</script>

<style scoped>

</style>