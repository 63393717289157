<template>
  <VLimitReached>
    <VSurveyBlock id="comments-table" class="mt-6">
      <template #title>User comments ({{ comments_count }})</template>
      <template #reload-btn>
        <a-button
            type="default"
            size="large"
            icon="reload"
            class="btn-action btn-action_ghost ml-5"
            :loading="isLoadingChild"
            @click="reloadComments"
        >
          Reload comments
        </a-button>
      </template>
      <template #content>
        <a-config-provider :render-empty="showEmptyData">
          <a-table
              :columns="columns"
              :data-source="comments"
              :loading="isLoadingChild"
              :pagination="pagination"
              rowKey="createdAt"
              class="w-full mt-2"
              @change="onChange"
          >
            <template slot="createdAt" slot-scope="createdAt">
              {{ createdAt | moment('DD.MM.YYYY') }}
            </template>
            <template #comment>
              {{ comment }}
            </template>
          </a-table>
        </a-config-provider>
      </template>
    </VSurveyBlock>
  </VLimitReached>
</template>

<script>
import {mapGetters} from "vuex";
import VSurveyBlock from "@/components/VSurveyBlock";
import {FETCH_COMMENTS} from "@/store/actions.type";
import {EmptyTableSvg} from "@/helpers/customIcons";
import VLimitReached from "../../VLimitReached";

export default {
  name: "SurveyAnalyticComments",
  components: {VSurveyBlock, VLimitReached},
  data() {
    return {
      columns: [
        {
          title: 'Date',
          dataIndex: 'createdAt',
          scopedSlots: {customRender: 'createdAt'},
          sorter: true,
          defaultSortOrder: 'descend',
          width: 200
        },
        {
          title: 'Comment',
          sorter: true,
          dataIndex: 'comment',
        },
      ],
      sorter: {},
      defaultPagination: {
        pageSize: 20,
        current: 1
      }
    }
  },
  computed: {
    ...mapGetters('survey', ["comments", "comments_count", "isLoadingChild"]),
    pagination: function () {
      return {
        total: this.comments_count,
        pageSize: 20,
        showSizeChanger: true,
        showTotal: (total) => `Total ${total} comments`,
        pageSizeOptions: ["20", "50", "100"],
      };
    }
  },
  methods: {
    onChange(pagination, filters, sorter) {
      let limit = pagination.pageSize,
          offset = (pagination.current - 1) * pagination.pageSize,
          sort = sorter.columnKey;

      //Set sort variable for accurate reloading
      this.sorter = sorter;

      if (sorter.order === 'descend') {
        sort = `-${sort}`;
      }

      this.$store.dispatch(`survey/${FETCH_COMMENTS}`, {
        survey_id: this.$route.params.id,
        sort,
        limit,
        offset
      })
    },
    showEmptyData() {
      return <a-empty class="pt-12 pb-20">
        <EmptyTableSvg slot="image"/>
        <span slot="description" class="text-neutral_gray-300 text-2xl">No data</span>
      </a-empty>
    },
    reloadComments() {
      this.onChange(this.defaultPagination, {}, this.sorter);
    }
  }
}
</script>

<style scoped>

</style>
