import { render, staticRenderFns } from "./VTranslationsItem.vue?vue&type=template&id=72b53160&scoped=true&"
import script from "./VTranslationsItem.vue?vue&type=script&lang=js&"
export * from "./VTranslationsItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b53160",
  null
  
)

export default component.exports