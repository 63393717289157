import {mapGetters} from "vuex";
import {CANCEL_SUBSCRIPTION, PAUSE_SUBSCRIPTION, RENEW_SUBSCRIPTION, SWITCH_SUBSCRIPTION} from "../store/actions.type";

export const subscriptionMixin = {
  computed: {
    ...mapGetters('auth', ["user", "responsesLimit", "isUserLifetimeWithAddon"]),
  },

  methods: {
    getLimits() {
      let color = 'var(--color-blue-500)';
      const {passedCount, availableCount, dateEnd} = this.responsesLimit;
      const resetsAt = dateEnd;

      if (availableCount >= 999999) {
        color = 'var(--color-violet-500)';

        return {
          label: `<span style="color: ${color}">Unlimited</span>`,
          percent: 100,
          isLimited: false,
          color,
          availableCount,
          resetsAt
        }
      }

      if (availableCount && passedCount >= availableCount * 0.9) {
        color = 'var(--color-negative)';
      }

      return {
        label: `<span style="color: ${color}">${passedCount}</span> / ${availableCount}`,
        percent: Number((passedCount / availableCount * 100).toFixed(0)),
        isLimited: true,
        color,
        passedCount,
        availableCount,
        resetsAt
      };
    },

    getPlanIcon() {
      const plan = this.getPlanSlug();

      if (plan === this.$constants('PLAN_FREE')) {
        return 'free-plan'
      }

      if (plan === this.$constants('PLAN_PRO_PLUS') || plan === this.$constants('PLAN_PRO_ANNUAL')) {
        return 'top-droid'
      }

      return 'basic-droid'
    },

    getPlanName() {
      const plan = this.getPlanSlug();

      if (plan === this.$constants('PLAN_PRO_PLUS')) {
        return 'MetaSurvey PRO+'
      }

      return 'MetaSurvey PRO'
    },

    getStatus() {
      const {passedCount, availableCount} = this.responsesLimit;
      let status = {
        type: 'success',
        text: 'Active',
        icon: 'caret-right'
      }

      if (this.user.subscription?.status === this.$constants('SUBSCRIPTION_TRIALING')) {
        status.type = 'info'
        status.text = 'Trial'
        status.icon = 'sketch'
        return status
      }

      if (this.user.subscription?.status === this.$constants('SUBSCRIPTION_PAUSED')) {
        status.type = null
        status.text = 'Paused'
        status.icon = 'pause'
        return status
      }

      if (this.user.subscription?.status === this.$constants('SUBSCRIPTION_PAST_DUE')) {
        status.type = 'warning'
        status.text = 'Past due'
        status.icon = 'clock-circle'
        return status
      }

      if (passedCount >= availableCount) {
        status.type = 'error'
        status.text = 'Limit reached'
        status.icon = 'dashboard'
        return status
      }

      return status;
    },

    getPlanSlug() {
      return this.user.oldPlan || this.user.current_plan;
    },

    getRibbon(plan) {
      if (plan.product_id === this.currentPlan.planId) {
        if (this.user?.subscription?.status === this.$constants('SUBSCRIPTION_PAST_DUE')) {
          return {color: '#7F8CA7', text: 'Your plan past due', style: {borderStyle: 'dashed'}}
        }

        if (this.user?.subscription?.status === this.$constants('SUBSCRIPTION_PAUSED')) {
          return {color: '#7F8CA7', text: 'Your plan paused', style: {borderStyle: 'dashed'}}
        }

        return {color: '#0095E9', text: 'Your plan'}
      }

      if (plan.subscription.interval === 'year') {
        return {color: '#FF8743', text: '2 months free', style: {borderColor: '#FF8743'}}
      }

      return {}
    },

    onPause() {
      this.$posthog.capture('Clicked on Pause Sub button');

      this.$confirm({
        title: 'Pause your subscription?',
        content: 'Pausing your subscription will block access to PRO features and collecting extra responses, but keep it without being billed.',
        okText: 'Pause',
        cancelText: 'No, stay',
        okButtonProps: {props: {type: 'primary', size: 'default'}},
        centered: true,
        maskClosable: true,
        icon: () => (<a-icon type="question-circle-o" style="color: rgb(250, 173, 20);"/>),
        onOk: () => {
          this.makePause()
        },
      })
    },

    onRenew(coupon = '') {
      this.$confirm({
        title: 'Resume your subscription?',
        content: 'You will continue to have access to PRO features and collecting extra responses.',
        okText: 'Resume now',
        cancelText: 'Close',
        okButtonProps: {props: {type: 'primary', size: 'default'}},
        centered: true,
        maskClosable: true,
        icon: () => (<a-icon type="question-circle-o" style="color: var(--color-positive)"/>),
        onOk: () => {
          this.makeRenew(coupon)
        },
      })
    },

    onCancel() {
      this.$posthog.capture('Clicked on Cancel Sub button');

      this.$confirm({
        title: 'Cancel your subscription?',
        content: this.isUserLifetimeWithAddon ? 'Cancelling your subscription will result in loss access to Plus add-on features.' : 'Cancelling your subscription will result in loss access to PRO features and delete extra responses.',
        okText: 'Confirm',
        cancelText: 'No, stay',
        okButtonProps: {props: {type: 'danger', size: 'default'}},
        centered: true,
        maskClosable: true,
        icon: () => (<a-icon type="warning" style="color: var(--color-negative)"/>),
        onOk: () => {
          this.makeCancel()
        },
      })
    },

    onSwitch(planId) {
      this.$confirm({
        title: 'Switch your plan?',
        content: 'Your remaining credits will automatically be applied to the new subscription, reducing the amount of future payments! You will be charged immediately.',
        okText: 'Switch',
        cancelText: 'No, stay',
        okButtonProps: {props: {type: 'primary', size: 'default'}},
        centered: true,
        maskClosable: true,
        icon: () => (<a-icon type="question-circle-o" style="color: var(--color-positive)"/>),
        onOk: () => {
          this.makeSwitch(planId)
        },
      })
    },

    makeRenew(coupon) {
      const key = 'subscription-renewed';

      this.$store.dispatch(`billing/${RENEW_SUBSCRIPTION}`, {coupon})
        .then(() => {
          this.$notification.open({
            key,
            duration: 5,
            message: <div class="text-positive text-base mb-2">Subscription Resumed - Welcome Back!</div>,
            description: 'Congratulations! Your subscription has been successfully resumed.',
            icon: <a-icon type="sync" class="text-positive"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Got it!',
              );
            }
          });

          this.$posthog.capture('Subscription Resumed', {withCoupon: !!coupon});

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          this.$notification.open({
            key,
            duration: null,
            message: <div class="text-warning-500 text-base mb-2">Subscription Renewal Error - Let's Fix It!</div>,
            description: 'We\'re sorry, but there was an error while trying to resume your subscription. Please contact us as soon as possible so that we can help resolve the issue.',
            icon: <a-icon type="minus-circle" class="text-warning-500"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Close',
              );
            }
          });
        });
    },

    makePause(days) {
      const key = 'subscription-paused';

      let payload = {};
      if (days) {
        payload.days = Number(days)
      }

      this.$store.dispatch(`billing/${PAUSE_SUBSCRIPTION}`, payload)
        .then(() => {
          this.$notification.open({
            key,
            duration: 5,
            message: <div class="text-blue-500 text-base mb-2">Subscription Paused - Take a Break!</div>,
            description: 'Your subscription has been successfully paused.',
            icon: <a-icon type="pause" class="text-blue-500"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Close',
              );
            }
          });

          this.$posthog.capture('Subscription Paused', {pausePeriod: days});

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          this.$notification.open({
            key,
            duration: null,
            message: <div class="text-warning-500 text-base mb-2">Subscription Pause Error - We Can Help!</div>,
            description: 'We\'re sorry, but there was an error while trying to pause your subscription. Please contact us as soon as possible so that we can help resolve the issue.',
            icon: <a-icon type="minus-circle" class="text-warning-500"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Close',
              );
            }
          });
        });
    },

    makeCancel() {
      const key = 'subscription-cancelled';

      this.$store.dispatch(`billing/${CANCEL_SUBSCRIPTION}`)
        .then(() => {
          this.$notification.open({
            key,
            duration: 5,
            message: <div class="text-blue-500 text-base mb-2">We're sorry to see you go :(</div>,
            description: 'Your subscription has been successfully cancelled. Thank you for choosing our service!',
            icon: <a-icon type="meh" class="text-blue-500"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Bye!',
              );
            }
          });

          this.$posthog.capture('Subscription Cancelled');

          this.$router.push({name: 'ChurnSurvey'});
        })
        .catch(() => {
          this.$notification.open({
            key,
            duration: null,
            message: <div class="text-negative text-base mb-2">Subscription Cancellation Error - We're Sorry!</div>,
            description: 'We\'re sorry, but there was an error while trying to cancel your subscription. Please contact us as soon as possible so that we can help resolve the issue.',
            icon: <a-icon type="stop" class="text-negative"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Close',
              );
            }
          });
        });
    },

    makeSwitch(planId) {
      const key = 'subscription-switched';

      this.$store.dispatch(`billing/${SWITCH_SUBSCRIPTION}`, {planId})
        .then(() => {
          this.$notification.open({
            key,
            duration: 5,
            message: <div class="text-positive text-base mb-2">Subscription Plan Changed - Yay!</div>,
            description: 'Congratulations! Your subscription plan has been successfully changed.',
            icon: <a-icon type="check" class="text-positive"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'primary',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Got it!',
              );
            }
          });

          this.$posthog.capture('Subscription Switched', {planId});

          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          this.$notification.open({
            key,
            duration: null,
            message: <div class="text-warning-500 text-base mb-2">Uh-oh, Subscription Plan Change Error</div>,
            description: 'We\'re sorry, but there was an error while trying to change your subscription plan. Please contact us as soon as possible so that we can help resolve the issue.',
            icon: <a-icon type="minus-circle" class="text-warning-500"/>,
            style: {width: '467px', marginLeft: `${384 - 467}px`},
            btn: h => {
              return h(
                'a-button',
                {
                  props: {
                    type: 'default',
                    size: 'small',
                  },
                  on: {
                    click: () => {
                      this.$notification.close(key);
                    }
                  },
                },
                'Close',
              );
            }
          });
        })
    }

  }
}
