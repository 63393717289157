<template>
  <div>
    <div class="w-full"
         style="height: 16px; background: linear-gradient(0deg, rgba(154, 165, 187, 0.2) 0%, rgba(154, 165, 187, 0) 55.21%);">
    </div>

    <a-collapse :bordered="false"
                expandIconPosition="right"
                class="space-y-6"
                style="background-color: transparent; margin-bottom: -12px;"
                @change="onCollapse"
    >
      <template #expandIcon="props">
        <a-icon type="down" :rotate="props.isActive ? 180 : 0"
                style="color: var(--color-gray-500); font-size: 24px;"/>
      </template>
      <a-collapse-panel key="1"
                        style="background: var(--color-base-theme); border: 0; box-sizing: border-box;">
        <template slot="header">
          <VBlockHeader>
            <template #icon>
              <a-icon type="bg-colors"/>
            </template>
            <template #title>
              <div class="flex items-center space-x-3">
                <div class="space-x-1">
                  <span>Theme:</span>
                  <span class="font-normal">{{ currentThemeName }}</span>
                </div>
              </div>
            </template>
          </VBlockHeader>
        </template>

        <a-divider :dashed="true"
                   style="margin-top: 1px; margin-bottom: 40px; border-color: var(--color-neutral-gray-300)"/>

        <div class="w-7/12 text-character_gray-400 pb-12">
          <div class="px-10 space-y-8 mb-8">
            <h3 class="font-light text-2xl leading-none">Theme library</h3>
            <p class="text-base">
              Choose a default theme or create a custom one (change background, button, and text colors) to match your
              own branding. You can reuse custom themes in
              other surveys!
            </p>
          </div>

          <a-spin :spinning="isFetching" class="relative px-10">
            <div v-if="themes && themes.length > 0" class="space-y-6">
              <div class="flex items-stretch space-x-6">
                <a-radio-group v-model="currentTheme"
                               size="large"
                               class="clear-select flex flex-col items-start space-y-6"
                               @change="updateSurveyTheme(currentTheme)"
                >
                  <div v-for="theme in themes"
                       :key="theme._id">
                    <VProElement :needUpgrade="!survey.features.custom_theme && !theme.isDefault"
                                 name="unlock Your Custom Theme"
                                 class="relative flex space-x-2">
                      <div class="flex space-x-5">
                        <a-radio :value="theme._id" :disabled="!survey.features.custom_theme && !theme.isDefault"
                                 style="margin-top: 47px;"/>
                        <VSurveyThemeItem :is-active="theme._id === currentTheme"
                                          :theme="theme"
                                          :is-disabled="!survey.features.custom_theme && !theme.isDefault"
                                          @select="onSelect"
                                          @delete="onDelete"
                        />
                      </div>
                      <VLabel v-if="!survey.features.custom_theme && !theme.isDefault" type="feature"
                              class="absolute top-0 right-0 my-auto"></VLabel>
                    </VProElement>
                  </div>
                </a-radio-group>
              </div>

              <div class="flex">
                <a-button
                    type="default"
                    block
                    size="large"
                    icon="plus"
                    class="btn-add"
                    style="width: 330px; margin-left: 44px;"
                    :loading="isCreating"
                    @click="onCreate"
                >
                  New theme
                </a-button>
              </div>
            </div>
          </a-spin>

        </div>

      </a-collapse-panel>
    </a-collapse>

  </div>
</template>

<script>
import VBlockHeader from "./VBlockHeader";
import {mapGetters} from "vuex";
import VSurveyThemeItem from "../Design/VSurveyThemeItem";
import {CREATE_THEME, FETCH_THEMES} from "../../../store/actions.type";
import surveyMixin from "../../../mixins/survey";
import VProElement from "../../VProElement";
import VLabel from "../../VLabel";
import _ from "lodash";

export default {
  name: "SurveyDesignThemes",

  components: {VBlockHeader, VSurveyThemeItem, VProElement, VLabel},

  mixins: [surveyMixin],

  async mounted() {
    this.isFetching = true
    await this.$store.dispatch(`themes/${FETCH_THEMES}`, {surveyId: this.$route.params.id})
    this.isFetching = false

    if (this.survey.themeId) {
      this.currentTheme = this.survey.themeId;
    } else {
      this.currentTheme = this.themes[0]._id;
    }
  },

  data() {
    return {
      currentTheme: null,
      collapsed: false,
      isFetching: false,
      isCreating: false,
    }
  },

  computed: {
    ...mapGetters('survey', ["survey"]),
    ...mapGetters('themes', ["themes"]),
    currentThemeName: function () {
      return _.find(this.themes, {'_id': this.currentTheme})?.name;
    }
  },

  methods: {
    onCollapse(key) {
      this.collapsed = key.length > 0;
    },
    async onCreate() {
      this.isCreating = true
      this.$store.dispatch(`themes/${CREATE_THEME}`, {surveyId: this.survey._id})
          .then(() => {
            this.$posthog.capture('Theme Created', {
              surveyId: this.survey._id
            });
          })
          .finally(() => {
            this.isCreating = false
          })
    },
    onSelect(themeId) {
      this.currentTheme = themeId
      this.updateSurveyTheme(this.currentTheme)
      this.$posthog.capture('Theme Selected', {
        surveyId: this.survey._id,
        themeId: this.currentTheme._id,
        themeName: this.currentTheme.name
      });
    },
    onDelete(themeId) {
      if (this.currentTheme === themeId) {
        this.currentTheme = this.themes[0]._id
        this.updateSurveyTheme(this.currentTheme)
      }
    },
    updateSurveyTheme(themeId) {
      this.survey.themeId = themeId
      this.editSurvey(this.survey)
    }
  }
}
</script>

<style scoped>

</style>
