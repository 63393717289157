<template>
  <div>
    <slot></slot>
    <div class="h-7">
      <div v-if="maxLengthVal" class="flex justify-end items-center space-x-3 text-sm text-gray-400">

        <div v-if="hasUpgrade">
          <div v-show="countTypedSymbols >= maxLengthVal">
            <VProElement :needUpgrade="true" name="remove Character Limit" :is-plus-addon="true">
              <VLabel type="feature"
                      text="Remove character limit"
                      class="mt-1 hover:opacity-90 transition duration-100"/>
            </VProElement>
          </div>
        </div>

        <div :class="{'text-magenta-500': countTypedSymbols >= maxLengthVal && hasUpgrade}">
          {{ countTypedSymbols }} / {{ maxLengthVal }}
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import VLabel from "./VLabel";
import VProElement from "./VProElement";

export default {
  name: "VTextArea",
  props: ["maxLength", "countTypedSymbols", "hasUpgrade"],
  components: {VLabel, VProElement},
  data() {
    return {
      countTypedSymbolsVal: this.countTypedSymbols,
      maxLengthVal: this.maxLength,
    }
  },
}
</script>

<style scoped>

</style>
