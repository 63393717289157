<template>
  <div class="w-full">

    <h2 class="text-3xl text-dark_gray-500 mt-7 mb-5">
      Your <span class="font-bold">current plan</span>
    </h2>

    <a-spin :spinning="isSubscriptionLoading" class="bg-white rounded-xl px-6 pt-7 pb-6 shadow-custom">
      <div class="space-y-7">
        <div class="flex justify-between space-x-3">
          <div class="flex space-x-2.5">

            <div class="flex-none leading-none" style="font-size: 60px;">
              <VIcon :name="data.icon"/>
            </div>

            <div class="flex space-x-2">
              <div style="max-width: 305px;">
                <div class="flex items-center space-x-2">
                  <span class="font-bold leading-tight text-xl" v-text="data.name"></span>
                  <VSubscriptionStatus :status="data.status.type"
                                       :text="data.status.text"
                                       :icon="data.status.icon"/>
                </div>

                <div class="text-base text-character_gray-300 leading-tight"
                     style="max-width: 230px;"
                     v-text="data.details"></div>
              </div>
            </div>
          </div>
          <div class="flex-none space-y-2.5">

            <div class="flex items-center space-x-2.5">
              <span class="text-base leading-tight">Responses</span>
              <div style="min-width: 320px;">
                <a-progress :percent="data.responsesLimit.percent"
                            :width="224"
                            :strokeWidth="12"
                            :strokeColor="data.responsesLimit.color"
                >
                  <template #format>
                    <div class="inline text-base leading-tight" v-html="data.responsesLimit.label"></div>
                  </template>
                </a-progress>
              </div>
            </div>
            <div v-if="data.responsesLimit.isLimited" class="text-base leading-tight text-gray-500 text-center">
              <span v-if="data.responsesLimit.passedCount > data.responsesLimit.availableCount">
                {{ data.responsesLimit.passedCount - data.responsesLimit.availableCount }} new responses (hidden)
              </span>
              <span v-else>
                Resets on {{ data.responsesLimit.resetsAt | moment('ll') }}
              </span>
            </div>
          </div>

          <div class="flex flex-none space-x-10">
            <slot name="plan-buttons"></slot>
          </div>
        </div>

        <div class="flex justify-between items-end">
          <div class="text-base leading-tight space-x-5" style="padding-left: 70px;">

            <slot name="plan-extra"></slot>

            <span v-if="responsesLimit.availableCount > responsesLimit.passedCount"
                  class="text-blue-500">
              <a-icon type="check-circle"/>
              Responses collecting
            </span>
            <span v-else
                  style="color: rgba(127, 140, 167, 1)">
              <a-icon type="eye-invisible"/>
              New responses locked
            </span>
          </div>

          <div>
            <slot name="plan-billing-link"></slot>
          </div>
        </div>
      </div>

      <a-divider style="margin: 16px 0;"/>

      <slot name="addon"></slot>

      <div class="flex justify-between">

        <slot name="plans"></slot>

        <div class="flex flex-col justify-between">
          <slot name="promocode"></slot>

          <div class="flex flex-grow justify-end items-end">
            <slot name="plan-update-button"></slot>
          </div>
        </div>

      </div>

    </a-spin>
  </div>
</template>

<script>
import VSubscriptionStatus from "../components/VSubscriptionStatus";
import VIcon from "../components/VIcon";
import {mapGetters} from "vuex";

export default {
  name: "VSubscriptionLayout",

  components: {VSubscriptionStatus, VIcon},

  props: {
    data: Object,
  },

  computed: {
    ...mapGetters('auth', ["responsesLimit"]),
    ...mapGetters('billing', ["isSubscriptionLoading"]),
  }
}
</script>

<style lang="less" scoped>

</style>
