import Vue from "vue";
import Vuex from "vuex";

import auth from "./auth.module";
import home from "./home.module";
import survey from "./survey.module";
import themes from "./themes.module";
import billing from "./billing.module";
import file from "./file.module";
import collaborators from "./collaborators.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    home,
    survey,
    themes,
    billing,
    file,
    collaborators,
  }
});
