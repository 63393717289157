<template>
  <div :style="style.block">
    <div class="flex items-center m-auto max-w-screen-xl py-3 space-x-4">
      <div v-if="data.icon" class="flex-none">
        <a-icon :type="data.icon" style="font-size: 24px;" :style="style.icon"></a-icon>
      </div>
      <div class="flex-grow">
        <div v-if="data.title" class="text-base" v-text="data.title"></div>
        <div class="text-sm space-x-1">
          <span v-text="data.message"></span>
          <a v-if="data.cta_link" :href="data.cta_link" v-text="data.cta_label" class="underline hover:no-underline"
             target="_blank"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VNotification",
  props: ["data"],
  computed: {
    style() {
      switch (this.data.type) {
        case 'danger':
          return {
            block: {
              background: 'rgba(244, 53, 76, 0.15)',
              border: '1px solid rgba(244, 53, 76, 0.3)',
            },
            icon: {color: 'rgba(244, 53, 76, 0.3)'}
          }

        case 'info':
          return {
            block: {
              background: 'rgba(0, 108, 169, 0.15)',
              border: '1px solid rgba(0, 108, 169, 0.3)',
            },
            icon: {color: 'rgba(0, 108, 169, 0.3)'}
          }
      }

      return {
        block: {
          background: 'linear-gradient(0deg, rgba(250, 173, 20, 0.15), rgba(250, 173, 20, 0.15)), #FFFFFF',
          border: '1px solid rgba(250, 173, 20, 0.3)',
        },
        icon: {color: 'rgba(250, 173, 20, 1)'}
      }
    }
  },
}
</script>
