<template>
  <div class="group border border-solid rounded transition duration-100"
       style="max-width: 330px;"
       :class="[isActive ? 'border-blue-500' : 'border-gray-300', isEditing || isActive ? 'cursor-auto' : isDisabled ? 'cursor-not-allowed' : 'cursor-pointer hover:border-blue-500']"
       @click="onSelect"
  >
    <div class="flex space-x-3 p-3">
      <VSurveyThemeMiniPreview :settings="theme.settings" class="flex-none" :class="{'opacity-70': isDisabled}"/>
      <div class="flex flex-col">
        <div class="text-lg font-semibold leading-tight my-auto"
             :class="isActive ? 'text-blue-500' : isDisabled ? 'text-character_gray-200' : 'text-character_gray-500'"
        >
          <a-input v-if="isEditing" size="large" v-model="theme.name"/>
          <span v-else>{{ theme.name | truncateName }}</span>
        </div>
        <div class="flex space-x-3">
          <a-tooltip>
            <template v-if="theme.isDefault" slot="title">
              Duplicate the default theme to make edits
            </template>
            <VButton type="default"
                     size="large"
                     style="height: 40px; width: 40px; font-size: 20px;"
                     :disabled="theme.isDefault || isDisabled"
                     @click="onEdit"
            >
              <div class="flex">
                <a-icon :type="isEditing ? 'up' : 'edit'"/>
              </div>
            </VButton>
          </a-tooltip>

          <VProElement :needUpgrade="!survey.features.custom_theme"
                       name="create Custom Theme">
            <VButton type="default"
                     size="large"
                     style="height: 40px; width: 40px; font-size: 20px;"
                     :loading="isDuplicating"
                     :disabled="isDisabled"
                     @click="onDuplicate"
            >
              <div class="flex">
                <a-icon type="copy"/>
              </div>
            </VButton>
          </VProElement>

          <a-tooltip>
            <template v-if="theme.isDefault" slot="title">
              Unable to delete the default theme
            </template>
            <a-popconfirm
                :title="isActive ? 'Are you sure delete this theme? Your active theme will be set to the default theme. This action may affect other surveys.' : 'Are you sure delete this theme? This action may affect other surveys.'"
                ok-text="Delete"
                cancel-text="No, cancel"
                @confirm="onDelete"
                overlayClassName="danger-confirm"
            >
              <VButton type="default-negative-hover"
                       size="large"
                       style="height: 40px; width: 40px; font-size: 20px;"
                       :loading="isDeleting"
                       :disabled="theme.isDefault"
              >
                <div class="flex">
                  <a-icon type="delete"/>
                </div>
              </VButton>
            </a-popconfirm>
          </a-tooltip>
        </div>
      </div>
    </div>
    <transition name="expand">
      <div v-if="isEditing" class="theme-settings-collapse expand-content text-character_gray-500">
        <a-collapse :bordered="false" default-active-key="1" expandIconPosition="right">

          <template #expandIcon="props">
            <a-icon type="down"
                    :rotate="props.isActive ? 180 : 0"
                    style="color: var(--color-text-description); font-size: 16px;"/>
          </template>

          <a-collapse-panel key="1">
            <template slot="header">
              <div class="text-character_gray-400 text-xl leading-none p-3">
                Background
              </div>
            </template>
            <div class="px-3 pt-3 pb-7 space-y-6">
              <div class="space-y-3">
                <div class="font-light text-base">Background color:</div>
                <VInputColor v-model="theme.settings.bgColor"/>
              </div>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="2">
            <template slot="header">
              <div class="text-character_gray-400 text-xl leading-none p-3">
                Text
              </div>
            </template>
            <div class="px-3 pt-3 pb-7 space-y-6">
              <div class="space-y-3">
                <div class="flex items-center font-light text-base">
                  Title 1 (inside card)
                  <a-tooltip class="ml-1 mr-2">
                    <template slot="title">
                      Applied to titles of questions and screens inside card (Open answer, Last Step Comment, Welcome,
                      Thank You, Password, and Error screens).
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <VInputColor v-model="theme.settings.titleColor"/>
              </div>
              <div class="space-y-3">
                <div class="flex items-center font-light text-base">
                  Title 2 (outside card)
                  <a-tooltip class="ml-1 mr-2">
                    <template slot="title">
                      Applied to titles of questions outside of cards (NPS, Rating, and Multiple).
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <VInputColor v-model="theme.settings.titleColor2"/>
              </div>
              <div class="space-y-3">
                <div class="flex items-center font-light text-base">
                  Text
                  <a-tooltip class="ml-1 mr-2">
                    <template slot="title">
                      Applied to question text (Swipe questions only), answer text, and screen descriptions.
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <VInputColor v-model="theme.settings.textColor"/>
              </div>
              <div class="space-y-3">
                <div class="flex items-center font-light text-base">
                  Help text
                  <a-tooltip class="ml-1 mr-2">
                    <template slot="title">
                      Applied to question help text.
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <VInputColor v-model="theme.settings.helpTextColor"/>
              </div>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="3">
            <template slot="header">
              <div class="text-character_gray-400 text-xl leading-none p-3">
                Buttons
              </div>
            </template>
            <div class="pt-3 pb-7">
              <div class="px-3">
                <div class="flex items-center font-semibold text-lg mb-3">
                  Primary button
                  <a-tooltip :destroyTooltipOnHide="true"
                             placement="right"
                             overlayClassName="tooltip-image"
                             class="ml-1">
                    <template slot="title">
                      <div class="h-full flex flex-col justify-between">
                        <div class="pt-4 pb-0">
                          <VIcon name="tooltip-question-open-button"></VIcon>
                        </div>
                        <div
                            class="bg-white text-left pt-3 pl-4 pr-5 pb-5 text-base leading-tight text-character_gray-400">
                          Primary button used on all screens and within questions.
                        </div>
                      </div>
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <div class="space-y-6">
                  <div class="space-y-3">
                    <div class="font-light text-base">Button background color:</div>
                    <VInputColor v-model="theme.settings.buttonBgColor"/>
                  </div>
                  <div class="space-y-3">
                    <div class="font-light text-base">Button text color:</div>
                    <VInputColor v-model="theme.settings.buttonTextColor"/>
                  </div>
                </div>
              </div>

              <a-divider :dashed="true"
                         style="margin-top: 24px; margin-bottom: 14px; border-color: var(--color-neutral-gray-300)"/>

              <div class="px-3">
                <div class="flex items-center font-semibold text-lg mb-3">
                  "Hint" button
                  <a-tooltip :destroyTooltipOnHide="true"
                             placement="right"
                             overlayClassName="tooltip-image"
                             class="ml-1">
                    <template slot="title">
                      <div class="h-full flex flex-col justify-between">
                        <div class="pt-4 pb-0">
                          <VIcon name="tooltip-survey-hint-tooltip"></VIcon>
                        </div>
                        <div
                            class="bg-white text-left pt-3 pl-4 pr-5 pb-5 text-base leading-tight text-character_gray-400">
                          The survey hint button at the bottom right corner.
                        </div>
                      </div>
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <div class="space-y-6">
                  <div class="space-y-3">
                    <div class="font-light text-base">Button background color:</div>
                    <VInputColor v-model="theme.settings.buttonHintBgColor"/>
                  </div>
                  <div class="space-y-3">
                    <div class="font-light text-base">Button text color:</div>
                    <VInputColor v-model="theme.settings.buttonHintTextColor"/>
                  </div>
                </div>
              </div>

              <a-divider :dashed="true"
                         style="margin-top: 24px; margin-bottom: 14px; border-color: var(--color-neutral-gray-300)"/>

              <div class="px-3">
                <div class="flex items-center font-semibold text-lg mb-3">
                  Swipe button LEFT
                  <a-tooltip :destroyTooltipOnHide="true"
                             placement="right"
                             overlayClassName="tooltip-image"
                             class="ml-1">
                    <template slot="title">
                      <div class="h-full flex flex-col justify-between">
                        <div class="pt-4 pb-0">
                          <VIcon name="tooltip-question-swipe-left-button"></VIcon>
                        </div>
                        <div
                            class="bg-white text-left pt-3 pl-4 pr-5 pb-5 text-base leading-tight text-character_gray-400">
                          Left button on a Swipe question.
                        </div>
                      </div>
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <div class="space-y-6">
                  <div class="space-y-3">
                    <div class="font-light text-base">Button background color:</div>
                    <VInputColor v-model="theme.settings.swipeButtonLeftBgColor"/>
                  </div>
                  <div class="space-y-3">
                    <div class="font-light text-base">Button text color:</div>
                    <VInputColor v-model="theme.settings.swipeButtonLeftTextColor"/>
                  </div>
                </div>
              </div>

              <a-divider :dashed="true"
                         style="margin-top: 24px; margin-bottom: 14px; border-color: var(--color-neutral-gray-300)"/>

              <div class="px-3">
                <div class="flex items-center font-semibold text-lg mb-3">
                  Swipe button RIGHT
                  <a-tooltip :destroyTooltipOnHide="true"
                             placement="right"
                             overlayClassName="tooltip-image"
                             class="ml-1">
                    <template slot="title">
                      <div class="h-full flex flex-col justify-between">
                        <div class="pt-4 pb-0">
                          <VIcon name="tooltip-question-swipe-right-button"></VIcon>
                        </div>
                        <div
                            class="bg-white text-left pt-3 pl-4 pr-5 pb-5 text-base leading-tight text-character_gray-400">
                          Right button on a Swipe question.
                        </div>
                      </div>
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <div class="space-y-6">
                  <div class="space-y-3">
                    <div class="font-light text-base">Button background color:</div>
                    <VInputColor v-model="theme.settings.swipeButtonRightBgColor"/>
                  </div>
                  <div class="space-y-3">
                    <div class="font-light text-base">Button text color:</div>
                    <VInputColor v-model="theme.settings.swipeButtonRightTextColor"/>
                  </div>
                </div>
              </div>
            </div>
          </a-collapse-panel>

          <a-collapse-panel key="4">
            <template slot="header">
              <div class="text-character_gray-400 text-xl leading-none p-3">
                Elements
              </div>
            </template>
            <div class="px-3 pt-3 pb-7 space-y-6">
              <div class="space-y-3">
                <div class="flex items-center font-light text-base">
                  Accent color
                  <a-tooltip class="ml-1 mr-2">
                    <template slot="title">
                      Applied to answers when selected, for navigation buttons when hovered, and indicating a progress
                      bar.
                    </template>
                    <a-icon type="info-circle" class="icon-info"/>
                  </a-tooltip>
                </div>
                <VInputColor v-model="theme.settings.accentColor"/>
              </div>
            </div>
          </a-collapse-panel>

        </a-collapse>
      </div>
    </transition>
  </div>
</template>

<script>
import VButton from "../../VButton";
import VSurveyThemeMiniPreview from "./VSurveyThemeMiniPreview";
import VInputColor from "../../VInputColor";
import VIcon from "../../VIcon";
import {DELETE_THEME, DUPLICATE_THEME, UPDATE_THEME} from "../../../store/actions.type";
import VProElement from "../../VProElement";
import _ from "lodash";
import {mapGetters} from "vuex";

export default {
  name: "VSurveyThemeItem",
  props: {
    isActive: {type: Boolean, required: true},
    isDisabled: {type: Boolean},
    theme: {type: Object},
  },
  components: {VButton, VSurveyThemeMiniPreview, VInputColor, VIcon, VProElement},
  filters: {
    truncateName(value) {
      return _.truncate(value, {length: 32})
    }
  },
  watch: {
    theme: {
      handler(newOptions, oldOptions) {
        if (!_.isEmpty(oldOptions)) {
          this.editTheme(newOptions);
        }
      },
      deep: true
    }
  },
  data() {
    return {
      isEditing: false,
      isDeleting: false,
      isDuplicating: false,
    }
  },
  computed: {
    ...mapGetters('survey', ["survey"]),
  },
  methods: {
    onEdit() {
      this.isEditing = !this.isEditing
    },
    async onDuplicate() {
      this.isDuplicating = true
      this.$store.dispatch(`themes/${DUPLICATE_THEME}`, {themeId: this.theme._id, surveyId: this.survey._id})
          .finally(() => {
            this.isDuplicating = false
          })
    },
    async onDelete() {
      const themeId = this.theme._id

      this.$emit("delete", themeId) // it has to be here, because it won't work when item deleted from state

      this.isDeleting = true
      this.$store.dispatch(`themes/${DELETE_THEME}`, {themeId, surveyId: this.survey._id})
          .finally(() => {
            this.isDeleting = false
          })
    },
    onSelect(e) {
      if (this.isEditing || this.isDisabled) return;

      const targetEl = e.target.tagName.toLowerCase();

      if (targetEl === "button" || targetEl === "svg" || targetEl === "path") return;

      this.$emit("select", this.theme._id)
    },
    editTheme: _.debounce(function (payload) {
      this.$store.dispatch(`themes/${UPDATE_THEME}`, {...payload, surveyId: this.survey._id})
    }, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS),
  }
}
</script>

<style lang="less" scoped>
.expand-content {
  overflow: hidden;
  transition: max-height .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1);
}

.expand-enter-active,
.expand-leave-active {
  transition: max-height .15s cubic-bezier(.645, .045, .355, 1), opacity .15s cubic-bezier(.645, .045, .355, 1);
}

.expand-enter,
.expand-leave-to {
  max-height: 0;
  opacity: 0;
}

.expand-enter-to,
.expand-leave {
  max-height: 500px;
}
</style>

