<template>
  <component :is="iconSvg" />
</template>

<script>
const icons = {}

const requireComponents = require.context('@/assets/icons/', false, /.svg$/)

requireComponents.keys().forEach(fileName => {
  const iconName = fileName.replace(/^\.\/icon-(.+)\.svg$/, '$1')
  const componentConfig = requireComponents(fileName)
  icons[iconName] = componentConfig.default || componentConfig
})

export default {
  name: 'VIcon',

  props: {
    name: {
      type: String,
      required: true
    }
  },

  computed: {
    iconSvg() {
      return icons[this.name]
    }
  }
}
</script>
