<template>
  <router-link :to="{name: 'Survey', params: {id: survey._id}}">
    <div
        class="survey-item h-full flex flex-col p-4 bg-white rounded-xl transition duration-100"
        draggable="true"
        @dragstart="onDragStart">
      <div class="flex justify-end">
        <a-dropdown :trigger="['click']">
          <a-menu slot="overlay">
            <a-menu-item v-if="canEditSurvey" key="visibility" style="padding: 0">
              <div
                  class="text-base space-x-2"
                  :class="survey.visibility ? 'text-positive' : 'text-orange-500'"
                  style="padding: 5px 12px;"
                  @click.stop="onSurveyActive(survey)">
                <a-icon v-if="survey.visibility" type="play-square" theme="filled"/>
                <a-icon v-else type="pause"/>
                <span>{{ survey.visibility ? 'Active' : 'Paused' }}</span>
              </div>
            </a-menu-item>
            <a-menu-item v-if="canEditSurvey" key="edit">
              <router-link :to="'/survey/'+survey._id">
                <div class="text-base space-x-2">
                  <a-icon type="edit"/>
                  <span>Edit</span>
                </div>
              </router-link>
            </a-menu-item>
            <a-menu-item key="analytics">
              <router-link :to="`/survey/${survey._id}?tab=analytic`">
                <div class="text-base space-x-2">
                  <a-icon type="bar-chart"/>
                  <span>Analytics</span>
                </div>
              </router-link>
            </a-menu-item>
            <a-menu-divider v-if="canEditSurvey"/>
            <a-menu-item v-if="canEditSurvey" key="preview">
              <div class="text-base space-x-2"
                   @click="previewSurvey(survey._id)"
              >
                <a-icon type="eye"/>
                <span>Preview</span>
              </div>
            </a-menu-item>
            <a-menu-item v-if="canEditSurvey" key="copy">
              <a-tooltip :visible="showCopiedTooltip">
                <template slot="title">
                  Copied!
                </template>
                <div class="text-base space-x-2"
                     v-clipboard:copy="getSharableLink(survey._id)"
                     v-clipboard:success="onCopySurveyLink"
                >
                  <a-icon type="link"/>
                  <span>Copy link</span>
                </div>
              </a-tooltip>
            </a-menu-item>
            <a-menu-divider v-if="canEditSurvey"/>
            <a-menu-item v-if="canEditSurvey" key="clone">
              <div class="text-base space-x-2"
                   @click="onDuplicateSurvey"
              >
                <a-icon type="copy"/>
                <span>Duplicate</span>
              </div>
            </a-menu-item>
            <a-menu-item v-if="canInviteToSurvey" key="invite">
              <div class="text-base space-x-2"
                   @click="onInviteUser(survey._id)"
              >
                <a-icon type="team"/>
                <span>Collaborators</span>
                <a-tag color="rgba(57, 174, 97, 0.15)"
                       class="text-xs text-positive">
                  NEW
                </a-tag>
              </div>
            </a-menu-item>
            <a-sub-menu
                v-if="getCollaborator({surveyId: survey._id}).isOwner"
                key="move"
                :disabled="foldersToMove.length === 0">
              <template #title>
                <div class="inline text-base space-x-2">
                  <a-icon type="folder"/>
                  <span>Move to</span>
                </div>
              </template>
              <a-menu-item v-for="folder in foldersToMove" :key="folder._id">
                <div class="text-base space-x-2"
                     @click="onMoveSurvey(folder._id)"
                >
                  <a-icon :type="folder.icon || 'folder'"/>
                  <span>{{ folder.name }}</span>
                </div>
              </a-menu-item>
            </a-sub-menu>
            <a-menu-divider v-if="canEditSurvey || canLeaveSurvey"/>
            <a-menu-item v-if="canLeaveSurvey" key="leave">
              <div
                  class="text-base space-x-2"
                  @click.prevent="onLeave(getCollaborator({surveyId: survey._id})._id, survey._id, null)">
                <a-icon type="logout"/>
                <span>Leave</span>
              </div>
            </a-menu-item>
            <a-menu-item v-if="canEditSurvey" key="delete">
              <div class="text-base text-negative space-x-2"
                   @click.prevent="deleteSurvey(survey._id)"
              >
                <a-icon type="delete"/>
                <span>Delete</span>
              </div>
            </a-menu-item>
          </a-menu>
          <div
              class="cursor-pointer text-black leading-none text-opacity-40 hover:text-blue-500 transition duration-100">
            <a-icon type="ellipsis" style="font-size: 22px;"/>
          </div>
        </a-dropdown>
      </div>

      <div class="flex-grow flex flex-col justify-between">
        <div class="space-x-1.5 mb-1">
          <div class="text-character_gray-400 font-semibold text-lg leading-tight no-underline text-left break-words">
            {{ survey.title | surveyTitle }}
          </div>
        </div>

        <div class="flex justify-between items-center">
          <div class="flex">
            <router-link :to="`/survey/${survey._id}?tab=analytic`"
                         class="text-gray-500 text-base space-x-1">
              <a-icon type="bar-chart"></a-icon>
              <span>{{ survey.completedSurveysCount }}</span>
              <a-badge v-if="survey.hasNewData" status="success" class="leading-none"/>
            </router-link>
          </div>
          <div
              v-if="survey.collaborators && survey.collaborators.length > 1 && canInviteToSurvey"
              class="text-gray-500 text-base space-x-1 hover:text-blue-500 transition duration-100"
              @click.prevent="onInviteUser(survey._id)">
            <a-icon type="team"></a-icon>
            <span>{{ survey.collaborators.length }}</span>
          </div>
          <a-tag v-else-if="!canInviteToSurvey && currentPermission" class="text-sm m-0">
            {{ currentPermission }}
          </a-tag>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import _ from "lodash";
import surveyMixin from "../../mixins/survey";
import {mapGetters} from "vuex";
import {CollaboratorsMixin} from "../../mixins/collaborators.mixin";

export default {
  name: "SurveyItem",
  mixins: [surveyMixin, CollaboratorsMixin],
  props: {
    survey: {
      type: Object,
      required: true,
    }
  },
  filters: {
    surveyTitle(value) {
      if (!value) return 'Untitled survey'
      return _.truncate(value, {length: 60})
    }
  },
  data() {
    return {
      showCopiedTooltip: false,
    }
  },
  computed: {
    ...mapGetters('auth', ["user"]),
    ...mapGetters('home', ["folders"]),
    ...mapGetters('collaborators', ["getCollaborator", "canInvite", "canEdit", "permissions", "getPermissionByValue"]),
    foldersToMove() {
      return this.folders
          .filter(folder => folder.userId === this.user._id)
          .map(folder => ({
            ...folder,
            name: _.truncate(folder.name || 'Untitled folder', {length: 20})
          }))
          .concat(this.survey.folderId ? [{name: 'No folder', _id: null, icon: 'export'}] : []);
    },
    canEditSurvey() {
      return this.canEdit({surveyId: this.survey._id, folderId: this.$route.params.folderId});
    },
    canInviteToSurvey() {
      return this.canInvite({surveyId: this.survey._id, folderId: this.$route.params.folderId});
    },
    canLeaveSurvey() {
      const collaborator = this.getCollaborator({surveyId: this.survey._id});
      return Object.keys(collaborator).length > 0 && !collaborator.isOwner;
    },
    currentPermission() {
      return this.getPermissionByValue(this.getCollaborator({surveyId: this.survey._id})?.permission)?.label;
    }
  },
  methods: {
    onCopySurveyLink() {
      this.showCopiedTooltip = true;
      setTimeout(() => {
        this.showCopiedTooltip = false;
      }, 300);
    },
    onSurveyActive(survey) {
      survey.visibility = !survey.visibility
      this.editSurvey(survey)

      if (survey.visibility) {
        this.$posthog.capture('Survey Activated', {surveyId: survey._id});
      } else {
        this.$posthog.capture('Survey Paused', {surveyId: survey._id});
      }
    },
    onMoveSurvey(folderId) {
      this.$emit('move-survey', {survey: this.survey, folderId});
    },
    onDuplicateSurvey() {
      this.$emit('duplicate-survey', {survey: this.survey})
    },
    onDragStart(event) {
      event.dataTransfer.setData('surveyId', this.survey._id);
    }
  }
}
</script>

<style lang="less" scoped>
.survey-item {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  background: var(--color-base-theme);
  border: 1.5px solid var(--color-base-theme);

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    background: linear-gradient(0deg, rgba(0, 204, 255, 0.05), rgba(0, 204, 255, 0.05)), #FFFFFF;
    border: 1.5px solid var(--color-blue-500);
  }
}
</style>
