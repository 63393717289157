import Vue from "vue";
import JwtService from "@/common/jwt.service";
import ApiService from "@/common/api.service";
import router from "../router/index.js"
import {
  LOGIN,
  LOGOUT,
  REGISTER,
  CHECK_AUTH,
  RECOVER_PASSWORD,
  RESET_PASSWORD,
  CONFIRM_EMAIL,
  GET_AUTH_GOOGLE_URL,
  SET_AUTH_GOOGLE,
  UPDATE_USER_SETTINGS,
} from "./actions.type";
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_USER,
  AUTH_LOADING_START,
  AUTH_LOADING_END,
  SET_PLUGINS,
  SET_USER_SETTINGS,
} from "./mutations.type";

const state = {
  user: {},
  responsesLimit: {},
  alert: {},
  features: {},
  oldFeatures: {},
  isLoading: false,
  isSetPlugins: false,
}

const getters = {
  user(state) {
    return state.user;
  },
  isUserLifetimeWithAddon(state) {
    return state.user.current_plan.includes("_addon");
  },
  isUserLifetime(state) {
    const {current_plan, subscription} = state.user;
    return current_plan !== "free" && !subscription || getters.isUserLifetimeWithAddon(state);
  },
  productLiftSsoToken(state) {
    return state.user.productLiftSsoToken;
  },
  features(state) {
    return state.features;
  },
  oldFeatures(state) {
    return state.oldFeatures;
  },
  isLoading(state) {
    return state.isLoading;
  },
  responsesLimit(state) {
    return state.responsesLimit;
  },
  alert(state) {
    return state.alert;
  },
}

const actions = {
  [LOGIN](context, credentials) {
    context.commit(AUTH_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/auth/login`, credentials)
        .then(response => {
          context.commit(SET_AUTH, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit(AUTH_LOADING_END);
        })
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
    router.push({name: "Login"});

    if (Vue.prototype.$posthog) {
      Vue.prototype.$posthog.capture('Logged Out');
      try {
        Vue.prototype.$posthog.reset();
      } catch {
        return;
      }
    }
  },
  [CHECK_AUTH](context) {
    if (JwtService.getToken()) {
      return new Promise((resolve, reject) => {
        ApiService.get(`${process.env.VUE_APP_API_URL}/admin/me`)
          .then(response => {
            if (response.type === 'INVALID_TOKEN') context.commit(PURGE_AUTH);
            context.commit(SET_USER, response);
            context.commit(SET_PLUGINS, response);
            resolve(response);
          })
          .catch(error => {
            context.commit(PURGE_AUTH);
            reject(error);
          });
      })
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [REGISTER](context, credentials) {
    context.commit(AUTH_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/auth/register`, credentials)
        .then(response => {
          context.commit(SET_AUTH, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(AUTH_LOADING_END);
        })
    });
  },
  [RECOVER_PASSWORD](context, payload) {
    context.commit(AUTH_LOADING_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/auth/password-recover`, payload)
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(AUTH_LOADING_END);
        })
    });
  },
  [RESET_PASSWORD](context, payload) {
    context.commit(AUTH_LOADING_START);
    const {token, password} = payload;
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/auth/password-reset/${token}`, {password})
        .then(response => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(AUTH_LOADING_END);
        })
    });
  },
  [CONFIRM_EMAIL](context, payload) {
    context.commit(AUTH_LOADING_START);
    const {code} = payload;
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/auth/regconfirm/${code}`)
        .then(response => {
          if (Vue.prototype.$posthog) {
            Vue.prototype.$posthog.capture('Confirmed Email');
          }

          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          context.commit(AUTH_LOADING_END);
        })
    });
  },
  [GET_AUTH_GOOGLE_URL]() {
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/auth/google-auth-url`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [SET_AUTH_GOOGLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/auth/google`, payload)
        .then(response => {
          context.commit(SET_AUTH, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [UPDATE_USER_SETTINGS](context, {notifications}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/user/update`, {notifications})
        .then(response => {
          context.commit(SET_USER_SETTINGS, response);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        })
    });
  },
}

const mutations = {
  [AUTH_LOADING_START](state) {
    state.isLoading = true;
  },

  [AUTH_LOADING_END](state) {
    state.isLoading = false;
  },

  [SET_AUTH](state, payload) {
    JwtService.setToken(payload.jwtToken);
  },

  [PURGE_AUTH](state) {
    state.user = {};
    state.responsesLimit = {};
    state.alert = {};
    state.features = {};
    state.oldFeatures = {};
    state.isSetPlugins = false;
    JwtService.destroyToken();
  },

  [SET_USER](state, payload) {
    const {user, responsesLimits, alert, features, oldFeatures} = payload;

    state.user = user;
    state.alert = alert;
    state.features = features;
    state.oldFeatures = oldFeatures;
    state.responsesLimit = responsesLimits;

    state.isLoading = false;
  },

  [SET_USER_SETTINGS](state, payload) {
    state.user.notifications = payload?.notifications;
  },

  [SET_PLUGINS](state, payload) {
    if (state.isSetPlugins) return;

    const {_id, name, email} = payload?.user;

    if (window.Chatra) {
      window.Chatra('setIntegrationData', {
        name,
        email,
        notes: 'Customer'
      });
    }

    if (window.FS) {
      window.FS.identify(_id, {
        displayName: name,
        email: email,
      });
    }

    if (Vue.prototype.$posthog) {
      Vue.prototype.$posthog.identify(_id);
    }

    state.isSetPlugins = true;
  },

}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
