<template>
  <div class="extra-collapse mt-5">
    <a-collapse :bordered="false" expandIconPosition="right">

      <template #expandIcon="props">
        <div class="relative">
          <a-icon type="down"
                  :rotate="props.isActive ? 180 : 0"
                  style="color: var(--color-text-description); font-size: 16px;"/>
          <span v-if="isLogicActive"
                class="absolute left-full text-xl leading-none -mt-0.5 font-normal text-positive pl-3 pr-2">
            active
          </span>
        </div>
      </template>
      <a-collapse-panel key="logic">

        <template slot="header">
          <div class="flex items-center space-x-1.5 text-xl leading-none">
            <a-icon type="branches" style="color: var(--color-text-secondary); font-size: 24px;"/>
            <span class="text-character_gray-400 border-0 border-b border-dashed border-character_gray-400">
              Conditional logic
            </span>
          </div>
        </template>

        <div style="min-width: 650px">
          <p class="mt-2 mb-4">
            <VLearnMore :link="$constants('LINK_HELP_CONDITIONAL_LOGIC')"/>
          </p>
          <VConditionalLogic :question="question"/>
        </div>

      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import VConditionalLogic from "./VConditionalLogic";
import _ from "lodash";
import VLearnMore from "../VLearnMore";

export default {
  name: "VQuestionExtra",
  props: {
    question: Object,
  },
  components: {VConditionalLogic, VLearnMore},
  computed: {
    isLogicActive() {
      return this.question.logics && this.question.logics.length && _.some(this.question.logics, {active: true});
    }
  }
}
</script>

<style scoped>

</style>
