<template>
  <VAuthBlock title="Sign in to MetaSurvey">
    <a-form :form="form"
            @submit="makeLogin"
    >
      <a-form-item class="mb-6">
        <a-button
            type="default"
            block
            size="large"
            class="btn-action mt-1"
            @click="makeSocialAuth('google')"
        >
          <a-icon :component="iconGoogle"></a-icon>
          Continue with Google
        </a-button>
      </a-form-item>

      <a-divider/>

      <a-form-item
          :validate-status="emailError() ? 'error' : ''"
          :help="emailError() || ''"
          class="mt-6"
      >
        <a-input
            size="large"
            placeholder="Email"
            v-decorator="['email', { rules: [{type: 'email', message: 'Input valid email'}, { required: true, message: 'Input your email' }], initialValue: $route.query.email ? decodeURIComponent($route.query.email) : '', }]"
        >
          <a-icon slot="prefix" type="user" style="color: var(--color-gray-300)"/>
        </a-input>
      </a-form-item>
      <a-form-item
          :validate-status="passwordError() ? 'error' : ''"
          :help="passwordError() || ''"
      >
        <a-input-password
            size="large"
            placeholder="Password"
            v-decorator="['password', { rules: [{ required: true, message: 'Input your password' }] }]"
        >
          <a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
        </a-input-password>
        <template #extra>
          <div class="flex justify-end items-start mt-4">
            <VButton type="link"
                     size="large"
                     @click="$router.push({name: 'ForgotPassword'})"
            >
              Forgot password?
            </VButton>
          </div>
        </template>
      </a-form-item>
      <a-form-item class="mb-0">
        <div class="flex justify-between gap-8">
          <VButton type="primary"
                   size="large"
                   html-type="submit"
                   :disabled="hasErrors(form.getFieldsError())"
                   :loading="isLoading"
                   style="min-width: 152px;"
          >
            Sign in
          </VButton>
          <div class="flex items-center justify-center">
            <VButton type="link" size="large" @click="$router.push({name: 'Register', query: $route.query})">
              Register
            </VButton>
          </div>
        </div>
      </a-form-item>
    </a-form>
  </VAuthBlock>
</template>

<script>
import {LOGIN} from "@/store/actions.type";
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from "vuex";
import VAuthBlock from "@/components/VAuthBlock";
import {GoogleSvg} from "@/helpers/customIcons";
import socialAuth from "@/mixins/socialAuth";
import VButton from "../components/VButton";

export default {
  name: "Login",
  components: {VAuthBlock, VButton},
  mixins: [socialAuth],
  data() {
    return {
      hasErrors,
      form: this.$form.createForm(this, {name: 'login_form'}),
      iconGoogle: GoogleSvg,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },
  computed: {
    ...mapGetters('auth', ['isLoading'])
  },
  methods: {
    // Only show error after a field is touched.
    emailError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('email') && getFieldError('email');
    },
    // Only show error after a field is touched.
    passwordError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('password') && getFieldError('password');
    },
    makeLogin(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.$store.dispatch(`auth/${LOGIN}`, {
            ...values,
            invitationToken: this.$route.query?.invitation_token,
          })
              .then(() => {
                this.$router.push('/');
              })
        }
      });
    },
  },
}
</script>

<style lang="less" scoped>

</style>
