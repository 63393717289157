<template>
  <div class="relative w-full px-8 py-8">
    <div class="absolute inset-0 flex">
      <div class="m-auto text-center space-y-5">

        <VIcon name="page-not-found"></VIcon>

        <p class="text-3xl">Not Found</p>

        <VButton type="primary"
                 size="large"
                 class="mx-auto"
                 @click="$router.push({name: 'Index'})"
        >
          Back to Surveys
        </VButton>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from "@/components/VIcon";
import VButton from "@/components/VButton";

export default {
  name: "PageNotFound",
  components: {VIcon, VButton}
}
</script>
