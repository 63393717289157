<template>
  <div>
    <a-spin :spinning="isLoadingChild" tip="Calculating your results...">
      <div v-if="survey && survey._id">
        <SurveyAnalyticSummary/>
        <SurveyAnalyticQuestions/>
      </div>
    </a-spin>
  </div>
</template>

<script>
import SurveyAnalyticSummary from "@/components/SurveyEdit/Analytic/SurveyAnalyticSummary";
import SurveyAnalyticQuestions from "@/components/SurveyEdit/Analytic/SurveyAnalyticQuestions";
import {FETCH_RESPONSES} from "@/store/actions.type";
import {mapGetters} from "vuex";

export default {
  name: "SurveyEditAnalytic",
  components: {SurveyAnalyticSummary, SurveyAnalyticQuestions},
  mounted() {
    this.$store.dispatch(`survey/${FETCH_RESPONSES}`, {survey_id: this.$route.params.id})
  },
  computed: {
    ...mapGetters('survey', ['survey', 'isLoadingChild']),
  }
}
</script>

<style scoped>

</style>
