<template>
  <div>

    <div class="px-10">
      <h3 class="flex items-center mb-8 text-character_gray-400">
        <span class="font-light text-2xl">Appearance</span>
      </h3>

      <div class="flex space-x-8">
        <div class="flex items-center space-x-3">
          <span class="font-light text-lg">Width:</span>
          <a-input v-model="width" size="large" type="number" :min="1" class="select-unit" style="width: 130px;">
            <a-select slot="addonAfter" v-model="widthUnit" style="width: 60px">
              <a-select-option value="%">
                %
              </a-select-option>
              <a-select-option value="px">
                px
              </a-select-option>
            </a-select>
          </a-input>
        </div>

        <div class="flex items-center space-x-3">
          <span class="font-light text-lg">Height:</span>
          <a-input v-model="height" size="large" type="number" :min="1" class="select-unit" style="width: 130px;">
            <a-select slot="addonAfter" v-model="heightUnit" style="width: 60px">
              <a-select-option value="%">
                %
              </a-select-option>
              <a-select-option value="px">
                px
              </a-select-option>
            </a-select>
          </a-input>
        </div>
      </div>
    </div>

    <a-divider :dashed="true" style="margin: 40px 0 28px; border-color: var(--color-neutral-gray-300)"/>

    <div class="px-10">
      <VEmbedCode type="widget">
        <template #description>
          <p>
            This is your responsive widget embed code. <b>Copy-paste</b> it in your page's HTML where you want your survey
            to appear.
          </p>
          <p class="mb-0">Note: if you want to change the settings of an existing widget, replace it with the generated new code.</p>
        </template>
        <template #code>
          <code>
            {{ embedDiv }}
            {{ embedScript }}
          </code>
        </template>
      </VEmbedCode>
    </div>
  </div>
</template>

<script>
import VEmbedCode from "../../../../layouts/VEmbedCode";
import {embedMixin} from "../../../../mixins/embed";
import {mapGetters} from "vuex";

export default {
  name: "VSharingWidget",
  components: {VEmbedCode},
  data() {
    return {
      width: 100,
      widthUnit: "%",
      height: 650,
      heightUnit: "px",
    }
  },
  mixins: [embedMixin],
  computed: {
    ...mapGetters('survey', ["survey"]),
    embedDiv: function () {
      let embedDiv = document.createElement('div')

      embedDiv.style.width = this.width + this.widthUnit
      embedDiv.style.height = this.height + this.heightUnit

      embedDiv.setAttribute('data-ms-widget', this.survey._id)

      return embedDiv.outerHTML
    },
  },
}
</script>

<style lang="less" scoped>

</style>
