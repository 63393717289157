<template>
  <div class="flex flex-wrap items-center gap-x-5 gap-y-7">
    <div v-for="btn in btnList"
         :key="btn.type"
         class="relative flex-none"
    >
      <button
          type="button"
          :class="[
              btn.className,
              `metasurvey-btn-${size}`,
              `metasurvey-btn-${shape}`,
              {'border-2 border-solid border-blue-500': currentStyle === btn.type},
              {'opacity-80': currentStyle !== btn.type},
           ]"
          @click="onClickButton(btn.type)"
      >
        <i>
          <VIcon name="embed-btn-thumb-up"></VIcon>
        </i>
        <span v-if="shape === 'text'">{{ value | defaultButtonText }}</span>
        <i v-if="shape === 'text' && isClosable">
          <VIcon name="embed-btn-close"></VIcon>
        </i>
      </button>

      <a-icon v-if="currentStyle === btn.type"
              type="caret-up"
              class="text-blue-500 absolute"
              style="font-size: 28px; bottom: -28px; left: 50%; transform: translateX(-50%);"
      ></a-icon>
    </div>
  </div>
</template>

<script>
import VIcon from "../components/VIcon";

export default {
  name: "VEmbedButton",
  props: {
    btnList: {type: Array, required: true},
    value: {type: String, required: true},
    currentStyle: {type: String, required: true},
    shape: {type: String, required: true},
    size: {type: String},
    isClosable: {type: Boolean},
  },
  components: {VIcon},
  filters: {
    defaultButtonText(text) {
      return text || "Leave feedback";
    }
  },
  methods: {
    onClickButton(type) {
      this.$emit('doClickButton', type)
    }
  }
}
</script>

<style scoped>

</style>
