<template>
  <a-modal
      v-model="modal.isVisible"
      :title="modalTitle"
      :footer="null"
      :destroyOnClose="true">

    <InviteForm/>

    <div class="mb-4 mt-2">
      <VLearnMore :link="$constants('LINK_HELP_INVITE_COLLABORATORS')"/>
    </div>

    <div>Collaborators:</div>

    <CollaboratorList/>

  </a-modal>
</template>

<script>
import {mapGetters} from "vuex";
import InviteForm from "../Collaborators/InviteForm";
import CollaboratorList from "../Collaborators/CollaboratorList";
import VLearnMore from "../VLearnMore";

export default {
  name: "InviteCollaboratorModal",

  components: {
    InviteForm,
    CollaboratorList,
    VLearnMore
  },

  computed: {
    ...mapGetters("collaborators", ["permissions"]),
    modal: {
      get() {
        return this.$store.getters["collaborators/modal"];
      },
      set(value) {
        this.$store.commit("collaborators/setModal", {isVisible: value, surveyId: null, folderId: null});
      }
    },
    modalTitle() {
      let type = this.modal.surveyId ? 'Survey' : 'Folder';
      let name = this.modal.surveyId ? this.$store.getters["home/getSurveyById"](this.modal.surveyId)?.title || this.$store.getters["survey/survey"]?.title : this.$store.getters["home/getFolderById"](this.modal.folderId)?.name;
      name = name ? name : "Untitled";

      const prefix = "Invite Collaborator Into";

      return `${prefix} ${type} "${name}"`;
    },
  },
}
</script>

<style lang="less">
.permission-select {
  & .ant-select-selection__rendered {
    font-weight: 400;
  }

  & .ant-select-dropdown-content,
  & .ant-select-dropdown-menu-item {
    font-size: 16px;
  }
}
</style>
