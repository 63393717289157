<template>
  <div class="px-10 pt-8 pb-4 flex items-stretch space-x-9">
    <VIcon :name="icon" class="flex-none"/>

    <div class="flex-grow h-full">
      <div class="font-semibold text-xl leading-tight mb-4">{{ name }}</div>

      <div class="flex flex-wrap gap-x-8 gap-y-4">
        <slot></slot>
      </div>

    </div>
  </div>
</template>

<script>
import VIcon from "../components/VIcon";

export default {
  name: "VTranslationsItem",

  components: {VIcon},

  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    }
  }
}
</script>

<style lang="less" scoped>

</style>
