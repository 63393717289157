<template>
  <div class="w-full">
    <a-spin :spinning="isLoading" class="relative">
      <h2 class="text-3xl mb-6 text-dark_gray-500">
        Create new survey
      </h2>


      <div class="flex space-x-2 mb-5">
        <div v-for="templateGroup in templateGroups"
             :key="templateGroup.slug"
             class="flex items-center space-x-1 cursor-pointer text-base leading-tight px-4 py-2 rounded-3xl"
             :class="templateGroup.slug === activeTemplateGroup ? 'template-group-active' : 'template-group'"
             @click="activeTemplateGroup = templateGroup.slug"
        >
          <a-icon :type="templateGroup.iconName"></a-icon>
          <span>{{ templateGroup.name }}</span>
        </div>
      </div>


      <div class="flex -mx-2">

        <div class="w-1/6 px-2" style="min-width: 200px;">
          <div
              class="shadow w-full rounded-xl border-1.5 border-dashed border-blue-500 light-blue-bg cursor-pointer group"
              style="height: 272px;"
              @click="onCreate(false)"
          >
            <div class="flex items-center justify-center space-x-4 h-full w-full">
              <div class="text-center group-hover:opacity-75 transition duration-100">
                <VIcon name="add-file"></VIcon>
                <div class="text-blue-500 text-xl font-semibold">Blank survey</div>
              </div>
            </div>
          </div>
        </div>


        <div class="w-5/6" style="min-height: 620px;">
          <div class="flex flex-wrap">
            <div v-for="template in templatesComputed"
                 :key="template.slug"
                 class="w-1/5 px-2 mb-4"
                 style="min-width: 200px;"
            >
              <div class="relative shadow w-full rounded-xl cursor-default bg-white overflow-hidden group"
                   :class="{'border-hover': !template.isLockedForFree}"
                   style="height: 272px;"
              >

                <div v-if="template.isLockedForFree"
                     class="absolute z-10 top-0 left-0 flex flex-col justify-center items-center bg-white bg-opacity-75 w-full h-full space-y-1 group">
                  <div
                      class="flex rounded-full h-16 w-16 border border-solid border-gray-200 bg-white group-hover:-mt-10 transition-all duration-100">
                    <a-icon type="lock"
                            theme="filled"
                            class="text-dark_gray-500 opacity-60 group-hover:opacity-100 m-auto"
                            style="font-size: 29px;"></a-icon>
                  </div>
                  <div class="relative w-full">
                    <div
                        class="absolute t-0 w-full px-4 flex flex-col items-center opacity-0 group-hover:opacity-100 transition-all duration-100">
                      <div class="text-dark_gray-500 text-base font-semibold mb-1">Pro survey</div>
                      <div class="space-y-2">
                        <router-link :to="{name: 'Billing'}">
                          <VButton type="violet" block style="font-size: 16px;"
                                   @click="$posthog.capture('Clicked on Upgrade button on PRO Template', {templateName: template.title, templateId: template.survey_id})">
                            Upgrade to unlock
                          </VButton>
                        </router-link>
                        <VButton type="default"
                                 icon="eye"
                                 block
                                 style="font-size: 16px; background: #fff;"
                                 @click="previewSurvey(template.survey_id, true)"
                        >Preview
                        </VButton>
                      </div>
                    </div>
                  </div>

                </div>

                <div :class="{'group-hover:max-h-0 overflow-hidden slide-in-down': !template.isLockedForFree}">
                  <div class="template-up-block p-2.5" :style="{backgroundColor: template.color}">
                    <div class="flex flex-wrap -mx-0.5">
                      <div v-for="(groupName, index) in template.groupNames"
                           :key="index"
                           class="px-0.5 mb-1">
                        <div
                            class=" rounded-3xl text-white border border-solid border-white border-opacity-50 py-1 px-2 text-sm leading-none">
                          {{ groupName }}
                        </div>
                      </div>
                      <div class="px-0.5 mb-1">
                        <div
                            class="rounded-3xl text-white border border-solid border-white border-opacity-50 py-1 px-2 text-sm leading-none">
                          {{ template.questionsCount }} questions
                        </div>
                      </div>
                      <div class="px-0.5 mb-1">
                        <div
                            class="rounded-3xl text-white border border-solid border-white border-opacity-50 py-1 px-2 text-sm leading-none">
                          {{ template.estimatedTime }} min
                        </div>
                      </div>
                    </div>
                    <div style="font-size: 36px; letter-spacing: 4px;"
                         class="text-center leading-none mt-2.5 opacity-90">
                      {{ template.emoji }}
                    </div>
                  </div>
                  <div class="py-3 space-y-2">
                    <p class="font-bold text-base text-character_gray-400 m-0 leading-tight px-4">
                      {{ template.title }}
                    </p>
                    <p class="text-sm text-character_gray-300 m-0 leading-tight pl-4 pr-2">
                      {{ template.description }}
                    </p>
                  </div>
                </div>

                <div v-if="!template.isLockedForFree"
                     class="hidden group-hover:flex light-blue-bg flex flex-col justify-between h-full pt-2.5 pb-3.5">
                  <div class="px-2.5">
                    <div class="flex flex-wrap -mx-0.5">
                      <div v-for="(groupName, index) in template.groupNames"
                           :key="index"
                           class="px-0.5 mb-1">
                        <div
                            class=" rounded-3xl text-neutral_gray-700 border border-solid border-neutral_gray-500 py-1 px-2 text-sm leading-none">
                          {{ groupName }}
                        </div>
                      </div>
                      <div class="px-0.5 mb-1">
                        <div
                            class="rounded-3xl text-neutral_gray-700 border border-solid border-neutral_gray-500 py-1 px-2 text-sm leading-none">
                          {{ template.questionsCount }} questions
                        </div>
                      </div>
                      <div class="px-0.5 mb-1">
                        <div
                            class="rounded-3xl text-neutral_gray-700 border border-solid border-neutral_gray-500 py-1 px-2 text-sm leading-none">
                          {{ template.estimatedTime }} min
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="space-y-2">
                    <p class="font-bold text-base text-character_gray-400 m-0 leading-tight px-4">
                      {{ template.title }}
                    </p>
                    <p class="text-sm text-character_gray-300 m-0 leading-tight pl-4 pr-2">
                      {{ template.description }}
                    </p>
                  </div>
                  <div class="px-4 space-y-2">
                    <VButton type="default"
                             size="large"
                             icon="eye"
                             block
                             style="height: 36px;"
                             @click="previewSurvey(template.survey_id, true)"
                    >Preview
                    </VButton>
                    <VButton type="primary"
                             size="large"
                             block
                             style="height: 36px;"
                             @click="onCreate(template.survey_id)"
                    >Use template
                    </VButton>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div class="pl-2 mt-6">
            <a :href="`${$constants('LINK_FEATURE_REQUEST')}?sso=${productLiftSsoToken}`" target="_blank">
              <VButton type="link" size="large">Request your own template</VButton>
            </a>
          </div>

        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import {surveyMixin} from "@/mixins/survey";
import {mapGetters} from "vuex";
import VIcon from "../VIcon";
import VButton from "../VButton";
import _ from "lodash";
import {SURVEY_CLONE, SURVEY_CREATE} from "../../store/actions.type";

export default {
  name: "Templates",
  components: {VIcon, VButton},
  mixins: [surveyMixin],

  data() {
    return {
      activeTemplateGroup: 'all',
      defaultActiveTemplateGroup: 'all',
      isLoading: false,
    }
  },

  computed: {
    ...mapGetters('home', ["templates", "templateGroups"]),
    ...mapGetters('auth', ['user', 'productLiftSsoToken']),

    templatesComputed: function () {
      if (!this.templates) return [];

      const templateGroups = this.templateGroups
      this.templates.map((template) => {

        template.groupNames = []
        _.forEach(template.groups.split(","), (groupSlug) => {
          template.groupNames.push(_.find(templateGroups, ['slug', groupSlug])?.name)
        })

        if (template.isLockedForFree && this.user.current_plan !== 'free') {
          template.isLockedForFree = false
        }

        return template
      })

      if (this.activeTemplateGroup === this.defaultActiveTemplateGroup) return this.templates

      return _.filter(this.templates, (template) => {
        return _.includes(template.groups, this.activeTemplateGroup)
      })
    },

  },
  methods: {
    async onCreate(templateId) {
      this.isLoading = true;

      const actionType = templateId ? SURVEY_CLONE : SURVEY_CREATE;
      const payload = {folderId: this.$route.params.folderId};
      if (templateId) payload.survey_id = templateId;

      try {
        const {_id, title} = await this.$store.dispatch(`survey/${actionType}`, payload);
        this.$router.push(`/survey/${_id}`);
        const eventName = templateId ? 'Survey Created from template' : 'Survey Created blank';
        const eventProperties = templateId ? {templateTitle: title, templateId: _id} : {};
        this.$posthog.capture(eventName, eventProperties);
      } finally {
        this.isLoading = false;
      }
    }
  }
}
</script>

<style lang="less" scoped>
.light-blue-bg {
  background: linear-gradient(0deg, rgba(0, 204, 255, 0.06), rgba(0, 204, 255, 0.06)), #FFFFFF;
}

.shadow {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
}

.border-hover {
  &:hover {
    border: 1.5px solid var(--color-blue-500);
  }
}

.template-up-block {
  height: 132px;
}

.slide-in-down {
  height: auto;
  max-height: 272px;
  transition: max-height .3s;
}

.template-group {
  background-color: rgba(154, 165, 187, 0.08);
  color: @v-color-neutral-gray-700;
  transition: color .2s;

  & i {
    color: @v-color-neutral-gray-500;
  }

  &-active {
    background-color: rgba(0, 204, 255, 0.08);
    color: @v-color-blue-500;
  }

  &:hover {
    color: @v-color-blue-500;

    & i {
      color: @v-color-blue-500;
    }
  }
}
</style>
