<template>
  <div>
    <div class="flex items-start pl-12">
      <div class="flex flex-grow">
        <span class="text-xl font-light">Question:</span>
        <VTextArea :max-length="questionMaxLength"
                   :count-typed-symbols="question.text.length"
                   :has-upgrade="true"
                   class="flex-grow ml-3">
          <a-textarea placeholder="Your question here"
                      :max-length="questionMaxLength"
                      :auto-size="{ minRows: 3 }"
                      v-model="question.text"
          />
        </VTextArea>
      </div>
      <div class="ml-5 flex-none">
        <div class="flex">
          <div class="flex items-center">
            <span class="text-xl font-light">Shape</span>
            <a-tooltip class="ml-1 mr-2">
              <template slot="title">
                Select how the icons are displayed.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
          <a-select v-model="question.subtype" style="width: 144px" size="large">
            <a-select-option v-for="subtype in subtypes"
                             :key="subtype.slug"
                             :value="subtype.slug"
            >
              {{ subtype.title }}
            </a-select-option>
          </a-select>
        </div>
        <div v-show="question.subtype !== 'stars'" class="flex mt-5 justify-end">
          <div class="flex items-center">
            <span class="text-xl font-light">Scale</span>
            <a-tooltip class="ml-1 mr-2">
              <template slot="title">
                Number of points.
              </template>
              <a-icon type="info-circle" class="icon-info"/>
            </a-tooltip>
          </div>
          <a-select v-model="question.options.ratingScale" style="width: 80px" size="large">
            <a-select-option
                v-for="scaleVal in scales"
                :key="scaleVal"
                :value="scaleVal"
            >
              {{ scaleVal }}
            </a-select-option>
          </a-select>
        </div>
      </div>
    </div>
    <div class="flex mt-3">
      <div class="w-32 flex justify-end items-center mr-5 font-light text-lg">
        Preview
        <a-icon type="eye" class="ml-1 text-2xl"
                style="color: var(--color-neutral-gray-300)"></a-icon>
      </div>
      <div>
        <div class="flex" style="line-height: 0;">
          <a-rate v-if="question.subtype === 'stars'"
                  :value="5"
                  disabled
                  class="stars-preview"
                  style="font-size: 48px; line-height: 1;"
          />
          <VQuestionSmilesPreview v-else
                                  :scale="question.options.ratingScale"
                                  :subtype="question.subtype"/>
        </div>
        <div class="flex justify-between w-full mt-3 px-2 space-x-2">
          <div>
            <span v-if="showMinLabel"
                  class="text-base text-character_gray-200 dark:text-white dark:text-opacity-70">{{ minLabel }}</span>
          </div>
          <div class="text-right">
            <span v-if="showMaxLabel"
                  class="text-base text-character_gray-200 dark:text-white dark:text-opacity-70">{{ maxLabel }}</span>
          </div>
        </div>
      </div>
    </div>

    <a-divider style="margin-top: 32px; margin-bottom: 16px; border-color: var(--color-gray-300)"/>

    <div class="extra-collapse">
      <a-collapse :bordered="false" expandIconPosition="right">

        <template #expandIcon="props">
          <a-icon type="down"
                  :rotate="props.isActive ? 180 : 0"
                  style="color: var(--color-text-description); font-size: 16px;"/>
        </template>

        <a-collapse-panel key="1">

          <template slot="header">
            <div class="flex items-center space-x-1.5 text-xl leading-none">
              <VIcon name="translation" style="color: var(--color-text-secondary); font-size: 24px;"/>
              <span class="text-character_gray-400 border-0 border-b border-dashed border-character_gray-400">
                  Change min-max labels
                </span>
            </div>
          </template>

          <div class="flex flex-wrap gap-x-8 gap-y-4 pt-6">
            <VSwitchInput input-name="Min label"
                          input-info-html="<b>Min value label</b>. Show the min level of satisfaction."
                          :input-info-icon="question.subtype === 'stars' ? 'tooltip-question-stars-min' : 'tooltip-question-smiles-min'"
                          :input-length="20"
                          input-width="168px"
                          :input-placeholder="translationPlaceholder.min"
                          :input-value.sync="question.translations.minLabel.value"
                          :switch-value.sync="question.translations.minLabel.active"
            />

            <VSwitchInput input-name="Max label"
                          input-info-html="<b>Max value label</b>. Show the max level of satisfaction."
                          :input-info-icon="question.subtype === 'stars' ? 'tooltip-question-stars-max' : 'tooltip-question-smiles-max'"
                          :input-length="20"
                          input-width="168px"
                          :input-placeholder="translationPlaceholder.max"
                          :input-value.sync="question.translations.maxLabel.value"
                          :switch-value.sync="question.translations.maxLabel.active"
            />
          </div>

        </a-collapse-panel>
      </a-collapse>
    </div>

  </div>
</template>

<script>
import questionMixin from "@/mixins/question";
import VQuestionSmilesPreview from "@/components/SurveyEdit/Design/VQuestionSmilesPreview";
import VIcon from "../../../VIcon";
import VSwitchInput from "../../../VSwitchInput";
import {mapGetters} from "vuex";
import VTextArea from "../../../VTextArea";

export default {
  name: "QuestionRating",

  props: ["question"],

  components: {
    VQuestionSmilesPreview,
    VIcon,
    VSwitchInput,
    VTextArea,
  },

  mixins: [questionMixin],

  data() {
    return {
      subtypes: [
        {
          slug: "smilesGray",
          title: "Smiles gray"
        },
        {
          slug: "smilesColorful",
          title: "Smiles colored"
        },
        {
          slug: "stars",
          title: "Stars"
        }
      ],
      scales: [2, 3, 5],
    }
  },

  computed: {
    ...mapGetters('survey', ['survey']),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 100;
    },
    translationPlaceholder: function () {
      const minSmiles = this.survey.translations.questions.likertSmiles.minLabel
      const maxSmiles = this.survey.translations.questions.likertSmiles.maxLabel
      const minStars = this.survey.translations.questions.likertStars.minLabel
      const maxStars = this.survey.translations.questions.likertStars.maxLabel

      if (this.question.subtype === 'stars') {
        return {
          min: minStars.value || minStars.defaultValue,
          max: maxStars.value || maxStars.defaultValue,
        }
      }

      return {
        min: minSmiles.value || minSmiles.defaultValue,
        max: maxSmiles.value || maxSmiles.defaultValue,
      }
    },
    minLabel: function () {
      if (this.question.subtype === 'stars') {
        return this.question.translations.minLabel.value || this.survey.translations.questions.likertStars.minLabel.value || this.survey.translations.questions.likertStars.minLabel.defaultValue;
      }
      return this.question.translations.minLabel.value || this.survey.translations.questions.likertSmiles.minLabel.value || this.survey.translations.questions.likertSmiles.minLabel.defaultValue;
    },
    maxLabel: function () {
      if (this.question.subtype === 'stars') {
        return this.question.translations.maxLabel.value || this.survey.translations.questions.likertStars.maxLabel.value || this.survey.translations.questions.likertStars.maxLabel.defaultValue;
      }
      return this.question.translations.maxLabel.value || this.survey.translations.questions.likertSmiles.maxLabel.value || this.survey.translations.questions.likertSmiles.maxLabel.defaultValue;
    },
    showMinLabel: function () {
      return this.question.translations.minLabel.active
    },
    showMaxLabel: function () {
      return this.question.translations.maxLabel.active
    },
  },
}
</script>
