<template>
  <div class="flex items-center">
    <div class="question_type_icon__wrapper flex-none mr-4">
      <VIcon name="preview-unknown"/>
    </div>
    <div class="bg-gray-50 rounded flex justify-center items-center flex-grow px-4 pt-20 pb-16">
      <VIcon name="wind"/>
      <div class="ml-4">
        <p class="font-light text-xl mb-1">
          No questions
        </p>
        <p class="text-base font-light">
          Learn:
          <a :href="$constants('LINK_POST_RATING_SCALES')"
             target="_blank"
             class="underline hover:no-underline"
             @click="$posthog.capture('Clicked on How to choose the right question type link')">
            how to choose the right question type
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import VIcon from "../VIcon";

export default {
  name: "QuestionEmpty",
  components: {VIcon},
}
</script>
