<template>
  <Draggable
      v-model="surveysAndFolders"
      :options="{ draggable: '.survey-item' }"
      class="flex flex-wrap -mx-2">
    <div v-for="item in surveysAndFolders"
         :key="item._id || item.text"
         class="w-1/6 px-2 mb-4"
         style="min-width: 198px; height: 190px;">
      <SurveyItem v-if="!item.isButton && !item.isFolder"
                  :survey="item"
                  @move-survey="onMoveSurvey"
                  @duplicate-survey="onDuplicateSurvey"/>
      <FolderItem v-else-if="item.isFolder" :folder="item" @drop-survey="dropSurveyToFolder"/>
      <NewItem v-else @click="$emit('create-survey')"/>
    </div>
  </Draggable>
</template>

<script>
import surveyMixin from "@/mixins/survey";
import {mapGetters} from "vuex";
import SurveyItem from "./SurveyItem";
import FolderItem from "./FolderItem";
import NewItem from "./NewItem";
import Draggable from 'vuedraggable';

export default {
  name: "SurveysAndFolders",
  props: {
    canCreate: Boolean
  },
  components: {SurveyItem, FolderItem, NewItem, Draggable},
  mixins: [surveyMixin],
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters('home', ["surveys", "folders"]),
    surveysAndFolders() {
      return [
        ...(this.canCreate ? [{isButton: true}] : []),
        ...this.surveys,
        ...(this.$route.params.folderId ? [] : this.folders.map(folder => ({...folder, isFolder: true})))
      ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
  },
  methods: {
    onMoveSurvey({survey, folderId}) {
      this.$emit('move-survey', {survey, folderId});
    },
    onDuplicateSurvey({survey}) {
      this.$emit('duplicate-survey', {survey});
    },
    dropSurveyToFolder({surveyId, folderId}) {
      const survey = this.surveysAndFolders.find(item => item._id === surveyId);
      if (survey && !survey.isFolder && !survey.isButton) {
        this.onMoveSurvey({survey, folderId});
      }
    },
  },
}
</script>

<style lang="less" scoped>
</style>
