import ApiService from "@/common/api.service";
import {CREATE_THEME, DELETE_THEME, DUPLICATE_THEME, FETCH_THEMES, UPDATE_THEME} from "./actions.type";
import {
  CREATE_THEME_MUTATION, DELETE_THEME_MUTATION,
  DUPLICATE_THEME_MUTATION,
  SET_THEMES,
} from "./mutations.type";
import _ from "lodash";


const state = {
  themes: [],
}

const getters = {
  themes(state) {
    return state.themes;
  },
  currentTheme(state, getters, rootState, rootGetters) {
    const survey = rootGetters['survey/survey'];
    if (survey && survey.themeId) {
      return _.find(state.themes, {'_id': survey.themeId});
    }
    return null;
  }
}

const actions = {
  [FETCH_THEMES]({commit}, {surveyId}) {
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${surveyId}/themes`)
        .then(result => {
          if (result.code && result.code > 400) {
            throw result.message;
          }
          commit(SET_THEMES, result);
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [CREATE_THEME]({commit}, {surveyId}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${surveyId}/theme`)
        .then(result => {
          if (result.code && result.code > 400) {
            throw result;
          }
          commit(CREATE_THEME_MUTATION, result);
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [UPDATE_THEME](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${payload.surveyId}/theme/${payload._id}`, payload)
        .then(result => {
          if (result.code && result.code > 400) {
            throw result.message;
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [DELETE_THEME]({commit}, {themeId, surveyId}) {
    return new Promise((resolve, reject) => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/survey/${surveyId}/theme/${themeId}`)
        .then(result => {
          if (result.status && result.status > 400) {
            throw `Failed to delete theme: ${themeId}`;
          }
          commit(DELETE_THEME_MUTATION, themeId);
          resolve(themeId);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [DUPLICATE_THEME]({commit}, {themeId, surveyId}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${surveyId}/theme/${themeId}/duplicate`)
        .then(result => {
          if (result.code && result.code > 400) {
            throw result;
          }
          commit(DUPLICATE_THEME_MUTATION, result);
          resolve(result);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
}

const mutations = {
  [SET_THEMES](state, payload) {
    state.themes = payload;
  },

  [CREATE_THEME_MUTATION](state, payload) {
    state.themes.push(payload);
  },

  [DUPLICATE_THEME_MUTATION](state, payload) {
    state.themes.push(payload);
  },

  [DELETE_THEME_MUTATION](state, payload) {
    const objIndex = state.themes.findIndex(obj => obj._id === payload)
    if (objIndex !== -1) {
      state.themes.splice(objIndex, 1)
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
