<template>
    <span v-if="Object.keys(label).length > 0"
          class="label flex ml-2 text-white py-1 px-2 rounded-sm cursor-pointer leading-none"
          :class="label.class"
          @click="$emit('click')">
        <a-icon :type="label.icon"
                class="mr-1"
                theme="filled"
                style="color: var(--color-base-theme); opacity: 0.8"></a-icon>
        {{ label.value }}
    </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "VLabel",
  props: {
    text: {
      type: String,
    },
    type: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...mapGetters('auth', ["user", "isUserLifetime"]),
    label: function () {
      if (this.type === 'feature') {
        let value = this.text || 'Upgrade';

        return {
          class: 'label_color-violet',
          icon: 'star',
          value
        }
      }

      if (this.type === "plan") {
        if (this.user.current_plan === this.$constants('PLAN_FREE')) {
          return {}
        }

        if (this.isUserLifetime) {
          return {
            class: 'label_color-yellow',
            icon: 'crown',
            value: 'LTD'
          }
        }

        return {
          class: 'label_color-violet',
          icon: 'star',
          value: 'PRO'
        };
      }

      return {}
    }
  }
}
</script>

<style lang="less" scoped>
.label {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  height: 24px;

  &_color-blue {
    background: @v-color-button-blue;
  }

  &_color-yellow {
    background: @v-color-button-yellow;
  }

  &_color-violet {
    background: @v-color-button-violet;
  }
}
</style>
