export const FeedbackMixin = {
  methods: {
    onFeedback() {
      const feedbackLink = this.$store.getters["auth/isUserLifetime"] ? this.$constants('LINK_REVIEW_ON_APPSUMO') : this.$constants('LINK_REVIEW_ON_PRODUCTHUNT');

      this.$posthog.capture('Clicked on Feedback');

      window.open(feedbackLink, '_blank');
    }
  }
}
