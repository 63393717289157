import {GET_AUTH_GOOGLE_URL} from "@/store/actions.type";

export const socialAuth = {
  mounted() {
    this.$store.dispatch(`auth/${GET_AUTH_GOOGLE_URL}`)
      .then((url) => {
        this.authGoogleUrl = url;
      });
  },
  data() {
    return {
      authGoogleUrl: null
    }
  },
  methods: {
    makeSocialAuth(type) {
      switch (type) {
        case 'google': {
          const url = new URL(this.authGoogleUrl);
          if (this.$route.query?.invitation_token) {
            url.searchParams.append('state', encodeURIComponent(JSON.stringify({invitationToken: this.$route.query?.invitation_token})));
          }
          window.location.href = url.toString();
          break;
        }
      }
    },
  }
}

export default socialAuth;
