import VSubscriptionLayout from "../layouts/VSubscriptionLayout";
import VButton from "../components/VButton";
import VIcon from "../components/VIcon";
import VVerticalRadio from "../components/VVerticalRadio";
import VPlanRadio from "../components/VPlanRadio";
import {subscriptionMixin} from "./subscription";
import {mapGetters} from "vuex";
import _ from "lodash";
import {plansMixin} from "./plans";
import {FETCH_PLANS} from "../store/actions.type";

export const subscriptionProMixin = {
  components: {VSubscriptionLayout, VButton, VIcon, VVerticalRadio, VPlanRadio},

  mixins: [subscriptionMixin, plansMixin],

  created() {
    this.$store.dispatch(`billing/${FETCH_PLANS}`)
      .then(() => {
        this.selectedPlanDefault = _.find(this.plans, 'isSelected')?.product_id;
      })

    this.selectedPlan = Number(this.user?.subscription?.subscriptionPlanId);
  },

  data() {
    return {
      selectedPlan: undefined,
      selectedPlanDefault: undefined,
    }
  },

  computed: {
    ...mapGetters('auth', ["user", "features", "oldFeatures", "responsesLimit"]),
    ...mapGetters('billing', ["country", "isLoading"]),

    currentPlan: function () {
      let responses = this.oldFeatures?.responses_count || this.features?.responses_count
      responses = responses >= 999999 ? 'Unlimited' : responses

      return {
        icon: this.getPlanIcon(),
        name: this.getPlanName(),
        details: `${responses} responses, unlimited surveys and questions`,
        status: this.getStatus(),
        responsesLimit: this.getLimits(),
        planId: Number(this.user?.subscription?.subscriptionPlanId),
      }
    },
  },
}
