<template>
  <div class="flex flex-wrap flex-col">
    <div class="inline-block m-auto relative">
      <div class="image-uploader-wrapper">
        <a-upload
            name="avatar"
            accept="image/*"
            list-type="picture-card"
            class="image-uploader"
            :class="[`image-uploader_${uploaderType}`]"
            :show-upload-list="false"
            :action="uploadEndPoint"
            :before-upload="beforeUpload"
            :disabled="isDisabled"
            @change="handleChange"
        >
          <div v-if="imageUploadUrl">
            <ik-image :src="imageUploadUrl | parseImgUrl"
                      class="w-full"
                      alt="Image"
            />
          </div>
          <div class="text-text-100" v-else>
            <UploadPictureCardIcon v-if="!imageLoading" class="upload-icon"/>
            <a-icon v-else type="loading" class="upload-icon"/>
            <div class="ant-upload-text leading-tight mt-1">
              {{ uploaderType === 'company' ? 'Drag logo here' : 'Click or drag image here' }}
            </div>
          </div>
        </a-upload>
        <div v-if="!isDisabled && imageUploadUrl" class="upload-preview relative">
          <a-tooltip>
            <template slot="title">
              Remove this image
            </template>
            <div class="cursor-pointer p-1"
                 @click="clearImageUploadUrl"
            >
              <a-icon type="delete"
                      style="font-size: 24px; color: var(--color-base-theme)"
              />
            </div>
          </a-tooltip>
        </div>
      </div>
    </div>
    <div class="mt-2 text-center">
      <a-popover v-if="!isDisabled"
                 v-model="visibleAttachLinkPopover"
                 trigger="click"
                 placement="bottomLeft"
      >
        <template slot="content" class="w-64">
          <a-form :form="form">
            <a-form-item>
              <a-input
                  size="large"
                  placeholder="https://"
                  class="mb-3 attach-link__input"
                  v-decorator="['imageStringUrl', { rules: [{ required: true, message: 'Fill the URL' }] }]"
              />
            </a-form-item>
          </a-form>
          <div class="mt-3 flex justify-end">
            <a-button
                size="large"
                class="mr-2"
                @click="visibleAttachLinkPopover = false"
            >
              Cancel
            </a-button>
            <a-button
                type="primary"
                size="large"
                @click="changeImageUrl"
            >
              Add
            </a-button>
          </div>
        </template>
        <a-button type="link"
                  class="attach-link m-auto"
        >
          Or attach via link
        </a-button>
      </a-popover>
      <a-button v-else
                type="link"
                class="attach-link m-auto cursor-not-allowed"
      >
        Or attach via link
      </a-button>
    </div>

    <a-divider/>

    <div class="text-sm text-character_gray-400" style="max-width: 300px;">
      <div class="mb-1">Image upload guidelines for best results:</div>
      <ul class="pl-4">
        <li>Dimensions: {{ dimensions }} px</li>
        <li>Format: jpeg, png, gif, svg, webp</li>
        <li>Max size: 2MB</li>
      </ul>
    </div>
  </div>
</template>

<script>
import {UploadPictureCardIcon} from "@/helpers/customIcons";
import {parseImgUrl} from "../helpers/fns";
import {DELETE_FILE} from "../store/actions.type";

export default {
  name: "VUpload",
  props: {
    uploaderType: {type: String},
    imageSrc: {type: String},
    isDisabled: {type: Boolean, default: false},
    dimensions: {type: String}
  },
  components: {UploadPictureCardIcon},
  filters: {parseImgUrl},
  data() {
    return {
      form: this.$form.createForm(this, {name: 'imageStringUrl_form'}),
      imageLoading: false,
      imageUploadUrl: this.imageSrc,
      imageStringUrl: '',
      visibleAttachLinkPopover: false,
      uploadEndPoint: `${process.env.VUE_APP_API_URL}/upload/`,
      showPreview: false
    }
  },
  watch: {
    imageUploadUrl: {
      handler(imageUploadUrl) {
        this.$emit('update:image-src', imageUploadUrl)
      }
    }
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.imageLoading = true
        return
      }
      if (info.file.status === 'done') {
        this.imageUploadUrl = `${process.env.VUE_APP_UPLOAD_URL}${info.file.response[0].fileName}`
        this.imageLoading = false

        if (this.uploaderType === 'company') {
          this.$posthog.capture('Uploaded Company Logo', {surveyId: this.$route.params.id});
        } else if (this.uploaderType === 'welcome-screen') {
          this.$posthog.capture('Uploaded Welcome Background Image', {surveyId: this.$route.params.id});
        } else if (this.uploaderType === 'thankyou-screen') {
          this.$posthog.capture('Uploaded Thank You Image', {surveyId: this.$route.params.id});
        }
      }
      if (info.file.status === 'error') {
        this.imageLoading = false
        this.$message.error({content: 'Upload error. Please try again.', duration: 5})
      }
    },
    beforeUpload(file) {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/apng' || file.type === 'image/avif' || file.type === 'image/gif' || file.type === 'image/svg+xml' || file.type === 'image/webp';
      if (!isImage) {
        this.$message.error('You can only upload image file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('Image must smaller than 2MB!');
      }
      return isImage && isLt2M;
    },
    changeImageUrl() {
      this.form.validateFields((err, values) => {
        if (!err) {
          this.deleteUploadedImage(this.imageUploadUrl);
          this.imageUploadUrl = values.imageStringUrl;
          this.visibleAttachLinkPopover = false
        }
      });
    },
    clearImageUploadUrl() {
      this.deleteUploadedImage(this.imageUploadUrl);
      this.imageUploadUrl = '';
      this.form.resetFields();
    },
    deleteUploadedImage(imageUrl) {
      if (imageUrl?.includes(process.env.VUE_APP_UPLOAD_URL)) {
        // set timeout to delete after updated in db
        setTimeout(() => {
          this.$store.dispatch(`file/${DELETE_FILE}`, {fileName: imageUrl.split('/').pop()});
        }, 2000);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.image-uploader-wrapper {
  & .upload-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
    border-radius: 4px;
    transition: all .1s ease-in-out;
  }

  &:hover .upload-preview {
    opacity: 1;
    background-color: var(--color-neutral-gray-base-700);
  }
}
</style>
