<template>
  <a-button
      :disabled="disabled"
      :ghost="ghost"
      :icon="icon"
      :loading="loading"
      :size="size"
      :type="type"
      :block="block"
      :shape="shape"
      :htmlType="htmlType"
      :class="btnClass"
      @click="click"
  >
    <slot></slot>
  </a-button>
</template>

<script>
export default {
  name: "VButton",
  props: {
    disabled: {type: Boolean, default: false},
    ghost: {type: Boolean, default: false},
    icon: {type: String},
    loading: {type: [Boolean, Object], default: false},
    size: {type: String, default: 'default'},
    type: {type: String, default: 'default'},
    block: {type: Boolean, default: false},
    shape: {type: String},
    htmlType: {type: String, default: 'button'},
  },
  computed: {
    btnClass: function () {
      const baseClassPrefix = 'ms-btn';
      const antBtnClass = {
        default: 'ant-btn-default',
        primary: 'ant-btn-primary',
        ghost: 'ant-btn-ghost ant-btn-background-ghost',
      };

      let _class = `${baseClassPrefix}-${this.type}`;

      switch (this.type) {
        case 'success':
          _class = `${antBtnClass.primary} ${_class}`;
          break;
        case 'warning':
          _class = `${antBtnClass.primary} ${_class}`;
          break;
        case 'negative':
          _class = `${antBtnClass.primary} ${_class}`;
          break;
        case 'ghost-negative':
          _class = `${antBtnClass.ghost} ${_class}`;
          break;
        case 'default-danger':
          _class = `${antBtnClass.default} ${_class}`;
          break;
        case 'default-negative':
          _class = `${antBtnClass.default} ${_class}`;
          break;
        case 'default-success':
          _class = `${antBtnClass.default} ${_class}`;
          break;
        case 'default-negative-hover':
          _class = `${antBtnClass.default} ${_class}`;
          break;
        case 'default-primary':
          _class = `${antBtnClass.default} ${_class}`;
          break;
        case 'violet':
          _class = `${antBtnClass.primary} ${_class}`;
      }

      return _class;
    }
  },
  methods: {
    click() {
      this.$emit('click');
    }
  }
}
</script>

<style lang="less" scoped>
.ms-btn {
  line-height: 20px;

  &-default {
    background: transparent;
    color: @v-color-gray-500;

    &:hover,
    &:focus {
      background: transparent;
      color: @v-color-blue-500;
    }

    &-primary {
      background: transparent;
      color: @v-color-blue-500;
      border-color: @v-color-blue-500;

      &:hover,
      &:focus {
        background: transparent;
        .lighter(border-color, @v-color-blue-500, 2);
        .lighter(color, @v-color-blue-500, 2);
      }
    }

    &-danger {
      background: transparent;
      color: @v-color-magenta-500;
      border-color: @v-color-magenta-500;

      &:hover,
      &:focus {
        background: transparent;
        .lighter(border-color, @v-color-magenta-500, 4);
        .lighter(color, @v-color-magenta-500, 4);
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus {
        background: transparent;
        .lighter(border-color, @v-color-magenta-500, 4);
        .lighter(color, @v-color-magenta-500, 4);
      }
    }

    &-negative {
      background: transparent;
      color: @v-color-negative;
      border-color: @v-color-negative;

      &:hover,
      &:focus {
        background: transparent;
        .lighter(border-color, @v-color-negative, 4);
        .lighter(color, @v-color-negative, 4);
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus {
        background: transparent;
        .lighter(border-color, @v-color-negative, 4);
        .lighter(color, @v-color-negative, 4);
      }
    }

    &-success {
      background: transparent;
      color: @v-color-positive;
      border-color: @v-color-positive;

      &:hover,
      &:focus {
        background: transparent;
        .lighter(border-color, @v-color-positive, 3);
        .lighter(color, @v-color-positive, 3);
      }

      &[disabled],
      &[disabled]:hover,
      &[disabled]:focus {
        background: transparent;
        .lighter(border-color, @v-color-positive, 3);
        .lighter(color, @v-color-positive, 3);
      }
    }

    &-negative-hover {
      color: @v-color-gray-500;
      background-color: transparent;

      &:hover,
      &:focus {
        color: @v-color-negative;
        border-color: @v-color-negative;
        background-color: transparent;
      }

      &:after {
        display: none;
      }
    }
  }

  &-ghost {
    color: @v-color-gray-400;
    background-color: transparent;
    box-shadow: none;
    border: 0;

    &:hover,
    &:focus {
      border: 0;
    }

    &-negative {
      color: @v-color-gray-400;
      background-color: transparent;
      box-shadow: none;
      border: 0;

      &:hover {
        border: 0;
        color: @v-color-negative;
      }

      &:focus {
        border: 0;
        color: @v-color-gray-400;
      }

      &:after {
        display: none;
      }
    }
  }

  &-link {
    padding: 0;
    height: auto;
    line-height: 1;
    border-bottom: 1px dashed @v-color-blue-500;
    border-radius: 0;

    &[disabled] {
      border-bottom: 1px dashed @v-color-neutral-gray-300;
    }

    &.gray {
      color: @v-color-gray-500;
      border-bottom: 1px dashed @v-color-gray-500;
      transition: all .1s ease-in;
    }

    &.gray:hover {
      opacity: .7;
    }

    &.link-default {
      color: @v-color-gray-400;
      border: 0;
      transition: all .1s ease-in;
    }

    &.link-default:hover {
      color: @v-color-blue-500;
    }
  }

  &-success {
    background: @v-color-button-green;
    text-transform: uppercase;
    font-weight: 700;
    border: 0;

    &:hover,
    &:focus {
      .lighter(background, @v-color-positive, 2);
    }
  }

  &-violet {
    background: @v-color-button-violet;
    font-weight: 600;
    border: 0;

    &:hover,
    &:focus {
      .lighter(background, @v-color-violet-500, 2);
    }
  }

  &-warning {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(179, 64, 0, 0.4) 100%), #FAAD14;
    font-weight: 600;
    border: 0;

    &:hover,
    &:focus {
      .lighter(background, #FAAD14, .5);
    }
  }

  &-negative {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(125, 0, 15, 0.4) 100%), #F4354C;
    font-weight: 600;
    border: 0;

    &:hover,
    &:focus {
      .lighter(background, #F4354C, .5);
    }
  }

  &-danger {
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
      background: fade(@v-color-magenta-500, 90%);
      border-color: @v-color-magenta-500;
      color: @v-color-base-light;
    }
  }
}
</style>
