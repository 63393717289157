<template>
    <div>
        <SurveyAnalyticComments/>
    </div>
</template>

<script>
	import {FETCH_COMMENTS} from "@/store/actions.type";
	import SurveyAnalyticComments from "@/components/SurveyEdit/Analytic/SurveyAnalyticComments";

	export default {
		name: "SurveyEditComment",
        components: {SurveyAnalyticComments},
		mounted() {
			this.$store.dispatch(`survey/${FETCH_COMMENTS}`, {survey_id: this.$route.params.id})
		}
	}
</script>

<style scoped>

</style>