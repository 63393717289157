<template>
  <iframe id="metasurvey-iframe"
          :src="surveySrc"
          style="width: 100%; height: 100vh; border: none;"></iframe>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ChurnSurvey",
  computed: {
    ...mapGetters('auth', ["user"]),
    surveySrc() {
      return `${this.$constants('LINK_CHURN_SURVEY')}/?email=${this.user?.email}`;
    },
  }
}
</script>

<style scoped>

</style>
