<template>
  <div class="m-auto max-w-screen-xl">
    <div class="space-x-4 text-sm">
      <a :href="$constants('LINK_PRIVACY_POLICY')" target="_blank" class="text-character_gray-200">
        Privacy Policy
      </a>
      <a :href="$constants('LINK_TERMS_OF_SERVICE')" target="_blank" class="text-character_gray-200">
        Terms of Service
      </a>
      <a-divider type="vertical"/>
      <a
          href="mailto:hi@getmetasurvey.com"
          class="text-character_gray-200"
          @click="$posthog.capture('Clicked on Support Email')">
        hi@getmetasurvey.com
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheFooter",
}
</script>
