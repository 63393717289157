<template>
  <VAuthBlock :title="resetSuccess ? '✅ Password updated' : 'Set a new password'">
    <div v-if="!resetSuccess">
      <a-form :form="form" @submit="reset">
        <a-form-item :validate-status="passwordError() ? 'error' : ''" :help="passwordError() || ''">
          <a-input-password
              size="large"
              placeholder="New password"
              v-decorator="['password', { rules: [{ required: true, message: 'Input a new password' }, {min: 8, message: 'Min. 8 characters'}, {validator: validateToNextPassword}] }]"
          >
            <a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
          </a-input-password>
        </a-form-item>
        <a-form-item :validate-status="confirmError() ? 'error' : ''" :help="confirmError() || ''">
          <a-input-password
              size="large"
              placeholder="Confirm password"
              v-decorator="['confirm', { rules: [{ required: true, message: 'Confirm a password' }, {validator: compareToFirstPassword}] }]"
              @blur="handleConfirmBlur"
          >
            <a-icon slot="prefix" type="lock" style="color: var(--color-gray-300)"/>
          </a-input-password>
        </a-form-item>
        <a-form-item style="margin-bottom: 0;">
          <div class="flex justify-between gap-8">
            <a-button
                type="primary"
                size="large"
                class="btn-action"
                html-type="submit"
                :disabled="hasErrors(form.getFieldsError())"
                :loading="isLoading"
            >
              Set new password
            </a-button>
            <div class="flex items-center justify-center">
              <router-link :to="{name: 'Login'}">
                <VButton type="link" size="large">Cancel</VButton>
              </router-link>
            </div>
          </div>
        </a-form-item>
      </a-form>
    </div>
    <div v-else class="flex justify-center text-base">
      <router-link :to="{name: 'Login'}">
        <a-button
            type="primary"
            size="large"
            class="btn-action"
        >
          Go to login
        </a-button>
      </router-link>
    </div>
  </VAuthBlock>
</template>

<script>
import VAuthBlock from "@/components/VAuthBlock";
import hasErrors from "@/helpers/formValidate";
import {mapGetters} from "vuex";
import {RESET_PASSWORD} from "@/store/actions.type";
import VButton from "../components/VButton";

export default {
  name: "ResetPassword",
  components: {VAuthBlock, VButton},
  data() {
    return {
      resetSuccess: false,
      confirmDirty: false,
      hasErrors,
      form: this.$form.createForm(this, {name: 'reset_password_form'}),
    };
  },
  mounted() {
    this.$nextTick(() => {
      // To disabled submit button at the beginning.
      this.form.validateFields();
    });
  },
  computed: {
    ...mapGetters('auth', ['isLoading'])
  },
  methods: {
    // Only show error after a field is touched.
    passwordError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('password') && getFieldError('password');
    },
    // Only show error after a field is touched.
    confirmError() {
      const {getFieldError, isFieldTouched} = this.form;
      return isFieldTouched('confirm') && getFieldError('confirm');
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue('password')) {
        callback('Passwords do not match');
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(['confirm'], {force: true});
      }
      callback();
    },
    reset(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          //Get reset token
          values.token = this.$route.params.token;
          this.$store.dispatch(`auth/${RESET_PASSWORD}`, values)
              .then(() => {
                this.resetSuccess = true;
              })
              .catch(() => {
                this.resetSuccess = false;
              })
        }
      });
    },
  },
}
</script>

<style scoped>

</style>
