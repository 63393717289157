<template>
  <div>
    <div class="flex items-center space-x-1" v-for="(feature, index) in features" :key="index">
      <a-icon :type="feature.isAvailable ? 'check' : 'clock-circle'"
              :class="feature.isAvailable ? 'text-positive' : 'text-warning-500'"/>
      <span>{{ feature.name }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "VPlusAddonFeatures",

  data() {
    return {
      features: [
        {name: 'Remove Character Limit', isAvailable: true},
        {name: 'Direct Google Sheets Integration', isAvailable: true},
        {name: '10 Custom Domains + SSL certs', isAvailable: true},
        {name: 'Unlimited Folders (Workspaces)', isAvailable: true},
        {name: 'Invite Collaborators', isAvailable: true},
      ]
    }
  },
}
</script>

<style scoped>

</style>
