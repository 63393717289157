<template>
  <transition
      enter-active-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="opacity-100"
      leave-to-class="opacity-0"
  >
    <div class="transition-opacity ease-in-out duration-300" v-show="survey.comment.active">
      <div class="flex flex-grow">
        <div class="w-32 text-center flex-none text-right">
          <h4 class="text-xl font-light mr-3">Title:</h4>
        </div>
        <div class="flex-grow">
          <VTextArea :max-length="questionMaxLength"
                     :count-typed-symbols="survey.comment.title.length"
                     :has-upgrade="true">
            <a-textarea :placeholder="default_messages.title"
                        :max-length="questionMaxLength"
                        :auto-size="{ minRows: 2 }"
                        v-model="survey.comment.title"
            />
          </VTextArea>
        </div>
      </div>
      <div class="flex flex-grow mt-3">
        <div class="w-32 text-center flex-none text-right">
          <h4 class="text-xl font-light mr-3">Placeholder:</h4>
        </div>
        <div class="flex-grow">
          <VTextArea :max-length="35" :count-typed-symbols="survey.comment.placeholder.length">
            <a-input :placeholder="default_messages.placeholder"
                     size="large"
                     :maxLength="35"
                     v-model="survey.comment.placeholder"
            />
          </VTextArea>
        </div>
      </div>
      <div class="flex flex-grow mt-3">
        <div class="w-32 text-center flex-none text-right">
          <h4 class="text-xl font-light mr-3">Button label:</h4>
        </div>
        <div class="w-48">
          <VTextArea :max-length="16" :count-typed-symbols="survey.translations.comment.buttonSubmit.value.length">
            <a-input :placeholder="survey.translations.comment.buttonSubmit.defaultValue"
                     size="large"
                     :maxLength="16"
                     v-model="survey.translations.comment.buttonSubmit.value"
            />
          </VTextArea>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import VTextArea from "@/components/VTextArea";
import {mapGetters} from "vuex";

export default {
  name: "QuestionComment",
  components: {VTextArea},
  data() {
    return {
      default_messages: {
        title: 'Tell us what you think',
        placeholder: 'Your feedback here',
        btnLabel: 'Send',
      }
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 100;
    }
  }
}
</script>
