<template>
  <a-form-model
      ref="inviteForm"
      :model="inviteForm"
      layout="inline"
      class="invite-form"
      @submit.prevent="onInvite">
    <a-form-model-item
        prop="email"
        :rules="[{type: 'email', message: 'Input valid email', trigger: [null]}, { required: true, message: 'Input email', trigger: [null]}]">
      <a-input
          v-model="inviteForm.email"
          placeholder="Input email"
          size="large"
          :disabled="isInviting">
        <PermissionSelect
            slot="addonAfter"
            :default-value="inviteForm.permission"
            :loading="isInviting"
            :permissions="Object.values(permissions)"
            @select="onSelect"/>
      </a-input>
    </a-form-model-item>
    <a-form-model-item>
      <VButton html-type="submit" type="primary" size="large" :loading="isInviting">
        Invite
        <a-icon type="arrow-right"/>
      </VButton>
    </a-form-model-item>
  </a-form-model>
</template>

<script>
import VButton from "../VButton";
import {mapGetters} from "vuex";
import PermissionSelect from "./PermissionSelect";

export default {
  name: "InviteForm",
  components: {VButton, PermissionSelect},
  data() {
    return {
      inviteForm: {
        email: "",
        permission: "admin",
      },
      isInviting: false,
    };
  },
  computed: {
    ...mapGetters("collaborators", ["permissions", "modal"]),
  },
  methods: {
    onSelect(permission) {
      this.inviteForm.permission = permission;
    },
    onInvite() {
      this.$refs.inviteForm.validate(valid => {
        if (!valid) return false;

        this.isInviting = true;

        this.$store.dispatch("collaborators/invite", {
          email: this.inviteForm.email,
          permission: this.inviteForm.permission,
          surveyId: this.modal.surveyId,
          folderId: this.modal.folderId,
        })
            .then(() => {
              this.$message.success("Invitation sent");
              this.$refs.inviteForm.resetFields();
            })
            .finally(() => {
              this.isInviting = false;
            });
      });
    },
  }
}
</script>

<style lang="less">
.invite-form {
  display: flex;
  width: 100%;

  & .ant-form-item:first-child {
    flex-grow: 1;

    & .ant-col {
      width: 100%;
    }
  }

  & .ant-form-item:last-child {
    margin-right: 0;
  }

  &.ant-form-inline .ant-form-item-with-help {
    margin-bottom: 0;
  }
}
</style>
