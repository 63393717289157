export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const RECOVER_PASSWORD = "recoverPassword";
export const RESET_PASSWORD = "resetPassword";
export const CONFIRM_EMAIL = "confirmEmail";
export const FETCH_SURVEYS = "fetchSurveys";
export const FETCH_SURVEY = "fetchSurvey";
export const SURVEY_EDIT = "editSurvey";
export const SURVEY_CREATE = "createSurvey";
export const SURVEY_CLONE = "cloneSurvey";
export const SURVEY_DELETE = "deleteSurvey";
export const FETCH_QUESTIONS = "fetchQuestions";
export const QUESTION_CREATE = "createQuestion";
export const QUESTION_EDIT = "editQuestion";
export const QUESTION_DELETE = "deleteQuestion";
export const QUESTIONS_UPDATE_POSITION = "updatePositionQuestions";
export const FETCH_RESPONSES = "fetchResponses";
export const FETCH_COMMENTS = "fetchComments";
export const FETCH_QUESTION_CHOICES = "fetchQuestionChoices";
export const CHOICE_CREATE = "createChoice";
export const CHOICE_UPDATE = "updateChoice";
export const CHOICE_DELETE = "deleteChoice";
export const CHOICES_UPDATE_POSITION = "updatePositionChoices";
export const FETCH_PLANS = "fetchPlans";
export const CANCEL_SUBSCRIPTION = "cancelSubscription";
export const SWITCH_SUBSCRIPTION = "switchSubscription";
export const PAUSE_SUBSCRIPTION = "pauseSubscription";
export const RENEW_SUBSCRIPTION = "renewSubscription";
export const VALIDATE_COUPON = "validateCoupon";
export const EXPORT_RESULTS = "exportResults"
export const FETCH_TEMPLATES = "fetchTemplates"
export const GET_AUTH_GOOGLE_URL = "getAuthGoogleUrl"
export const SET_AUTH_GOOGLE = "setAuthGoogle"
export const IMPORT_QUESTIONS_FROM_CSV = "importQuestionsFromCsv"
export const IMPORT_QUESTIONS_IMAGES = "importQuestionsImages"
export const FETCH_LAST_UPDATES = "fetchLastUpdates"
export const VERIFY_DOMAIN = "verifyDomain"
export const DELETE_DOMAIN = "deleteDomain"
export const VERIFY_WEBHOOK = "verifyWebhook"
export const FETCH_OPEN_RESPONSES = "fetchOpenResponses"
export const GET_PAY_LINK = "getPayLink"
export const UPDATE_USER_SETTINGS = "updateUserSettings"
export const ACTIVATE_CODE = "activateCode"
export const FETCH_ACTIVATED_CODES = "fetchActivatedCodes"
export const FETCH_THEMES = "fetchThemes"
export const CREATE_THEME = "createTheme"
export const UPDATE_THEME = "updateTheme"
export const DELETE_THEME = "deleteTheme"
export const DUPLICATE_THEME = "duplicateTheme"
export const FETCH_SCREENS = "fetchScreens"
export const CREATE_SCREEN = "createScreen"
export const UPDATE_SCREEN = "updateScreen"
export const DELETE_SCREEN = "deleteScreen"
export const DUPLICATE_SCREEN = "duplicateScreen"
export const DELETE_FILE = "deleteFile"
export const RESET_RESULTS = "resetResults"
export const GET_GOOGLE_CONNECT_URL = "getGoogleConnectUrl"
export const CONNECT_WITH_GOOGLE = "connectWithGoogle"
export const CONNECTION_DISCONNECT = "connectionDisconnect"
export const CREATE_FOLDER = "createFolder"
export const UPDATE_FOLDER = "updateFolder"
export const DELETE_FOLDER = "deleteFolder"
export const CALCULATED_FIELD_CREATE = "createCalculatedField"
