export const embedMixin = {
  computed: {
    embedScript: function () {
      let embedScript = document.createElement('script')
      embedScript.src = this.$constants("EMBED_SCRIPT_URL")

      return embedScript.outerHTML
    },
  }
}
