import ApiService from "../common/api.service";
import {DELETE_FOLDER_MUTATION, DELETE_SURVEY_MUTATION} from "./mutations.type";

function generateUrl(payload, endpoint) {
  if (payload.folderId) {
    return `${process.env.VUE_APP_API_URL}/admin/folder/${payload.folderId}/${endpoint}`;
  } else if (payload.surveyId) {
    return `${process.env.VUE_APP_API_URL}/admin/survey/${payload.surveyId}/${endpoint}`;
  }
}

const state = {
  modal: {
    isVisible: false,
    folderId: null,
    surveyId: null,
  },
  permissions: {
    admin: {
      value: 'admin',
      label: 'Full Access',
      description: "Can edit and invite",
    },
    edit: {
      value: 'edit',
      label: 'Can Edit',
      description: "Can edit, but not invite"
    },
    view: {
      value: 'view',
      label: 'Can View',
      description: "Cannot edit and invite"
    }
  }
}

const getters = {
  modal: state => state.modal,
  permissions: state => state.permissions,
  getPermissionByValue(state) {
    return (value) => {
      return Object.values(state.permissions).find(p => p.value === value) || {}
    }
  },
  listCollaborators(state, getters, rootState, rootGetters) {
    return ({surveyId, folderId}) => {
      return (surveyId ? rootGetters["home/getSurveyById"](surveyId)?.collaborators || rootGetters["survey/survey"]?.collaborators : rootGetters["home/getFolderById"](folderId)?.collaborators) || [];
    }
  },
  getCollaborator(state, getters, rootState, rootGetters) {
    return ({surveyId, folderId}) => {
      const collaborators = getters.listCollaborators({surveyId, folderId});
      return collaborators?.find(c => c.email === rootGetters['auth/user'].email) || {};
    }
  },
  canEdit(state, getters) {
    return ({surveyId, folderId}) => {
      let collaborator = {};
      if (surveyId) {
        collaborator = getters.getCollaborator({surveyId}) || {};
      }
      if (!collaborator.permission && folderId) {
        collaborator = getters.getCollaborator({folderId}) || {};
      }

      const {isOwner, permission} = collaborator;
      return isOwner || permission === state.permissions.admin.value || permission === state.permissions.edit.value;
    }
  },
  canInvite(state, getters) {
    return ({surveyId, folderId}) => {
      const {isOwner, permission} = getters.getCollaborator({surveyId, folderId}) || {};
      return isOwner || permission === state.permissions.admin.value;
    }
  },
}

const actions = {
  invite({commit}, payload) {
    const {folderId, surveyId} = payload;
    const url = generateUrl(payload, 'collaborators');

    return new Promise((resolve, reject) => {
      ApiService.post(url, payload)
        .then((collaborator) => {
          if (folderId) {
            commit('home/addCollaboratorToFolder', {folderId, collaborator}, {root: true});
          } else if (surveyId) {
            commit('home/addCollaboratorToSurvey', {surveyId, collaborator}, {root: true});
            commit('survey/addCollaborator', {surveyId, collaborator}, {root: true});
          }
          resolve(collaborator);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  resendInvitation(context, payload) {
    const url = generateUrl(payload, `collaborator/${payload.collaboratorId}/resend_invitation`);

    return new Promise((resolve, reject) => {
      ApiService.post(url, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  acceptInvitation(context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/collaborators/accept_invitation`, payload)
        .then((collaborator) => {
          resolve(collaborator);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  removeAccess({commit}, payload) {
    const {collaboratorId, folderId, surveyId} = payload;
    const url = generateUrl(payload, `collaborator/${collaboratorId}`);

    return new Promise((resolve, reject) => {
      ApiService.destroy(url, payload)
        .then(() => {
          if (folderId) {
            commit('home/removeCollaboratorFromFolder', {folderId, collaboratorId}, {root: true});
          } else if (surveyId) {
            commit('home/removeCollaboratorFromSurvey', {surveyId, collaboratorId}, {root: true});
            commit('survey/removeCollaborator', {surveyId, collaboratorId}, {root: true});
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  updatePermission({commit}, payload) {
    const {collaboratorId, folderId, surveyId} = payload;
    const url = generateUrl(payload, `collaborator/${collaboratorId}`);

    return new Promise((resolve, reject) => {
      ApiService.update(url, payload)
        .then((collaborator) => {
          if (folderId) {
            commit('home/updateCollaboratorInFolder', {folderId, collaborator}, {root: true});
          } else if (surveyId) {
            commit('home/updateCollaboratorInSurvey', {surveyId, collaborator}, {root: true});
            commit('survey/updateCollaborator', {surveyId, collaborator}, {root: true});
          }
          resolve(collaborator);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  leave({commit}, payload) {
    const {collaboratorId, folderId, surveyId} = payload;
    const url = generateUrl(payload, `collaborator/${collaboratorId}/leave`);

    return new Promise((resolve, reject) => {
      ApiService.destroy(url, payload)
        .then(() => {
          if (folderId) {
            commit(`home/${DELETE_FOLDER_MUTATION}`, folderId, {root: true});
          } else if (surveyId) {
            commit(`home/${DELETE_SURVEY_MUTATION}`, surveyId, {root: true});
          }
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const mutations = {
  setModal(state, payload) {
    state.modal = payload;
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
