<template>
  <div>
    <div class="flex items-start">
      <VUpload v-if="question.subtype === 'textWithImage'"
               class="mr-8 flex-none"
               uploader-type="question"
               :image-src.sync="question.imageSrc"
               dimensions="800 x 600"
      />
      <div class="flex-grow">
        <VTextArea :max-length="questionMaxLength"
                   :count-typed-symbols="question.text.length"
                   :has-upgrade="true">
          <a-textarea placeholder="Your question here"
                      :max-length="questionMaxLength"
                      :auto-size="{ minRows: 3 }"
                      v-model="question.text"
          />
        </VTextArea>
        <div v-if="question.imageSrc" class="flex items-center">
          <a-switch class="switch_positive-color"
                    checked-children="On"
                    un-checked-children="Off"
                    v-model="isTextOnly"
          />
          <span class="ml-3 mr-1 font-light text-base">Hide image</span>
          <a-tooltip>
            <template slot="title">
              Display text only.
            </template>
            <a-icon type="info-circle" class="icon-info"/>
          </a-tooltip>
        </div>
      </div>
    </div>

    <a-divider style="margin-top: 32px; margin-bottom: 16px; border-color: var(--color-gray-300)"/>

    <div class="extra-collapse">
      <a-collapse :bordered="false" expandIconPosition="right">

        <template #expandIcon="props">
          <a-icon type="down"
                  :rotate="props.isActive ? 180 : 0"
                  style="color: var(--color-text-description); font-size: 16px;"/>
        </template>

        <a-collapse-panel key="1">

          <template slot="header">
            <div class="flex items-center space-x-1.5 text-xl leading-none">
              <VIcon name="translation" style="color: var(--color-text-secondary); font-size: 24px;"/>
              <span class="text-character_gray-400 border-0 border-b border-dashed border-character_gray-400">
                  Change button labels
                </span>
            </div>
          </template>

          <div class="flex flex-wrap gap-x-8 gap-y-4 pt-6">
            <VSwitchInput input-name="Left button"
                          input-info-html="You can change the <b>left button</b> label."
                          input-info-icon="tooltip-question-swipe-left-button"
                          :input-length="10"
                          input-width="168px"
                          :input-placeholder="translationPlaceholder.left"
                          :input-value.sync="question.translations.leftButton.value"
            />

            <VSwitchInput input-name="Right button"
                          input-info-html="You can change the <b>right button</b> label."
                          input-info-icon="tooltip-question-swipe-right-button"
                          :input-length="10"
                          input-width="168px"
                          :input-placeholder="translationPlaceholder.right"
                          :input-value.sync="question.translations.rightButton.value"
            />
          </div>

        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>

<script>
import VUpload from "@/components/VUpload";
import VTextArea from "@/components/VTextArea";
import questionMixin from "@/mixins/question";
import VIcon from "../../../VIcon";
import VSwitchInput from "../../../VSwitchInput";
import {mapGetters} from "vuex";

export default {
  name: "QuestionSwipe",

  props: ["question"],

  components: {
    VUpload,
    VTextArea,
    VIcon,
    VSwitchInput,
  },

  mixins: [questionMixin],

  watch: {
    isTextOnly: function () {
      this.question.subtype = this.isTextOnly ? 'textOnly' : 'textWithImage';
    },
  },

  data() {
    return {
      isTextOnly: this.question.subtype === 'textOnly',
    }
  },

  computed: {
    ...mapGetters('survey', ['survey']),
    translationPlaceholder: function () {
      const lBtn = this.survey.translations.questions.swipe.leftButton
      const rBtn = this.survey.translations.questions.swipe.rightButton

      return {
        left: lBtn.value || lBtn.defaultValue,
        right: rBtn.value || rBtn.defaultValue,
      }
    },
    questionMaxLength: function () {
      return this.survey.features.unlimit_text ? null : 100;
    }
  },
}
</script>
