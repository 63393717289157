<template>
  <div class="ml-5 -mt-1">
    <a-dropdown :trigger="['click']" :disabled="disabled">
      <a-menu slot="overlay" @click="onChangeQuestionType">
        <a-menu-item v-for="(type, key) in questionTypesComputed" :key="key">
          {{ type.typeName }}
        </a-menu-item>
      </a-menu>
      <a-tooltip class="flex items-end">
        <div class="flex items-center">
          <div
              class="question-type-selected flex cursor-pointer border-0 border-b border-dashed leading-none"
              :class="{'opacity-50': disabled}">
            {{ questionTypes[questionTypeSelected].typeName }}
          </div>
          <span
              v-if="!disabled"
              class="question-type-selected flex cursor-pointer pl-1"
          >
						<a-icon type="down"/>
					</span>
        </div>
        <template v-if="disabled" slot="title">
          Type change is disabled because answers are already collecting on this question.
        </template>
        <a-icon v-if="disabled" type="lock" class="icon-info ml-1"></a-icon>
      </a-tooltip>
    </a-dropdown>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "VQuestionTypeChange",
  props: ['questionTypeSelected', 'questionTypes', 'disabled'],
  computed: {
    questionTypesComputed: function () {
      return _.pickBy(this.questionTypes, (type) => type.isQuestion)
    },
  },
  methods: {
    onChangeQuestionType(questionType) {
      this.$emit('makeChangeQuestionType', questionType.key)
    }
  }
}
</script>
