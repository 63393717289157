<template>
  <div>
    <div class="text-base mb-8">
      <p>
        Add URLs for receiving webhooks. You can add up to <b>{{ maxEndpointCount }}</b> endpoints to receive the events
        when someone
        completes your survey.
      </p>
      <p>
        <VLearnMore :link="$constants('LINK_HELP_WEBHOOKS')"/>
      </p>
    </div>


    <div class="space-y-7">
      <div>
        <p class="text-lg font-light mb-4">Endpoints:</p>
        <a-form-model ref="endpointsForm"
                      :model="endpointsForm"
                      class="webhook-form"
        >
          <a-form-model-item
              v-for="(endpoint, index) in webhooks"
              :key="index"
              :prop="'endpoints.' + index + '.value'"
              :rules="{validator: validateEndpoint, trigger: [null]}"
              has-feedback
          >
            <div class="flex items-center space-x-3">
              <a-switch class="switch_positive-color"
                        checked-children="On"
                        un-checked-children="Off"
                        v-model="endpoint.active"
              />
              <a-input placeholder="https://yourwebsite.com/webhook"
                       size="large"
                       v-model="endpoint.value"
                       :disabled="!endpoint.active"
              />
              <VButton type="default"
                       size="large"
                       style="height: 40px; min-width: 96px;"
                       @click="testEndpoint(index)"
                       :disabled="!endpoint.active"
              >
                Test
              </VButton>
              <div>
                <a-popconfirm
                    title="Are you sure delete this endpoint?"
                    ok-text="Delete"
                    cancel-text="No, cancel"
                    overlayClassName="danger-confirm"
                    @confirm="deleteEndpoint(endpoint)"
                >
                  <VButton type="default-negative-hover"
                           size="large"
                           icon="delete"
                           style="height: 40px; width: 40px;"
                           class="ml-1"
                           :disabled="webhooks.length === 1"
                  />
                </a-popconfirm>
              </div>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>

      <a-button type="default"
                block
                size="large"
                icon="plus"
                class="btn-add"
                :disabled="webhooks.length >= maxEndpointCount"
                @click="addEndpoint"
      >
        Add Endpoint
      </a-button>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "../../../VButton";
import {VERIFY_WEBHOOK} from "../../../../store/actions.type";
import VLearnMore from "../../../VLearnMore";

export default {
  name: "WebhooksConnection",
  components: {VButton, VLearnMore},
  mounted() {
    this.webhooks = this.survey.connections.filter(c => c.app === this.$constants("WEBHOOK"))
  },
  data() {
    return {
      webhooks: [],
      maxEndpointCount: 5,
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
    endpointsForm() {
      return {endpoints: this.webhooks}
    },
  },
  methods: {
    addEndpoint() {
      if (this.webhooks.length >= this.maxEndpointCount) return;

      const newEndpoint = {app: this.$constants("WEBHOOK"), active: false, value: ''};
      this.webhooks.push(newEndpoint);
      this.survey.connections.push(newEndpoint); // update survey.connections
    },
    deleteEndpoint(item) {
      let index = this.webhooks.indexOf(item);
      if (index !== -1) {
        this.webhooks.splice(index, 1);

        // update survey.connections
        const connectionsIndex = this.survey.connections.indexOf(item);
        if (connectionsIndex !== -1) {
          this.survey.connections.splice(connectionsIndex, 1);
        }
      }
    },
    testEndpoint(index) {
      this.$refs.endpointsForm.validateField(`endpoints.${index}.value`);
    },
    validateEndpoint(rule, value, callback) {
      const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

      if (value === '') {
        callback(new Error('endpoint can not be null'));
      } else if (!httpRegex.test(value)) {
        callback(new Error('endpoint doesn\'t look like a URL'));
      } else {
        this.$store.dispatch(`survey/${VERIFY_WEBHOOK}`, {
          survey_id: this.$route.params.id,
          endpoint: value
        })
            .then(() => {
              callback()
              this.$posthog.capture('Tested Webhook Endpoint', {surveyId: this.$route.params.id, endpoint: value});
            })
            .catch(e => {
              callback(e)
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
