export const UpgradeMixin = {
  methods: {
    setupPlusAddonCheckout() {
      return new Promise((resolve, reject) => {
        const paddle = window.Paddle;

        if (process.env.NODE_ENV === "dev") {
          paddle.Environment.set('sandbox');
        }

        paddle.Setup({
          vendor: Number(process.env.VUE_APP_PADDLE_VENDOR_ID)
        });

        const key = 'plus-addon-activating';

        paddle.Checkout.open({
          product: Number(process.env.VUE_APP_PADDLE_PLUS_ADDON_PLAN_ID),
          email: this.$store.getters["auth/user"]?.email,
          successCallback: () => {
            this.$message.loading({
              content: 'Upgrading your plan, please hold on a moment...',
              duration: 0,
              key
            });

            setTimeout(() => {
              this.$message.success({
                content: 'Your plan has been successfully upgraded!',
                key
              });
              resolve();
              window.location.reload();
            }, 5000);
          },
          closeCallback: () => {
            reject();
          }
        });
      });
    },
  }
}
