<template>
  <a-alert v-if="alertData"
           :type="alertData.type"
           show-icon
  >
    <p slot="message" class="font-normal mb-2">{{ alertData.message }}</p>
    <div slot="description" class="font-light">
      <p>{{ alertData.description }}</p>
      <VButton type="primary" @click="$router.push({name: 'Billing'})">Upgrade plan</VButton>
    </div>
  </a-alert>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "../VButton";

export default {
  name: "VImportAlert",
  components: {VButton},
  props: {
    data: {required: true},
  },
  data() {
    return {
      warning: {
        type: "warning",
        message: "",
        description: "",
      }
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'questions_count']),
    maxQuestions: function () {
      return this.survey.features.questions_count
    },
    alertData: function () {
      const data = {
        warning: {
          type: "warning",
          message: "Looks like you are reaching the questions limit!",
          description: `Your current plan allows ${this.maxQuestions} questions for this survey. Only part of your questions will be uploaded.`,
        },
        error: {
          type: "error",
          message: "Questions limit reached",
          description: `Your current plan only allows ${this.maxQuestions} questions for this survey. Upgrade plan to import more questions.`,
        }
      }

      if (this.maxQuestions && this.questions_count >= this.maxQuestions) {
        return data.error
      } else if (this.data && this.maxQuestions && this.data.length > this.maxQuestions - this.questions_count) {
        return data.warning
      }

      return false
    }
  },
}
</script>

<style scoped>

</style>
