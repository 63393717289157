const ConstantsPlugin = {
    // Links
    LINK_TERMS_OF_SERVICE: 'https://getmetasurvey.com/terms-of-service/',
    LINK_PRIVACY_POLICY: 'https://getmetasurvey.com/privacy-policy/',
    LINK_POST_RATING_SCALES: 'https://getmetasurvey.com/blog/rating-scales/',
    LINK_BLOG: 'https://getmetasurvey.com/blog/',
    LINK_IMPORT_QUESTIONS_EXAMPLE_DOC: 'https://docs.google.com/spreadsheets/d/1U0cNTpdBVSRrOTrd1GU_JIlKdfQMc7z16pn2TvzvisM/edit?usp=sharing',
    LINK_ROADMAP: 'https://feedback.getmetasurvey.com/t/roadmap',
    LINK_CHANGELOG: 'https://feedback.getmetasurvey.com/t/changelog',
    LINK_FEATURE_REQUEST: 'https://feedback.getmetasurvey.com/new',
    LINK_LAST_POSTS: 'https://getmetasurvey.com/blog/',
    LINK_AFFILIATES: 'https://getmetasurvey.com/affiliates/',
    LINK_CHURN_SURVEY: 'https://public.getmetasurvey.com/survey/6544f14d8139690012766684',
    LINK_HELP_CENTER: 'https://getmetasurvey.com/help/',
    LINK_HELP_WEBHOOKS: 'https://getmetasurvey.com/help/webhooks/',
    LINK_HELP_PABBLY: 'https://getmetasurvey.com/help/pabbly/',
    LINK_HELP_GOOGLE_SHEETS: 'https://getmetasurvey.com/help/google-sheets/',
    LINK_HELP_INVITE_COLLABORATORS: 'https://getmetasurvey.com/help/invite-collaborators/',
    LINK_HELP_CUSTOM_DOMAIN: 'https://getmetasurvey.com/help/custom-domain/',
    LINK_HELP_CONDITIONAL_LOGIC: 'https://getmetasurvey.com/help/conditional-logic/',
    LINK_HELP_CALCULATED_FIELDS: 'https://getmetasurvey.com/help/calculated-fields/',
    LINK_REVIEW_ON_PRODUCTHUNT: 'https://www.producthunt.com/products/metasurvey/reviews/new',
    LINK_REVIEW_ON_APPSUMO: 'https://appsumo.8odi.net/Orj7Pz',

    // Embed script
    EMBED_SCRIPT_URL: process.env.VUE_APP_EMBED_SCRIPT_URL || 'https://embed.getmetasurvey.com/embed.js',

    // Plan names
    PLAN_FREE: 'free',
    PLAN_PRO: 'basic',
    PLAN_PRO_PLUS: 'pro',
    PLAN_PRO_MONTHLY: 'pro_monthly',
    PLAN_PRO_ANNUAL: 'pro_annual',

    // Subscription statuses
    SUBSCRIPTION_ACTIVE: 'active',
    SUBSCRIPTION_PAUSED: 'paused',
    SUBSCRIPTION_TRIALING: 'trialing',
    SUBSCRIPTION_PAST_DUE: 'past_due',

    // Connections
    WEBHOOK: "webhook",
    PABBLY: "pabbly",
    GOOGLE_SHEETS: "google-sheets",
}

ConstantsPlugin.install = (Vue) => {
    Vue.prototype.$constants = (key) => {
        return ConstantsPlugin[key] || ''
    }
}

export default ConstantsPlugin
