<template>
  <a-modal
      v-model="isVisible"
      title="New Update 🎉"
      :maskClosable="false"
      :footer="null"
      :destroyOnClose="true"
      :bodyStyle="{paddingLeft: 0, paddingRight: 0}">

    <div class="px-6 mb-10">
      <div class="space-y-2 mb-5">
        <img
            v-if="lastUpdate.image"
            :src="lastUpdate.image"
            :alt="lastUpdate.title"
            class="w-full object-contain rounded mb-5">
        <div class="flex items-center justify-center flex-wrap">
          <a-tag color="rgba(57, 174, 97, 0.15)" class="text-sm text-positive">NEW</a-tag>
          <span class="font-semibold text-3xl leading-tight">{{ lastUpdate.title }}</span>
        </div>
        <div class="text-base text-center">
          {{ lastUpdate.description }}
        </div>
      </div>
      <div class="flex justify-center space-x-2">
        <a :href="lastUpdate.link" target="_blank">
          <VButton type="default" icon="info-circle" size="large">Learn more</VButton>
        </a>
        <VButton type="primary" icon="like" size="large" @click="onFeedback">
          Feedback
        </VButton>
      </div>
    </div>

    <a-divider/>

    <div class="px-6">
      <div class="text-gray-500 text-x mb-4">Previous updates:</div>
      <a-timeline>
        <a-timeline-item
            v-for="(update, index) in previousUpdates"
            :key="index"
            color="gray">
          <a-icon slot="dot" type="check-circle" style="font-size: 12px;"/>
          <a
              :href="update.link"
              target="_blank"
              class="text-character_gray-500">
            <span class="text-base leading-none">{{ update.title }}</span>
          </a>
          <div class="font-normal" style="font-size: 15px; color: rgba(51, 51, 51, 0.4);">
            {{ update.releasedAt | moment('ll') }}
          </div>
        </a-timeline-item>
      </a-timeline>
      <a
          :href="`${$constants('LINK_CHANGELOG')}?sso=${productLiftSsoToken}`"
          target="_blank"
          class="text-base space-x-1">
        <span>See more updates</span>
        <a-icon type="arrow-right"/>
      </a>
    </div>

  </a-modal>
</template>

<script>
import {mapGetters} from "vuex";
import {SET_LAST_UPDATES_SEEN} from "../../store/mutations.type";
import VButton from "../VButton";
import {FETCH_LAST_UPDATES} from "../../store/actions.type";
import {FeedbackMixin} from "../../mixins/feedback.mixin";
import {UpgradeMixin} from "../../mixins/upgrade.mixin";

export default {
  name: "NewFeatureModal",
  components: {VButton},
  mixins: [UpgradeMixin, FeedbackMixin],
  created() {
    this.$store.dispatch(`home/${FETCH_LAST_UPDATES}`);
  },
  computed: {
    ...mapGetters('auth', ['productLiftSsoToken']),
    ...mapGetters('home', ['latestUpdates', 'hasNewUpdates']),
    isVisible: {
      get() {
        return this.hasNewUpdates && this.latestUpdates.length > 0;
      },
      set() {
        return this.$store.commit(`home/${SET_LAST_UPDATES_SEEN}`);
      }
    },
    lastUpdate() {
      return this.latestUpdates[0] || {};
    },
    previousUpdates() {
      return this.latestUpdates.slice(1) || [];
    }
  }
}
</script>

<style scoped>

</style>
