<template>
  <div class="flex flex-col justify-center rounded border-1.5 border-solid border-gray-50 space-y-1.5 overflow-hidden"
       style="width: 150px; height: 100px;"
       :style="{backgroundColor: settings.bgColor}"
  >
    <div style="width: 40px; height: 4px; box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);"
         :style="{backgroundColor: settings.titleColor2}"
         class="mx-auto"></div>
    <div class="flex justify-center items-center space-x-2">
      <div style="width: 50px; height: 60px; border-radius: 2px; box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);"
           class="flex flex-col bg-white p-2">
        <div class="m-auto space-y-0.5">
          <div style="width: 24px; height: 3px;" :style="{backgroundColor: settings.textColor}" class="m-auto"></div>
          <div style="width: 16px; height: 3px;" :style="{backgroundColor: settings.textColor}" class="m-auto"></div>
        </div>
        <div style="height: 10px;" :style="{backgroundColor: settings.buttonBgColor}"
             class="flex w-full rounded-full">
          <div style="width: 12px; height: 2px;"
               :style="{backgroundColor: settings.buttonTextColor}"
               class="m-auto"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VSurveyThemeMiniPreview",
  props: {
    settings: {
      type: Object
    },
  }
}
</script>

<style scoped>

</style>
