<template>
    <VSurveyBlock class="mt-8">
        <template #title>Summary stats</template>
		<template #reload-btn>
			<a-button
				type="default"
				size="large"
				icon="reload"
				class="btn-action btn-action_ghost ml-5"
				:loading="isLoadingChild"
				@click="reloadResults"
			>
				Reload results
			</a-button>
		</template>
        <template #content>
            <div class="w-full flex items-stretch">
                <div
                        v-for="(item, index) in summary"
                        :key="index"
                        class="w-1/4 px-3 pt-1 pb-2 border-0 border-r border-solid border-gray-300 last:border-0"
                >
                    <div class="relative text-center">
                        <a-progress type="circle"
                                    :percent="item.percent"
                                    :strokeColor="item.color"
                                    :strokeWidth="7"
                                    status="normal"
                                    class="text-2xl"
                        >
                            <template v-if="item.slug !== 'completed_rate'" #format="percent">
                                <span>{{ percent = item.value }}</span>
                            </template>
                        </a-progress>
                        <div class="font-light text-lg mt-3">
                            <a-icon :type="item.icon" :style="`color: ${item.color}`" class="mr-1"/>
                            {{ item.name }}
                        </div>
                        <a-tooltip v-if="item.hint">
                            <template slot="title">
                                {{ item.hint }}
                            </template>
                            <a-icon type="info-circle" class="icon-info absolute top-0 right-0"/>
                        </a-tooltip>
                    </div>
                </div>
            </div>
        </template>
    </VSurveyBlock>
</template>

<script>
	import VSurveyBlock from "@/components/VSurveyBlock";
	import {mapGetters} from "vuex";
	import {FETCH_RESPONSES} from "@/store/actions.type";

	export default {
		name: "SurveyAnalyticSummary",
		components: {VSurveyBlock},
		computed: {
			...mapGetters('survey', ['responses_summary', 'isLoadingChild']),
			summary: function () {
				return [
					{
						name: "Total responses",
                        slug: "total",
						hint: "",
						value: this.responses_summary.total_cnt || 0,
						percent: this.responses_summary.total_cnt > 0 ? 100 : 0,
						color: "var(--color-magenta-500)",
						icon: 'file-text'
					},
					{
						name: "Completed surveys",
						slug: "completed_cnt",
						hint: "",
						value: this.responses_summary.completed_cnt || 0,
						percent: this.responses_summary.completion_rate,
						color: "var(--color-positive)",
						icon: 'check'
					},
					{
						name: "Completion Rate",
						slug: "completed_rate",
						hint: "The percentage of respondents that completed the entire survey.",
						value: null,
						percent: this.responses_summary.completion_rate,
						color: "var(--color-blue-500)",
						icon: 'fund'
					},
					{
						name: "Comments",
						slug: "comments",
						hint: "",
						value: this.responses_summary.comments_cnt || 0,
						percent: this.responses_summary.comments_cnt > 0 ? 100 : 0,
						color: "var(--color-gray-500)",
						icon: 'message'
					},
				]
			},
		},
		methods: {
			reloadResults() {
				this.$store.dispatch(`survey/${FETCH_RESPONSES}`, {survey_id: this.$route.params.id})

        this.$root.$emit('reloadResults')
			}
		}
	}
</script>

<style scoped>

</style>
