<template>
  <div class="mx-auto w-full pt-16 pb-40" style="max-width: 1134px;">

    <component :is="subscriptionComponent"/>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SubscriptionLifetime from "../components/Subscription/SubscriptionLifetime";
import SubscriptionProActive from "../components/Subscription/SubscriptionProActive";
import SubscriptionProCancelling from "../components/Subscription/SubscriptionProCancelling";
import SubscriptionProPaused from "../components/Subscription/SubscriptionProPaused";
import SubscriptionProPausing from "../components/Subscription/SubscriptionProPausing";
import SubscriptionProTrialing from "../components/Subscription/SubscriptionProTrialing";
import SubscriptionPastDue from "../components/Subscription/SubscriptionPastDue";

export default {
  name: "Subscription",

  computed: {
    ...mapGetters('auth', ["user", "isUserLifetime"]),
    subscriptionComponent: function () {
      if (this.isUserLifetime) {
        return SubscriptionLifetime
      }

      if (this.user.subscription.status === this.$constants('SUBSCRIPTION_TRIALING')) {
        return SubscriptionProTrialing
      }

      if (this.user.subscription.status === this.$constants('SUBSCRIPTION_PAST_DUE')) {
        return SubscriptionPastDue
      }

      if (this.user.subscription.status === this.$constants('SUBSCRIPTION_PAUSED')) {
        return SubscriptionProPaused
      }

      if (this.user.subscription.status === this.$constants('SUBSCRIPTION_ACTIVE') && this.user.subscription.pausedFrom) {
        return SubscriptionProPausing
      }

      if (this.user.subscription.cancelledFrom) {
        return SubscriptionProCancelling
      }

      return SubscriptionProActive
    }
  },
}
</script>
