import {mapGetters} from "vuex";
import _ from "lodash";
import getSymbolFromCurrency from "currency-symbol-map";

export const plansMixin = {
  computed: {
    ...mapGetters('billing', ["plans"]),

    plansComputed: function () {
      let plans;

      plans = _.map(this.plans, function (plan) {
        plan.responses = plan.features.responses_count >= 999999 ? 'Unlimited' : String(plan.features.responses_count);
        plan.currencySymbol = getSymbolFromCurrency(plan.currency) || plan.currency;

        return plan;
      });

      plans = _.sortBy(plans, ['name']);

      return plans;
    },
  },

}
