<template>
  <div>
    <div class="text-base mb-8">
      <p>
        Connect MetaSurvey with Google Sheets. This action will create a new spreadsheet, ensuring the privacy of your
        existing spreadsheets.
      </p>
      <p>
        <VLearnMore :link="$constants('LINK_HELP_GOOGLE_SHEETS')"/>
      </p>
    </div>


    <div v-if="connection.value" class="flex items-center space-x-3">
      <a-switch class="switch_positive-color"
                checked-children="On"
                un-checked-children="Off"
                v-model="connection.active"
      />
      <a-input size="large"
               :value="connection.value"
               :disabled="!connection.active"
               readonly
      />
      <VButton size="large"
               icon="link"
               style="height: 44px; min-width: 44px;"
               @click="onOpen"
      />
      <a-popconfirm
          title="Are you sure delete this connection?"
          ok-text="Delete"
          cancel-text="No, cancel"
          @confirm="onDelete"
          overlayClassName="danger-confirm"
      >
        <a-icon slot="icon" type="question-circle-o" style="color: var(--color-negative)"/>
        <VButton type="default-negative-hover"
                 size="large"
                 icon="delete"
                 style="height: 44px; min-width: 44px;"
        />
      </a-popconfirm>
    </div>
    <div class="space-y-4" v-else>
      <a-checkbox v-model="isSendExisting">
        <span class="font-light text-lg">Send existing results to spreadsheet</span>
      </a-checkbox>
      <VButton
          size="large"
          type="primary"
          ref="btnCopyCode"
          style="height: 40px; min-width: 96px;"
          class="btn-copy"
          :loading="isLoading"
          @click="onConnect"
      >
        Connect
      </VButton>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "../../../VButton";
import {CONNECT_WITH_GOOGLE, CONNECTION_DISCONNECT, GET_GOOGLE_CONNECT_URL} from "../../../../store/actions.type";
import VLearnMore from "../../../VLearnMore";

export default {
  name: "GoogleSheetsConnection",
  components: {VButton, VLearnMore},
  mounted() {
    this.connection = this.survey.connections.find(c => c.app === this.$constants("GOOGLE_SHEETS"))
  },
  beforeDestroy() {
    window.removeEventListener('message', this.handleGoogleConnect)
  },
  data() {
    return {
      connection: {},
      isSendExisting: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
  },
  methods: {
    async onConnect() {
      this.isLoading = true

      const url = await this.$store.dispatch(`survey/${GET_GOOGLE_CONNECT_URL}`, {survey_id: this.$route.params.id})

      window.open(url, "_blank")

      window.addEventListener('message', this.handleGoogleConnect)
    },
    async handleGoogleConnect(event) {
      if (event.origin !== process.env.VUE_APP_ORIGIN) return

      const data = event.data

      if (!data.surveyId || !data.code || data.surveyId !== this.$route.params.id) {
        this.isLoading = false
        return false
      }

      this.$store.dispatch(`survey/${CONNECT_WITH_GOOGLE}`, {
        code: data.code,
        survey_id: data.surveyId,
        isSendExisting: this.isSendExisting,
      })
          .catch(() => {
            const key = "google-sheets-failed";
            this.$notification.open({
              key,
              duration: 16,
              message: <div class="text-negative text-base mb-2">Failed to connect with Google Sheets</div>,
              description: 'We\'re sorry, but there was an error while trying to connect your survey with Google Sheets. Please try again or chat with support.',
              icon: <a-icon type="stop" class="text-negative"/>,
              style: {width: '467px', marginLeft: `${384 - 467}px`},
              btn: h => {
                return h(
                    'a-button',
                    {
                      props: {
                        type: 'default',
                        size: 'small',
                      },
                      on: {
                        click: () => {
                          this.$notification.close(key);
                        }
                      },
                    },
                    'Close',
                );
              }
            });
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    async onDelete() {
      this.connection.active = false
      this.connection.value = ""

      await this.$store.dispatch(`survey/${CONNECTION_DISCONNECT}`, {
        survey_id: this.$route.params.id,
        app: this.$constants("GOOGLE_SHEETS"),
      })
    },
    onOpen() {
      window.open(this.connection.value, "_blank")
    }
  }
}
</script>

<style scoped>

</style>
