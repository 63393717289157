<template>
    <div class="flex justify-center items-center w-full">
        <div v-for="(smile, index) in smiles[scale]"
             :key="index"
             class="ml-7 first:ml-0"
        >
            <component :is="smile"></component>
        </div>
    </div>
</template>

<script>
	import {
		SmileColorfulPositive,
		SmileColorfulSuspicious,
		SmileColorfulNeutral,
		SmileColorfulThinking,
		SmileColorfulSad,
		SmileGrayPositive,
		SmileGraySuspicious,
		SmileGrayNeutral,
		SmileGrayThinking,
		SmileGraySad
	} from "@/helpers/customIcons";

	export default {
		name: "VQuestionSmilesPreview",
		props: ["scale", "subtype"],
		computed: {
			smiles: function () {
				if (this.subtype === 'smilesGray') {
					return {
						2: [SmileGraySad, SmileGrayPositive],
						3: [SmileGraySad, SmileGrayNeutral, SmileGrayPositive],
						5: [SmileGraySad, SmileGrayThinking, SmileGrayNeutral, SmileGraySuspicious, SmileGrayPositive]
					}
				} else {
					return {
						2: [SmileColorfulSad, SmileColorfulPositive],
						3: [SmileColorfulSad, SmileColorfulNeutral, SmileColorfulPositive],
						5: [SmileColorfulSad, SmileColorfulThinking, SmileColorfulNeutral, SmileColorfulSuspicious, SmileColorfulPositive]
					}
				}
			}
		}
	}
</script>

<style scoped>

</style>
