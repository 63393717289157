<template>
  <VSubscriptionLayout :data="currentPlan">


    <template #plan-extra>
      <span style="color: rgba(127, 140, 167, 1)">
        Your cancelled subscription will remain active until {{ user.subscription.cancelledFrom | moment('ll') }}
      </span>
    </template>


  </VSubscriptionLayout>
</template>

<script>
import {subscriptionProMixin} from "../../mixins/subscriptionPro";

export default {
  name: "SubscriptionProCancelling",

  mixins: [subscriptionProMixin]
}
</script>

<style scoped>

</style>
