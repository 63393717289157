<template>
  <div class="content-block pb-3 pt-3">
    <div class="px-6 flex items-center">
      <h3 class="text-gray-400 mb-0 font-light text-2xl">
        <slot name="title"></slot>
      </h3>
      <slot name="options"></slot>
      <slot name="reload-btn"></slot>
    </div>
    <a-divider/>
    <div class="flex px-10">
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VSurveyBlock"
}
</script>
