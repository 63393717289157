<template>
  <div class="py-4 px-6 flex items-center justify-between">
    <div class="flex flex-1 items-center">
      <div class="flex flex-none items-center justify-center w-8 h-8 mr-3 rounded-full"
           :class="isActive ? 'bg-gray-200' : 'bg-gray-300'"
      >
        <span class="text-white font-light text-xl font-bold">
          <slot name="icon"></slot>
          {{ position }}
        </span>
      </div>
      <h3 class="mb-0 font-light text-2xl flex items-center"
          :class="isActive ? 'text-gray-400' : 'text-character_gray-400'"
      >
        <slot name="title"></slot>
      </h3>
      <slot name="extra"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "VBlockHeader",
  props: ['position', 'isActive']
}
</script>
