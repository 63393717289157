<template>
  <div class="m-auto max-w-screen-xl flex">


    <div class="flex-1 flex justify-between items-center border-0 border-r border-solid border-neutral_gray-200 pr-6">
      <router-link :to="{name: 'Index'}" class="flex">
        <VIcon name="main-logo" style="font-size: 180px;"/>
      </router-link>
      <router-link
          v-if="user.current_plan === $constants('PLAN_FREE') && $route.name !== 'Billing' && $route.name !== 'Upgrade'"
          :to="{name: 'Billing'}">
        <VButton type="link"
                 size="large"
                 style="font-size: 1.125rem;"
                 @click="$posthog.capture('Clicked on Upgrade button in Header')">
          {{ "Upgrade plan" }}
        </VButton>
      </router-link>
    </div>


    <div class="flex-none flex items-center border-0 border-r border-solid border-neutral_gray-200">
      <div class="flex cursor-pointer">
        <a-dropdown :trigger="['click']" v-model="helpMenuVisibility">
          <a-menu slot="overlay">
            <a-menu-item key="help-center">
              <a :href="$constants('LINK_HELP_CENTER')" target="_blank">
                <span class="text-base space-x-2">
                  <a-icon type="question-circle"/>
                  <span>Help center</span>
                </span>
              </a>
            </a-menu-item>
            <a-menu-item key="support-chat">
              <span @click="openChat" class="text-base space-x-2">
                <a-icon type="message" style="margin: 0;"/>
                <span>Support chat</span>
              </span>
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item key="roadmap">
              <a :href="`${$constants('LINK_ROADMAP')}?sso=${productLiftSsoToken}`" target="_blank">
                <span class="text-base space-x-2">
                  <a-icon type="compass"/>
                  <span>Roadmap</span>
                </span>
              </a>
            </a-menu-item>
            <a-menu-item key="changelog">
              <a :href="`${$constants('LINK_CHANGELOG')}?sso=${productLiftSsoToken}`" target="_blank">
                <span class="text-base space-x-2">
                  <a-icon type="check-circle"/>
                  <span>Latest updates</span>
                </span>
              </a>
            </a-menu-item>
            <a-menu-item key="feature-request">
              <a :href="`${$constants('LINK_FEATURE_REQUEST')}?sso=${productLiftSsoToken}`" target="_blank">
                <span class="text-base space-x-2">
                  <a-icon type="plus-circle"/>
                  <span>Feature request</span>
                </span>
              </a>
            </a-menu-item>
            <a-menu-divider/>
            <a-menu-item key="rate">
                <span @click="onFeedback" class="text-base space-x-2">
                  <a-icon type="like" style="margin: 0;"/>
                  <span>Feedback</span>
                </span>
            </a-menu-item>
          </a-menu>

          <div class="flex px-6">
            <a-icon type="question-circle" style="font-size: 24px; color: #9AA5BB; outline: none;"></a-icon>
          </div>

        </a-dropdown>
      </div>
    </div>


    <div class="flex-none px-6">
      <a-dropdown :trigger="['click']" v-model="userMenuVisibility" overlayClassName="header-dropdown-width">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="email" class="menu-item-info">
            <div class="text-base text-gray-500">
              {{ user.email | userEmail }}
            </div>
          </a-menu-item>
          <a-menu-item key="responses" class="menu-item-info">
            <div class="text-base leading-none">
              <span class="text-gray-500 mr-1">Responses:</span>
              <span v-if="responses.availableCount >= 999999"
                    class="font-semibold text-violet-500">
								Unlimited
							</span>
              <span v-else
                    class="font-semibold"
                    :class="responses.colorClass"
              >
								{{ responses.passedCount }}/{{ responses.availableCount }}
							</span>
            </div>
          </a-menu-item>
          <a-menu-divider/>
          <a-menu-item key="1">
            <router-link :to="{name: 'Billing'}">
              <span @click="$posthog.capture('Clicked on Manage plans')" class="text-base space-x-2">
                <a-icon type="unordered-list"/>
                <span>Manage plans</span>
              </span>
            </router-link>
          </a-menu-item>
          <a-menu-item key="2">
            <router-link :to="{name: 'Settings'}">
              <span @click="$posthog.capture('Clicked on Notifications')" class="text-base space-x-2">
                <a-icon type="setting"/>
                <span>Settings</span>
              </span>
            </router-link>
          </a-menu-item>
          <a-menu-divider/>
          <a-menu-item key="4" @click="onLogout">
            <span class="text-base space-x-2">
              <a-icon type="logout" style="margin: 0;"/>
              <span>Logout</span>
            </span>
          </a-menu-item>
        </a-menu>

        <div class="inline text-base text-neutral_gray-800 cursor-pointer leading-none">
          <VUserAvatar :email="user.email" :name="userName"/>

          <span class="ml-2 mr-1">{{ userName }}</span>

          <a-icon type="down"/>

          <VLabel type="plan" style="display: inline-block;"></VLabel>
        </div>
      </a-dropdown>
    </div>


  </div>
</template>

<script>
import VLabel from "@/components/VLabel";
import {mapGetters} from "vuex";
import {LOGOUT} from "@/store/actions.type";
import VButton from "@/components/VButton";
import {subscriptionMixin} from "../mixins/subscription";
import VUserAvatar from "./VUserAvatar";
import {truncate} from "lodash";
import {FeedbackMixin} from "../mixins/feedback.mixin";
import VIcon from "./VIcon";

export default {
  name: "TheHeader",
  components: {VLabel, VButton, VUserAvatar, VIcon},

  mixins: [subscriptionMixin, FeedbackMixin],

  filters: {
    getFirstLetter(word) {
      return word.charAt(0).toUpperCase()
    },
    userEmail(email) {
      return truncate(email, {length: 20})
    },
  },

  data() {
    return {
      userMenuVisibility: false,
      helpMenuVisibility: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['user', 'responsesLimit', 'productLiftSsoToken']),
    userName: function () {
      return truncate(this.user.name || this.user.email, {length: 20});
    },
    responses: function () {
      let colorClass = 'text-blue-500';
      const {passedCount, availableCount} = this.responsesLimit;
      if (availableCount && passedCount >= availableCount) {
        colorClass = 'text-magenta-500';
      }

      return {passedCount, availableCount, colorClass};
    }
  },

  methods: {
    onLogout() {
      this.$store.dispatch(`auth/${LOGOUT}`);
    },

    handleMenuClick(e) {
      this.userMenuVisibility = ["email", "responses"].includes(e.key.toString());
    },

    openChat() {
      if (window.Chatra) {
        window.Chatra('openChat', true);
      }

      this.helpMenuVisibility = false;
    }

  }
}
</script>
