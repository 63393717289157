<template>
  <div>
    <div class="text-base mb-8">
      <p>
        Connect MetaSurvey with more than 1500+ services like Google Sheets or TaskMagic using Pabbly Connect.
      </p>
      <p>
        <VLearnMore :link="$constants('LINK_HELP_PABBLY')"/>
      </p>
    </div>


    <div class="space-y-7">
      <div>
        <p class="text-lg font-light mb-4">Webhook URL:</p>
        <a-form-model ref="connectForm"
                      :model="connectForm"
                      class="connection-form"
        >
          <a-form-model-item
              v-if="connection"
              prop="connection.value"
              :rules="{validator: validateEndpoint, trigger: [null]}"
              has-feedback
          >
            <div class="flex items-center space-x-3">
              <a-switch class="switch_positive-color"
                        checked-children="On"
                        un-checked-children="Off"
                        v-model="connection.active"
              />
              <a-input placeholder="https://connect.pabbly.com/workflow/sendwebhookdata/..."
                       size="large"
                       v-model="connection.value"
                       :disabled="!connection.active"
              />
              <VButton type="default"
                       size="large"
                       style="height: 40px; min-width: 96px;"
                       @click="testEndpoint"
                       :disabled="!connection.active"
              >
                Test
              </VButton>
            </div>
          </a-form-model-item>
        </a-form-model>
      </div>
    </div>

  </div>
</template>

<script>
import {mapGetters} from "vuex";
import VButton from "../../../VButton";
import {VERIFY_WEBHOOK} from "../../../../store/actions.type";
import VLearnMore from "../../../VLearnMore";

export default {
  name: "PabblyConnection",
  components: {VButton, VLearnMore},
  mounted() {
    this.connection = this.survey.connections.find(c => c.app === this.$constants("PABBLY"))
  },
  data() {
    return {
      connection: {},
    }
  },
  computed: {
    ...mapGetters('survey', ['survey']),
    connectForm() {
      return {connection: this.connection}
    }
  },
  methods: {
    testEndpoint() {
      this.$refs.connectForm.validateField("connection.value");
    },
    validateEndpoint(rule, value, callback) {
      const httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

      if (value === '') {
        callback(new Error('URL can not be null'));
      } else if (!httpRegex.test(value)) {
        callback(new Error('enter a correct URL'));
      } else {
        this.$store.dispatch(`survey/${VERIFY_WEBHOOK}`, {
          survey_id: this.$route.params.id,
          endpoint: value
        })
            .then(() => {
              callback()
              this.$posthog.capture('Tested Pabbly Endpoint', {surveyId: this.$route.params.id, endpoint: value});
            })
            .catch(e => {
              callback(e)
            })
      }
    }
  }
}
</script>

<style scoped>

</style>
