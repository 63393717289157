<template>
  <div class="input-color">
    <a-input size="large" style="width: 200px;" :value="value" @change="$emit('change', $event.target.value)">
      <a-popover slot="addonAfter" trigger="click" placement="right">
        <template #content>
          <VColorPicker
              style="box-shadow: none; width: 200px;"
              :value="value"
              @input="changeColor"/>
        </template>
        <div class="cursor-pointer" style="width: 44px; height: 42px; border-radius: 0 4px 4px 0;" :style="{backgroundColor: value}"></div>
      </a-popover>
    </a-input>
  </div>
</template>

<script>
import {Chrome as VColorPicker} from 'vue-color';

export default {
  name: "VInputColor",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String,
  },
  components: {VColorPicker},
  methods: {
    changeColor(color) {
      if (!color) return

      const {rgba, hex, hex8} = color
      const {a} = rgba

      const colorValue = a !== 1 ? hex8 : hex

      this.$emit("change", colorValue)
    }
  }
}
</script>

<style scoped>

</style>
