<template>
  <a-select
      :default-value="defaultValue"
      :loading="loading"
      :disabled="loading"
      option-label-prop="label"
      size="large"
      class="permission-select"
      dropdownClassName="permission-select"
      style="width: 120px;"
      :dropdownStyle="{zIndex: 1070, minWidth: '180px'}"
      @select="onSelect">
    <div slot="dropdownRender" slot-scope="menu">
      <v-nodes :vnodes="menu"/>
      <div v-if="$slots.actions">
        <a-divider style="margin: 4px 0 9px;"/>
        <div class="space-y-2 px-3 mb-3">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
    <a-select-option
        v-for="(permission, index) in permissions"
        :key="index"
        :value="permission.value"
        :label="permission.label">
      <div>{{ permission.label }}</div>
      <div class="text-gray-500">{{ permission.description }}</div>
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: "PermissionSelect.vue",
  props: {
    defaultValue: String,
    loading: Boolean,
    permissions: Array,
  },
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  methods: {
    onSelect(permission) {
      this.$emit('select', permission);
    },
  },
}
</script>

<style lang="less">
.permission-select {
  & .ant-select-selection__rendered {
    font-weight: 400;
  }

  & .ant-select-dropdown-content,
  & .ant-select-dropdown-menu-item {
    font-size: 16px;
  }
}
</style>
